<template>
  <div
    class="products-list-page"
    :id="paginationId"
  >
    <loader
      ref="loader"
      class="products-list-page__loader"
      :initial-visible="false"
    />
    <div class="products-list-page__header">
      <h1
        id="CATEGORY_HEADLINE"
        class="products-list-page__name | headline headline--type_h1"
        v-html="headline + ` (${resultCount})`"
      />
      <div class="products-list-page__top-bar">
        <products-sort class="products-list-page__products-sort" />
      </div>
    </div>
    <template v-if="teaserTop">
      <div class="products-list-page__teaser-top">
        <template-compiler
          :template="teaserTop.data"
        />
      </div>
    </template>
    <div class="products-list-page__column">
      <filters class="products-list-page_filters" />
    </div>
    <div class="products-list-page__content">
      <active-filters class="products-list-page__active-filters" />

      <products-list
        v-if="productsList.length > 0"
        class="products-list-page__products-list"
        :products-list="productsList"
        :product-update-path="productUpdateLink"
      />
      <div
        v-else
        class="products-list-page__no-results-message"
        v-text="$t('productsListPage.noResults')"
      />
      <div
        class="products-list-page__bottom-bar"
        v-if="pagination.pagesCount > 0"
      >
        <pagination
          class="products-list-page__pagination products-list-page__pagination--bottom"
          :pagination="pagination"
          :id="paginationId"
          @next-page="nextPageHandler"
          @prev-page="prevPageHandler"
          @goto-page="gotoPageHandler"
        />
      </div>
    </div>
    <template v-if="teaserBottom">
      <div class="products-list-page__teaser-bottom">
        <template-compiler
          :template="teaserBottom.data"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Loader from '../loader/loader.vue';
import Filters from '../filters/filters.vue';
import ProductsSort from '../productsSort/productsSort.vue';
import Pagination from '../pagination/pagination';
import ActiveFilters from '../activeFilters/activeFilters.vue';
import ProductsList from '../productsList/productsList.vue';
import plpStateComputedMixin from '../../utilities/vueMixins/plpStateComputedMixin/plpStateComputedMixin.js';
import TemplateCompiler from '../templateCompiler/templateCompiler.vue';
import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';
import {mapGetters} from 'vuex';
import { toRaw } from "vue";

export default {
    components: {
      Loader,
      Filters,
      ProductsSort,
      Pagination,
      ActiveFilters,
      ProductsList,
      TemplateCompiler
    },
    mixins: [
      plpStateComputedMixin,
      translationsMixin
    ],
    created() {
      this.bindHistoryEvent();
      this.saveInitialHistoryState();
    },
    computed: {
      ...mapGetters(
        'productsListPage',
        ['headline', 'resultCount', 'teaserBottom', 'teaserTop']
      )
    },
    data() {
      return {
        paginationId: 'productsList'
      };
    },
    methods: {
      bindHistoryEvent() {
        window.onpopstate = (event) => {
          this.$store.dispatch('productsListPage/historyPopState', event);
        };
      },
      nextPageHandler(){
        this.$store.dispatch('productsListPage/nextPage');
      },
      prevPageHandler() {
        this.$store.dispatch('productsListPage/prevPage');
      },
      gotoPageHandler(payload) {
        this.$store.dispatch('productsListPage/gotoPage', payload);
      },
      saveInitialHistoryState() {
        window.history.replaceState(
          {
            productsList: toRaw(this.$store.state.productsListPage.elementsList),
            filters: toRaw(this.$store.state.productsListPage.filters),
            pagination: toRaw(this.$store.state.productsListPage.pagination),
            sorting:  toRaw(this.$store.state.productsListPage.sorting)
          },
          document.title,
          window.location.href
        );
      }
    }
  };
</script>
