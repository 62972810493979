<template>
  <div class="product-flags">
    <span
      v-for="(flag, index) in updatedProductFlags"
      :key="index"
      class="product-flags__label | product-label"
      :class="[
        flag ? `product-label--${flag.style}` : ''
      ]"
    >
      {{ $t(flag.name) }}
    </span>
  </div>
</template>
<script>
export default {
    props: {
      productFlags: Array
    },
    data() {
      return {
        updatedProductFlags: this.productFlags
      }
    }
  }
</script>
