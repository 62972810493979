<template>
  <div class="gallery">
    <swiper
      :thumbs="{ swiper: thumbsSwiper ? thumbsSwiper : null }"
      :navigation="sliderCustomNavigationObject"
      :modules="modules"
      :items="images"
      :pagination="!isThumbnailGalleryShown"
      :slides-per-view="slidesPerViewMain"
      :space-between="spaceBetweenMain"
      :simulate-touch="true"
      @swiper="$emit('swiper', $event)"
      @slideChange="$emit('slideChange', $event)"
    >
      <swiper-slide
        v-for="(image, index) in images"
        :key="`slider-${index}`"
      >
        <slot
          name="slide"
          v-bind="{...image, index}"
        />
      </swiper-slide>
      <slot name="arrows" />
      <slot name="pagination" />
    </swiper>
    <swiper
      v-if="isThumbnailGalleryShown"
      ref="thumbnails"
      class="product-gallery__swiper-thumbs"
      :items="thumbs"
      :space-between="spaceBetweenThumbs"
      :modules="modules"
      :free-mode="true"
      :slides-per-view="slidesPerViewThumbs"
      watch-slides-progress
      @swiper="setThumbsSwiper"
    >
      <swiper-slide
        v-for="(thumb, index) in thumbs"
        :key="`slider-thumbs-${index}`"
      >
        <slot
          name="slide-thumbs"
          v-bind="{...thumb, index}"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';

import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';

import { FreeMode, Navigation, Thumbs, Pagination } from 'swiper/modules';
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Object,
      default() {
        return {}
      }
    },
    isThumbnailGalleryArrowsShown: {
      type: Boolean,
      default: false
    },
    isThumbnailGalleryShown: {
      type: Boolean,
      default: true
    },
    slidesPerViewMain: {
      type: Number,
      default: 1
    },
    slidesPerViewThumbs: {
      type: Number,
      default: 5
    },
    spaceBetweenMain: {
      type: Number,
      default: 0
    },
    spaceBetweenThumbs: {
      type: Number,
      default: 10
    },
    thumbs: {
      type: Object,
      default() {
        return {}
      }
    },
    thumbsCustomNavigationObject: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  setup() {
    const sliderCustomNavigationObject = {
      prevEl: `.product-gallery__button--prev`,
      nextEl: `.product-gallery__button--next`,
      disabledClass: 'product-gallery__button-disabled'
    }
    const thumbsSwiper = ref(null);

    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };

    return {
      thumbsSwiper,
      sliderCustomNavigationObject,
      setThumbsSwiper,
      modules: [FreeMode, Navigation, Thumbs, Pagination],
    };
  },
};
</script>