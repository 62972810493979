<template>
  <div class="input">
    <label
      class="input__label"
      :class="{
        'input__label--as-placeholder': labelAsPlaceholder,
        'input__label--only-mobile': labelType === 'only-mobile',
        'input__label--required': required
      }"
      v-if="label"
    >
      {{ label }}
    </label>
    <!--TODO Spacing is to far away. Can't reduce the spacing with padding or margin  -->
    <div
      class="input__wrapper"
      :class="{
        'input__wrapper--focus': focus,
        'input__wrapper--error': !!error,
        'input__wrapper--valid': (!error && modelValue),
        'input__wrapper--login': isLogin,
        'input__wrapper--search': type === 'search',
      }"
    >
      <input
        class="input__field"
        :aria-label="name"
        :type="innerType"
        :name="name"
        :value="modelValue"
        :placeholder="inputPlaceholder"
        :required="required"
        @blur="blurHandler"
        @focus="focusHandler"
        :disabled="disabled"
        :autocomplete="autocomplete"
        ref="inputField"
        :id="`input-vue-${type}-${name}`"
        v-model="modelValue"
        @keyup="$emit('keyUpEvent', {key: $event.key})"
      >
      <button
        v-if="modelValue.length && (type === 'search' || type === 'delete')"
        :class="[this.focus ? 'input__close-icon__active' : 'input__close-icon__not-active']"
        class="input__close-icon"
        @click.prevent="resetInputValue"
        type="button"
      />
      <button
        v-if="button"
        class="input__button"
        :type="button.type"
        :class="[{
          'input__button--default': (button.type && button.type === 'default') || !button.type,
          'input__button--icon_eye': innerType === 'password' && !passwordToggled,
          'input__button--icon_eye-blocked': innerType === 'text' && passwordToggled
        }, buttonIconClass(button.icon)]"
        :aria-label="labelForButton(button.icon)"
        @click.prevent="buttonClickHandler"
        tabindex="-1"
      >
        <span
          v-if="button.text"
          class="input__button-text"
        >
          {{ button.text }}
        </span>
      </button>
    </div>
    <span
      class="input__error"
      v-if="!!error"
      v-html="error"
    />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        focus: false,
        innerType: 'text',
        passwordToggled: false,
        modelValue: this.intiialValue
      };
    },
    props: {
      intiialValue: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      type: {
        type: String,
        default: 'text'
      },
      labelType: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        default: false
      },
      button: {
        type: Object,
        default: null
      },
      placeholder: {
        type: String,
        default: ''
      },
      autocomplete: String,
      isLogin: {
        type: Boolean,
        default: false
      },
      isFocused: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      inputPlaceholder() {
        return this.focus ? '' : (this.placeholder || this.label) + (this.required ? ' *' : '');
      },
      labelAsPlaceholder() {
        return !this.placeholder && !this.focus && !this.modelValue;
      },
    },
    mounted() {
      this.innerType = this.type;
    },
    emits: ['update:modelValue', 'blur:modelValue', 'buttonclicked', 'keyUpEvent'],
    methods: {
      blurHandler() {
        this.focus = false;
        this.$emit('blur:modelValue');
      },
      focusHandler() {
        this.focus = true;
      },
      buttonClickHandler() {
        if (this.innerType === 'password' && !this.passwordToggled) {
          this.innerType = 'text';
          this.passwordToggled = true;
        } else if (this.innerType === 'text' && this.passwordToggled) {
          this.innerType = 'password';
          this.passwordToggled = false;
        } else {
          this.$emit('buttonclicked');
        }
      },
      getValue() {
        return this.modelValue;
      },
      buttonIconClass(icon) {
        return icon ? 'input__button--icon_' + icon : '';
      },
      labelForButton(icon) {
        if (icon === 'mail') {
          return this.$t('newsletter.sub.button.label');
        } else if (icon === 'search') {
          return this.$t('search.button.label');
        } else if (this.innerType === 'password' && !this.passwordToggled) {
          return this.$t('password.plain.text.button.label');
        } else if (this.innerType === 'text' && this.passwordToggled) {
          return this.$t('password.hide.text.button.label');
        } else {
          return '';
        }
      },
      resetInputValue() {
        this.modelValue = '';
      }
    },
    watch: {
      isFocused(newValue) {
        if (newValue) {
          this.$refs.inputField.focus();
        }
      },
      modelValue(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  };
</script>
