<template>
  <div class="product-gallery">
    <!--  ToDo can be tested and implemented if there are more items  -->
    <!--  @swiper="swiperEvent"  -->
    <swiper-thumbs-gallery
      :thumbs="thisImages"
      :images="thisImages"
      :is-thumbnail-gallery-shown="!isMobile"
      @slideChange="slideChange"
    >
      <template #slide="image">
        <div
          class="product-gallery__slide"
          @click="imageClickHandler(image.index)"
        >
          <image-magnify
            v-if="!disableZoom && !isMobile && isMagnify"
            class="product-gallery__image"
            :image-src="imageUrl(image.big, width)"
            :preview-src="image.zoom"
            :alt="productName"
          />
          <img
            v-else-if="disableZoom || (isMobile || !isMagnify)"
            class="product-gallery__image"
            :src="imageUrl(image.big, width)"
            :title="productName"
            :alt="productName"
          >
        </div>
      </template>
      <template #slide-thumbs="thumb">
        <div
          class="product-gallery__thumb-wrapper"
          @click="updateCurrentIndex(thumb.index)"
        >
          <img
            class="product-gallery__thumbnail"
            :src="imageUrl(thumb.thumbnail, imageSizeS())"
            :title="productName"
            :alt="productName"
          >
        </div>
      </template>
      <template
        #arrows
        v-if="!isMobile && thisImages.length > 1"
      >
        <div
          class="swiper-button swiper-button__main product-gallery__button product-gallery__button--prev"
        />
        <div
          class="swiper-button swiper-button__main product-gallery__button product-gallery__button--next"
        />
      </template>
    </swiper-thumbs-gallery>
  </div>
</template>

<script>
import productPageStateComputedMixin
  from '../../utilities/vueMixins/productPageStateComputedMixin/productPageStateComputedMixin.js';
import { breakpoints } from '../../variables/variables.js';
import ImageMagnify from '../imageMagnify/imageMagnify.vue';
import SwiperThumbsGallery from '../swiper/swiperThumbsGallery.vue';
import {imageUrlWithParam, imageSizeS, imageSizeXL, imageSizeMobile} from "../../utilities/js/image/image.js";

export default {
    components: {
      ImageMagnify,
      SwiperThumbsGallery
    },
    mixins: [
      productPageStateComputedMixin
    ],
    props: {
      id: String,
      productVideos: {
        type: Object,
        default() {
          return {}
        }
      },
      productImages: Array,
      name: String,
      productName: String,
      disableZoom: Boolean,
      indexKey: String
    },
    data() {
      return {
        isMagnify: true,
        isMobile: window.innerWidth < breakpoints.laptop,
        currentGalleryIndex: 0,
        gallerySwiperRef: null
      };
    },
    created() {
      this.$store.subscribe(mutation => {
        if (mutation.type === 'indexUpdate' && this.indexKey === mutation.payload.galleryId) {
          this.currentGalleryIndex = mutation.payload.showImage;
          /* ToDo */
          // this.gallerySwiperRef.slideTo(mutation.payload.showImage, false);
        }
      });
      window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
    },
    mounted() {
      if (this.isMobile) {
        this.isMagnify = false;
      }
    },
    methods: {
      imageSizeS() {
        return imageSizeS
      },
      /* ToDo */
      /* swiperEvent(e) {
        this.gallerySwiperRef = e
      },*/
      slideChange(e) {
        this.currentGalleryIndex = e.activeIndex;
      },
      imageClickHandler(index) {
        if (!this.disableZoom) {
          this.$store.dispatch('imageGalleryClicked', {
            showImage: index,
            galleryId: this.thisIndexKey
          })
        }
      },
      breakpointChangeHandler() {
        this.isMobile = window.innerWidth <= breakpoints.tablet;

        this.isMagnify = !this.isMobile;
      },
      updateStoreIndex(index) {
          if (this.currentGalleryIndex !== index) { // Prevent recursive calls
            this.$store.dispatch('indexUpdate', {
              showImage: index,
              galleryId: this.indexKey
            })
          }
      },
      updateCurrentIndex(index) {
        this.updateStoreIndex(index)
        //this.gallerySwiperRef.slideTo(index, false);
      },
      imageUrl(src, width) {
        return imageUrlWithParam(src, width)
      }
    },
  computed: {
    thisImages() {
      if (!this.availablePartDummy) {
        if (this.productImages && this.productImages.length > 0) {
          return [this.productImages[0]];
        } else if (this.productDetails.imageVersions && this.productDetails.imageVersions.length > 0) {
          return [this.productDetails.imageVersions[0]];
        }
      } else if (this.productDetails.imageVersions) {
        return this.productDetails.imageVersions;
      } else {
        return [];
      }
    },
    thisIndexKey() {
      return this.indexKey ? this.indexKey : this.id;
    },
    width() {
      if(window.innerWidth <= breakpoints.laptop) {
        return imageSizeMobile;
      } else {
        return imageSizeXL;
      }
    }
  },
  /*watch: {
    currentGalleryIndex() {
      this.gallerySwiperRef.slideTo(this.currentGalleryIndex, false);
    }
  }*/
};
</script>
