const headlineSizeCalculation = {
  computed: {
    headingFontSize() {
      if (this.productDetails.product.name.length > 0) {
        // calculates the heading font-size based on the length of the string.
        // 1.5 is the lower limit for the font-size and 2.5 the upper limit.
        let length = this.productDetails.product.name.length;
        if (length <= 6) {
          return 2.5;
        } else if (length > 6 && length < 106) {
          return -0.01 * length + 2.56;
        } else {
          return 1.5;
        }
      }
    }
  },
};

export default headlineSizeCalculation;