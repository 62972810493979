import wishlist from '../../../services/wishlist/wishlist.js';

const actions = {
  addAllItemsToBasket(store, data) {
    wishlist()
      .addAllToCart(data.url, data.formData)
      .then((res) => {
        store.dispatch('updateWishlistState', res.wishlist);
        store.dispatch('updateCart', res);
      });
  },
  updateCart(store, data) {
    store.commit('updateCartCount', data, {root: true});
    store.commit('updateWishlistCount', data, {root: true});
    store.commit('updateCart', data.cart, {root: true});
    store.commit('updateCarts', data.carts, {root: true});
  },
  addFromWishlist(store, data) {
    wishlist()
      .addSingleProductToCart(data)
      .then((res) => {
        store.dispatch('updateWishlistState', res.wishlist);
        store.dispatch('updateCart', Object.assign(res, {'type': data.type, 'itemId': data.itemId}));
      });
  },
  updateWishlistState(store, data) {
    store.commit('updateWishlistState', data);
  },
  removeSingleProduct(store, data) {
    wishlist()
      .removeSingleProduct(data)
      .then((entryId) => store.commit('removeFromWishlist', entryId));
  }
};

export default actions;
