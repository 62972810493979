<template>
  <div
    class="cookie-box"
    v-if="visible"
  >
    <div class="cookie-box__content">
      <div class="cookie-box__text">
        Hier kommt der Cookie Text
      </div>
      <button
        class="cookie-box__close-button button"
        name="accept-cookie"
        @click="acceptCookie"
      >
        bestätigen
      </button>
    </div>
  </div>
</template>

<script>
  import cookieMonster from '../../utilities/js/cookieMonster/cookieMonster.js';

  export default {
    props: {
      cookieName: {
        type: String,
        default: 'cookiesAccepted'
      },
      cookieValue: {
        type: String,
        default: 'true'
      },
      expireInDays: {
        type: Number,
        default: 30
      }
    },
    data() {
      return {
        visible: false,
        text: '',
        buttonLabel: ''
      };
    },
    mounted() {
      this.checkCookie();
    },
    methods: {
      close() {
        this.visible = false;
      },
      show() {
        this.visible = true;
      },
      checkCookie() {
        let cookie = cookieMonster.get(this.cookieName);

        if (!cookie) {
          this.show();
        }
      },
      acceptCookie(event) {
        event.preventDefault();
        let expireDate = (new Date()).getTime() + 86400000 * this.expireInDays;
        cookieMonster.set(this.cookieName, this.cookieValue, new Date(expireDate));
        this.close();
      }
    }
  };
</script>
