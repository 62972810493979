<template>
  <div
    class="account-page account-orders-list | user-area"
  >
    <account-page
      :headline="$t('account.orders')"
      :show-back-button="showBackButton"
      ref="accountOrders"
    >
      <account-single-order
        v-if="orderNumber"
        :order-number="orderNumber"
        @goBack="goBackToList"
      />
      <account-orders-list
        v-else
        :no-address-data="noAddressData"
        @goToOrder="goToOrder"
      />
    </account-page>
  </div>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin'
import AccountPage from '../accountPage/accountPage.vue'
import AccountSingleOrder from '../accountSingleOrder/accountSingleOrder.vue'
import AccountOrdersList from '../accountOrdersList/accountOrdersList.vue'

export default {
  components: {
    AccountPage,
    AccountOrdersList,
    AccountSingleOrder
  },
  mixins: [
    globalStateComputedMixin
  ],
  props: {
    accountOrders: {
      type: Object,
      default: null
    },
    noAddressData: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      orderNumber: '',
      showBackButton: true
    }
  },
  methods: {
    goToOrder(number) {
      this.orderNumber = number
      this.showBackButton = false
    },
    goBackToList() {
      this.orderNumber = ''
      this.showBackButton = true
    }
  }
}
</script>
