<template>
  <div class="radio-inputs-list">
    <label
      class="radio-inputs-list__option"
      v-for="(option, index) in options"
      :key="index"
      :for="`radio-${name}-${option.value}`"
    >
      <input
        class="radio-inputs-list__input"
        :name="name"
        type="radio"
        v-model="selectedOption"
        :value="option.value"
        :id="`radio-${name}-${option.value}`"
        @click.stop="$emit('update:modelValue', $event.target.value)"
      >
      <span class="radio-inputs-list__radio-circle" />
      <div class="radio-inputs-list__option-content">
        <span class="radio-inputs-list__option-name">{{ option.label }}</span>
        <span
          v-if="option.description"
          class="radio-inputs-list__option-description"
        >
          {{ option.description }}
        </span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    name: String,
    initialSelectedOption: String
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedOption: this.initialSelectedOption ? this.initialSelectedOption : this.options[0].value
    };
  }
};
</script>
