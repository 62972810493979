<template>
  <ul
    class="burger-nav-tree"
    :style="{
      zIndex: level,
      minHeight: parentMinHeight ? `${parentMinHeight}px` : minHeight
    }"
  >
    <li
      v-if="headline"
      class="burger-nav-tree__item burger-nav-tree__item--headline"
      @click="goBack"
    >
      {{ headline }}
    </li>
    <li
      v-if="allCategoriesLink"
      class="burger-nav-tree__item burger-nav-tree__item--category-special"
    >
      <a
        :href="allCategoriesLink.url"
        class="burger-nav-tree__link"
      >
        {{ allCategoriesLink.name }}
      </a>
    </li>
    <template v-if="isActive">
      <li
        class="burger-nav-tree__item"
        :class="{
          'burger-nav-tree__item--subcategory_first': index === 0,
          'burger-nav-tree__item--subcategory_last': index === categories.length - 1,
        }"
        v-for="(category, index) in categories"
        :key="`${level}-${index}`"
        ref="subcategory"
      >
        <template v-if="category.subcategories && category.subcategories.length">
          <span
            class="burger-nav-tree__link burger-nav-tree__link--arrow"
            @click="openSubcategory(index)"
          >
            {{ category.name }}
          </span>
          <burger-nav-tree
            class="burger-nav-tree__subcategories"
            :class="{
              'burger-nav-tree__subcategories--active': activeSubcategory === index
            }"
            :is-active="activeSubcategory === index"
            :headline="category.name"
            :categories="category.subcategories"
            :level="level + 1"
            :all-categories-link="{
              url: category.url,
              name: $t('global.allArticles.link')
            }"
            :parent-min-height="rootMinHeight ? rootMinHeight : parentMinHeight"
            @goback="goBackHandler"
            @heightupdate="heightUpdateHandler"
          />
        </template>
        <template v-else>
          <a
            v-if="!category.entryEmpty"
            :href="category.url"
            class="burger-nav-tree__link default-regular"
          >
            {{ category.name }}
          </a>
          <span
            v-if="category.entryEmpty"
            class="burger-nav-tree__link default-regular burger-nav-tree__link--disabled"
          >
            {{ category.name }}
          </span>
        </template>
      </li>
    </template>
  </ul>
</template>

<script>
import pigeon from '../../utilities/js/pigeon/pigeon.js';

export default {
    props: {
      headline: String,
      categories: {
        type: Array,
        default() {
          return [];
        }
      },
      level: {
        type: Number,
        default: 0
      },
      allCategoriesLink: Object,
      isActive: {
        type: Boolean,
        default: true
      },
      parentMinHeight: Number
    },
    emits: ['heightupdate', 'goback'],
    data() {
      return {
        activeSubcategory: -1,
        minHeight: '',
        rootMinHeight: null
      };
    },
    methods: {
      openSubcategory(index) {
        pigeon.publish('burgernavtree:levelchange');
        this.activeSubcategory = index;

        this.$nextTick(() => {
          let minHeight = this.$refs.subcategory[index].querySelector('.burger-nav-tree__subcategories').offsetHeight;

          if (this.level === 0) {
            this.minHeight = `${this.calculateMinHeight(minHeight)}px`;
          } else {
            this.$emit('heightupdate', {minHeight: minHeight});
          }
        })
      },
      goBack() {
        pigeon.publish('burgernavtree:levelchange');
        this.$emit('goback');
      },
      goBackHandler() {
        this.activeSubcategory = -1;

        if (this.level === 0) {
          this.minHeight = '';
        } else {
          this.$emit('heightupdate', {minHeight: this.$el.offsetHeight});
        }
      },
      heightUpdateHandler(data) {
        if (this.level === 0) {
          this.minHeight = `${this.calculateMinHeight(data.minHeight)}px`;
        } else {
          this.$emit('heightupdate', data);
        }
      },
      calculateMinHeight(minHeight) {
        if (!this.rootMinHeight) {
          this.rootMinHeight = this.$el.offsetHeight;
        }

        return minHeight >= this.rootMinHeight ? minHeight : this.rootMinHeight;
      }
    }
  };
</script>
