<template>
  <div class="checkout-process-breadcrumbs">
    <component
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
      :is="breadcrumb.url && activeBreadcrumbIndex !== index ? 'a' : 'span'"
      :href="breadcrumb.url"
      class="checkout-process-breadcrumbs__breadcrumb"
      :class="{
        'checkout-process-breadcrumbs__breadcrumb--ready': index < activeBreadcrumbIndex,
        'checkout-process-breadcrumbs__breadcrumb--previous': index + 1 === activeBreadcrumbIndex,
        'checkout-process-breadcrumbs__breadcrumb--current': index === activeBreadcrumbIndex
      }"
      :style="`z-index: ${breadcrumbs.length - index};`"
    >
      <span class="checkout-process-breadcrumbs__breadcrumb-name">
        {{ breadcrumb.name }}
      </span>
    </component>
  </div>
</template>

<script>
  import checkoutStateComputedMixin from '../../utilities/vueMixins/checkoutStateComputedMixin/checkoutStateComputedMixin.js';

  export default {
    mixins: [
      checkoutStateComputedMixin
    ]
  };
</script>
