<template>
  <div class="promo-items-list">
    <div class="promo-items-list__headline">
      {{ $t(headline, {0: maxToSelect}) }}
    </div>
    <promo-item
      class="promo-items-list__item"
      v-for="item in items"
      v-bind="item"
      :key="item.entryId"
      :action-url="actionUrl"
      :action-id="actionId"
      :action-type="actionType"
      :is-selectable="item.isSelected || selectedCount <= maxToSelect"
    />
  </div>
</template>

<script>
  import PromoItem from '../promoItem/promoItem.vue';
  import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';

  export default {
    mixins: [
      translationsMixin
    ],
    components: {
      PromoItem
    },
    props: {
      actionType: {
        type: String,
        default: ''
      },
      actionUrl: String,
      actionId: String,
      maxToSelect: {
        type: Number,
        default: 1
      },
      selectedCount: {
        type: Number,
        default: 1
      },
      items: {
        type: Array,
        default() {
          return [];
        }
      },
    },
    computed: {
      headline() {
        switch (this.actionType) {
        case "SPECIAL_PRICE_ITEM":
          return "specialPriceAction";
        case "FREE_ITEM":
          return "freeItemsAction"
        }
      }
    }
  };
</script>
