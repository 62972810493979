<template>
  <div class="form-structure">
    <csrf-input
      v-if="includeCsrf"
      ref="csrf"
    />
    <input
      type="hidden"
      v-for="(hiddenInput, index) in hiddenInputs"
      :key="index"
      :name="hiddenInput.name"
      :value="hiddenInput.value"
    >
    <div class="form-structure__structure">
      <div
        class="form-structure__row"
        v-for="(row, rowIndex) in structure"
        :key="'row'+rowIndex"
      >
        <div
          class="form-structure__row-left-column"
          v-if="template === 'side-column'"
        >
          <span
            class="form-structure__row-label"
            v-html="row.label"
          />
          <span
            v-if="row.hint"
            class="form-structure__row-hint"
            v-html="row.hint"
          />
        </div>
        <span
          class="form-structure__row-label"
          v-html="row.label"
          v-if="row.label && template !='side-column'"
        />
        <div
          class="form-structure__row-inputs"
          :class="{
            'form-structure__row-inputs--column': template === 'side-column'
          }"
        >
          <div
            v-for="(input, inputIndex) in row.inputs"
            class="form-structure__input-wrapper"
            :class="[
              `form-structure__input-wrapper--size_${input.size ? input.size : '100'}`,
              input.sizeTablet ? `form-structure__input-wrapper--size-tablet_${input.sizeTablet}` : '',
              input.sizeDesktop ? `form-structure__input-wrapper--size-desktop_${input.sizeDesktop}` : '',
              input.noLabel ? 'form-structure__input-wrapper--no-label' : ''
            ]"
            :key="'input'+inputIndex"
          >
            <template v-if="input.component === 'checkbox'">
              <checkbox-vue
                class="form-structure__input"
                v-bind="input.config"
                ref="input"
              />
            </template>
            <template v-else-if="input.component === 'textarea'">
              <textarea-vue
                class="form-structure__input"
                v-bind="input.config"
                ref="input"
                :label-type="input.labelType ? input.labelType : (template === 'side-column' ? 'only-mobile' : null)"
              />
            </template>
            <template v-else-if="input.component === 'select'">
              <select-vue
                class="form-structure__input"
                v-bind="input.config"
                ref="input"
                :label-type="input.labelType ? input.labelType : (template === 'side-column' ? 'only-mobile' : null)"
              />
            </template>
            <template v-else-if="input.component === 'date-input'">
              <date-input
                class="form-structure__input"
                v-bind="input.config"
                ref="input"
                :label-type="input.labelType ? input.labelType : (template === 'side-column' ? 'only-mobile' : null)"
              />
            </template>
            <template v-else>
              <input-vue
                class="form-structure__input"
                v-bind="input.config"
                ref="input"
                :label-type="input.labelType ? input.labelType : (template === 'side-column' ? 'only-mobile' : null)"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import scrollTo from 'scroll-to';
  import InputVue from '../input/input.vue';
  import TextareaVue from '../textarea/textarea.vue';
  import CheckboxVue from '../checkbox/checkbox.vue';
  import SelectVue from '../select/select.vue';
  import DateInput from '../dateInput/dateInput.vue';
  import CsrfInput from '../csrfInput/csrfInput.vue';
  import isElementVisibleOnScreen from '../../utilities/js/isElementVisibleOnScreen/isElementVisibleOnScreen.js';

  export default {
    components: {
      InputVue,
      TextareaVue,
      CheckboxVue,
      SelectVue,
      DateInput,
      CsrfInput
    },
    props: {
      structure: {
        type: Array,
        default() {
          return [];
        }
      },
      hiddenInputs: {
        type: Array,
        default() {
          return [];
        }
      },
      includeCsrf: {
        type: Boolean,
        default: true
      },
      preventSubmit: {
        type: Boolean,
        default: false
      },
      parentFormSelector: String,
      template: String
    },
    data() {
      return {

      };
    },
    mounted() {
      this.findParentFormElement();
      this.bindFormSubmitEvent();
      // handle empty forms or forms with hidden inputs only
      if (this.$refs.input === undefined) {
        this.$refs.input = [];
      }
    },
    methods: {
      findParentFormElement() {
        if (!this.parentFormSelector) {
          throw new TypeError('FormStructure: Missing required property parentFormSelector.');
        }

        const parentFormElement = document.querySelector(this.parentFormSelector);

        if (!parentFormElement) {
          throw new TypeError(`FormStructure: Couldn't find element with selector: '${this.parentFormSelector}'.`);
        }

        this.parentFormElement = parentFormElement;
      },
      bindFormSubmitEvent() {
        this.parentFormElement.addEventListener('submit', this.formSubmitHandler);
      },
      formSubmitHandler(event) {
        event.currentTarget.removeEventListener(event.type, this.formSubmitHandler);
        event.preventDefault();
        let valid = this.validate();

        this.$emit(
          'formsubmit',
          {
            valid: valid,
            formValues: this.getFormValues()
          }
        );
        if (this.validate() && !this.preventSubmit) {
          this.parentFormElement.submit();
        }
      },
      validate() {
        let firstNotValidInput;
        let valid = true;

        for (let input of this.$refs.input) {
          valid = input.validate() ? valid : false;
          firstNotValidInput = !valid && !firstNotValidInput ? input.$el : firstNotValidInput;
        }

        if (!valid) {
          this.scrollWindowToInput(firstNotValidInput);
        }

        return valid;
      },
      scrollWindowToInput(input) {
        let elementVisibility = isElementVisibleOnScreen(input);

        if (!elementVisibility[0]) {
          scrollTo(0, elementVisibility[1], 500);
        }
      },
      getFormValues() {
        const formValues = {};

        for (let input of this.$refs.input) {
          if (input.name) {
            formValues[input.name] = input.getValue();
          }
        }

        if(this.includeCsrf) {
          formValues[this.$refs.csrf.getName()] = this.$refs.csrf.getValue();
        }

        return formValues;
      },
      setFormValues(newValuesObject) {
        for (let input of this.$refs.input) {
          if (Object.prototype.hasOwnProperty.call(newValuesObject, input.name)) {
            input.setValue(newValuesObject[input.name]);
          }
        }
      }
    }
  };
</script>
