<template>
  <div
    class="recommendations-teaser"
    :class="{
      'recommendations-teaser--flat': type && type.toLowerCase() === 'flat'
    }"
  >
    <h1
      class="recommendations-teaser__headline | headline headline--type_h2"
      v-if="headline && headline !== ''"
      v-html="headline"
    />
    <carousel
      class="recommendations-teaser__carousel"
      v-if="products.length"
      :slides-count="products.length"
      :items="products"
      :slides-per-view-desktop="calculateSlidesPerView(4)"
      :slides-per-view-mobile="calculateSlidesPerView(2)"
      :slides-per-view-tablet="calculateSlidesPerView(3)"
    >
      <template #slide="item">
        <product-box
          :refresh-on="['carousel:mounted']"
          v-bind="item"
        />
      </template>
    </carousel>
  </div>
</template>

<script>
import Carousel from '../carousel/carousel.vue';
import ProductBox from '../productBox/productBox.vue';

export default {
  components: {
    Carousel,
    ProductBox
  },
  props: {
    initialProducts: {
      type: Array,
      default() {
        return [];
      }
    },
    type: String,
    headline: String,
    outerElementWidth: Number
  },
  data() {
    return {
      products: this.initialProducts
    };
  },
  methods: {
    calculateSlidesPerView(base) {
      if (this.outerElementWidth) {
        return Math.max(Math.floor(base * this.outerElementWidth), 1);
      }
      return base;
    }
  }
};
</script>
