<template>
  <div class="language-switcher">
    <select-vue
      class="language-switcher__select"
      name="language-switcher"
      id="language-switcher1"
      ref="select"
      :initial-value="initialValue.value"
      :options="options"
      :use-native-on-mobile="false"
      :use-custom-select="true"
      :language-switcher="true"
      :inverse="inverse"
      :without-border="withoutBorder"
      :small="small"
      @change="redirect"
    >
      <template v-slot="{option}">
        <img
          class="language-switcher__icon language-switcher__icon--option"
          :src="staticImagesUrl + option.icon.src"
        >
        <span class="default-regular">{{ option.label }}</span>
      </template>
    </select-vue>
  </div>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';
import SelectVue from "../select/select.vue";

export default {
  components: {
    SelectVue
  },
  mixins: [
    globalStateComputedMixin
  ],
  props: {
    currentCountry: {
      type: String,
      default: ''
    },
    inverse: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    withoutBorder: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      initialValue: this.initialValue,
      alt: this.alt,
      src: this.src,
      options: []
    };
  },
  created() {
    this.setInitialValue()
  },
  methods: {
    redirect() {
      const LANG_PARAM = 'lang=';
      const LANG_PATTERN = /([&?])lang=[a-z]{2}$/;
      const href = window.location.href;
      let selectedOption = this.options.filter(option => option.value === this.$refs.select.getValue())[0];
      window.__globalConfig.country = selectedOption.value;

      /* if #-Element */
      const idSplitElement = '#';
      let urlParametersWithHash = href.split(idSplitElement)[1];
      let actualUrl = href.split(idSplitElement)[0].replace(LANG_PATTERN, '');
      urlParametersWithHash = urlParametersWithHash !== undefined ? idSplitElement + urlParametersWithHash : '';

      /* query string */
      const splitElement = '?';
      let urlParameters = actualUrl.split(splitElement)[1];
      actualUrl = actualUrl.split(splitElement)[0];
      urlParameters = urlParameters !== undefined
        ? splitElement + urlParameters + '&' + LANG_PARAM + selectedOption.value
        : splitElement + LANG_PARAM + selectedOption.value;

      selectedOption.url = actualUrl + urlParameters + urlParametersWithHash;

      window.location.href = selectedOption.url;
    },
    setInitialValue() {
      this.options.push({value: 'en', label: 'EN', icon: {src: 'flag_unitedkingdom.png', alt: 'uk'}});
      let currentCountry = this.getCurrentCountry();

      if (currentCountry === 'se') {
        this.options.push({value: 'sv', label: 'SV', icon: {src: 'flag_sweden.png', alt: 'se'}});
      } else if (currentCountry === 'si') {
        this.options.push({value: 'sl', label: 'SL', icon: {src: 'flag_slovenia.png', alt: 'si'}});
      } else if (currentCountry === 'ee') {
        this.options.push({value: 'et', label: 'ET', icon: {src: 'flat_estonia.png', alt: 'ee'}})
      }

      this.initialValue = this.options.filter(option => option.value === this.getCurrentLanguage())[0];
    },
    getCurrentCountry() {
      return window.__globalConfig && window.__globalConfig.country !== 'undefined' ? `${window.__globalConfig.country}` : 'en';
    },
    getCurrentLanguage() {
      return window.__globalConfig && window.__globalConfig.language !== 'undefined' ? `${window.__globalConfig.language}` : this.currentCountry;
    }
  }
};
</script>
