const breakpoints = {
  phone: 370,
  phoneLg: 480,
  tablet: 768,
  laptop: 1024,
  laptopLg: 1200,
  desktop: 1440,
  tv: 1920
}

export default breakpoints;
