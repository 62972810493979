import createParametersString from "../../utilities/js/createParametersString/createParametersString";
import backendRequestService from "../backendRequest/backendRequest";

let service;

class ProductsList {
  constructor() {
    this.pageBaseUrl = window.location.href.replace(/\?.*$/, '');
  }

  getProductsList(parameters) {
    const requestUrl = `${this.pageBaseUrl}?${this.showAll()}${createParametersString(parameters)}`;

    return fetch(`${requestUrl}&ajax=true`)
      .then(response => {
        if (response.status === 205) {
          window.location.href = requestUrl;
          return null;
        }

        return response.json();

      })
      .then(response => {
        if (response) {
          return {
            state: response,
            url: requestUrl
          };
        }
    });
  }

  showAll() {
    return window.location.search.includes('show=all') ? 'show=all&' : '';
  }

  handleGeneral(url, data, method) {
    let requestHeaders = {};
    requestHeaders['Accept'] = 'application/json';
    return backendRequestService()
      .doBackendRequest(url, data, method, requestHeaders)
      .then(response => {
        if(response.status === 200 || response.ok) {
          return response.json();
        }
      })
  }
}

function productsList() {
  if (service) {
    return service;
  } else {
    service = new ProductsList();
    return service;
  }
}

export default productsList;
