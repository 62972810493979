<template>
  <div
    class="date-input"
    :class="{
      'date-input--active': datePickerVisible
    }"
    @click.stop
  >
    <label
      class="input__label date-input__label"
      :class="{
        'input__label--required': required
      }"
      v-if="label"
    >
      {{ label }}
    </label>
    <div class="date-input__input | input">
      <div
        class="input__wrapper"
        :class="{
          'input__wrapper--focus': focus,
          'input__wrapper--error': !!error,
          'input__wrapper--disabled': disabled
        }"
      >
        <!--   ToDo, this component hast to be tested and implemented if it will be used     -->
        <input
          class="input__field"
          type="text"
          :placeholder="$t('global.birthdate.placeholder')"
          @focus="inputFocusHandler"
          @blur="inputChangeHandler"
          @change="inputChangeHandler"
          @keydown.tab="hideDatePicker"
          v-model="value"
        >
      </div>
    </div>
    <date-picker
      v-if="datePickerVisible"
      class="date-input__date-picker"
      :initial-selected-date="datePickerInitialValue"
      :min-date="minDate"
      :max-date="maxDate"
      @change="datePickerChangeHandler"
    />
    <input
      type="hidden"
      v-model="value"
      :name="name"
    >
    <span
      class="date-input__error"
      v-if="!!error"
      v-html="error"
    />
  </div>
</template>

<script>
import DatePicker from '../datePicker/datePicker.vue';
import DateFormatMixin from '../../utilities/vueMixins/dateFormatMixin/dateFormatMixin';
// ToDo import { Field } from "vee-validate";

export default {
  components: {
    DatePicker
  },
  mixins: [
    DateFormatMixin
  ],
    props: {
      minDate: String,
      maxDate: String,
      required: {
        type: Boolean,
        default: false
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      label: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        default: '',
      }
    },
    emits: ['input', 'blur'],
    data() {
      return {
        value: this.initialValue,
        datePickerInitialValue: this.initialValue,
        datePickerVisible: false,
        focus: false
      };
    },
    mounted() {
      this.hideDatePickerBinded = this.hideDatePicker.bind(this);
      this.bodyEl = document.querySelector('body');
    },
    methods: {
      inputFocusHandler() {
        this.showDatePicker();
        this.focus = true;
      },
      showDatePicker() {
        this.datePickerVisible = true;
        this.bodyEl.addEventListener('click', this.hideDatePickerBinded);
      },
      hideDatePicker() {
        this.focus = false;
        this.datePickerVisible = false;
        this.bodyEl.removeEventListener('click', this.hideDatePickerBinded);
      },
      datePickerChangeHandler(event) {
        this.value = this.toLocaleDateString(event.value);
        this.hideDatePicker();
        this.inputEvent(event.value);
      },
      inputChangeHandler(event) {
        this.inputEvent(this.toISODateString(event.target.value));
      },
      inputEvent(value) {
        this.$emit('input', value);
        this.$emit('blur', value);
      }
    }
  };
</script>
