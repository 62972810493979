<template>
  <div class="hero-slider">
    <swiper
      :modules="modules"
      :navigation="sliderCustomNavigationObject"
      :slides-per-view="1"
      :speed="600"
      :autoplay="{
        stopOnLastSlide: false,
        disableOnInteraction: false
      }"
      @swiper="setSwiperRef"
      @slideChange="onSwiperChange"
    >
      <swiper-slide
        class="hero-slider__slide"
        v-for="(slide, index) in slides"
        :key="index"
        :virtualIndex="index"
        :style="`background-image: url('${slide.image.src}')`"
      >
        <a
          class="hero-slider__slide-content"
          :class="[
            `hero-slider__slide-content--text-${slide.textColor ? slide.textColor : 'dark'}`
          ]"
          :href="slide.url"
        >
          <div
            v-if="slide.headline"
            class="hero-slider__headline"
            v-html="slide.headline"
          />
          <div
            v-if="slide.subHeadline"
            class="hero-slider__subheadline"
            v-html="slide.subHeadline"
          />
        </a>
      </swiper-slide>
      <div
        v-if="slides.length > 1"
        class="hero-slider__pagination"
      >
        <div
          class="hero-slider__pagination-button"
          :class="{
            'hero-slider__pagination-button--active': index === activeSlide
          }"
          v-for="(slide, index) in slides"
          :key="`${index}-button`"
          @click="slideTo(index)"
        >
          <span class="hero-slider__pagination-count">{{ index + 1 }}</span> {{ slide.headline }}
          <span
            class="hero-slider__pagination-progress-bar"
            :style="`transition-duration: ${slide.autoplayTime ? slide.autoplayTime / 1000 : '3.5'}s;`"
          />
        </div>
      </div>
      <div
        v-if="slides.length > 1"
        class="hero-slider__button hero-slider__button--prev"
        :aria-label="$t('hero.slider.prev.button.label')"
      />
      <div
        v-if="slides.length > 1"
        class="hero-slider__button hero-slider__button--next"
        :aria-label="$t('hero.slider.next.button.label')"
      />
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';
import { ref } from "vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  setup() {
    const sliderCustomNavigationObject = {
      prevEl: `.hero-slider__button--prev`,
      nextEl: `.hero-slider__button--next`,
      disabledClass: 'button-disabled'
    }
    let swiperRef = null;
    let activeSlide = ref(0);
    const setSwiperRef = (swiper) => {
      swiperRef = swiper;
    };
    const onSwiperChange = () => {
      activeSlide.value = swiperRef.activeIndex;
    }
    const slideTo = (index) => {
      activeSlide.value = index;
      swiperRef.slideTo(index);
    };

    return {
      sliderCustomNavigationObject,
      swiperRef: null,
      activeSlide,
      setSwiperRef,
      onSwiperChange,
      slideTo,
      modules: [Autoplay, FreeMode, Navigation, Pagination],
    };
  },
};
</script>
