<template>
  <div
    class="category-image-teaser__entry--wrapper"
  >
    <a
      class="category-image-teaser__entry"
      :href="categoryImage.categoryLink"
    >
      <img
        class="category-image-teaser__entry--icon"
        :src="categoryImage.image.path"
        :alt="categoryImage.image.alt"
      >
      <span class="category-image-teaser__entry--label">{{ categoryImage.categoryName }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    categoryImage: Object,
  }
}
</script>