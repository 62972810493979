<template>
  <div class="pagination">
    <div
      v-if="showItemsPerPage"
      class="pagination__items-per-page"
    >
      <select-vue
        class="pagination__items-per-page--select"
        ref="paginationSelect"
        :aria-label="$t('global.pagination.items.per.page.count.label')"
        :initial-value="pagination.entriesPerPage.toString()"
        :options="itemsPerPageOptions"
        :use-native-on-mobile="false"
        :use-custom-select="true"
        :without-border="true"
        :key="pagination.entriesPerPage"
        @change="selectItemPerPage"
      />
      <span>{{ $t('global.pagination.itemsPerPage') }}</span>
    </div>
    <span
      v-if="pagination.pagesCount !== 1"
      class="pagination__button pagination__button--prev"
      :class="{
        'pagination__button--disabled': pagination.currentPage === 1
      }"
      @click="buttonClickHandler(prev)"
    />
    <span class="pagination__label">{{ $t('productlist.page.label') }}</span>
    <select
      v-if="showGoTo"
      class="pagination__select"
      :aria-label="$t('category.page.count.input.label')"
      :class="{
        'pagination__select--disabled': pagination.pagesCount === 1
      }"
      :disabled="pagination.pagesCount === 1"
      v-model="selectPageValue"
    >
      <option
        v-for="page in pagination.pagesCount"
        :key="page"
        :value="page"
      >
        {{ page }}
      </option>
    </select>
    <span
      v-else
      class="pagination__page-current"
    >
      {{ pagination.currentPage }}
    </span>
    <span class="pagination__label">{{ $t('productlist.page.of.label') }}</span>
    <span class="pagination__pages-count">{{ pagination.pagesCount }}</span>
    <span
      v-if="pagination.pagesCount !== 1"
      class="pagination__button pagination__button--next"
      :class="{
        'pagination__button--disabled': pagination.currentPage === pagination.pagesCount
      }"
      @click="buttonClickHandler(next)"
    />
  </div>
</template>

<script>
import SelectVue from "../select/select.vue";
import ScrollToElement from "../../utilities/js/scrollToElement/scrollToElement";

export default {
    components: {
      SelectVue
    },
    props: {
      pagination: Object,
      showGoTo: {
        type: Boolean,
        default: true
      },
      showItemsPerPage: {
        type: Boolean,
        default: false
      },
      maxSize: String,
      id: String
    },
    emits: ['elements-per-page', 'goto-page', 'prev-page', 'next-page'],
    data() {
      return {
        prev: "prev-page",
        next: "next-page",
      };
    },
    computed: {
      selectPageValue: {
        get() {
          return this.pagination.currentPage;
        },
        set(value) {
          this.$emit("goto-page", {value: value})
        }
      },
      itemsPerPageOptions() {
        let options = [];
        if (this.maxSize > 5) options.push({value: "5", label: "5"});
        if (this.maxSize > 15) options.push({value: "15", label: "15"});
        if (this.maxSize > 30) options.push({value: "30", label: "30"});
        options.push({value: this.maxSize, label: this.$t('global.pagination.showAll.label')});
        return options;
      }
    },
    methods: {
      buttonClickHandler(direction) {
        this.$emit(direction);
        this.scrollToElementsTop();
      },
      selectItemPerPage(value) {
        this.$emit("elements-per-page", {value: value.value})
      },
      scrollToElementsTop() {
        const section = document.getElementById(this.id);
        ScrollToElement(section);
      }
    },
  };
</script>
