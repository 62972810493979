<template>
  <div class="account-personal-data-form">
    <!-- Personal data -->
    <div class="account-personal-data-form__wrapper">
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.salutation.label') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.salutation"
          :disabled="!editable"
        >
      </div>
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.firstname.label') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.firstname"
          :disabled="!editable"
        >
      </div>
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.lastname.label') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.lastname"
          :disabled="!editable"
        >
      </div>
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.phone.label') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.phoneSimple"
          :disabled="!editable"
        >
      </div>
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.phone.mobile.label') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.mobilePhoneSimple"
          :disabled="!editable"
        >
      </div>
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.personal.data.additional') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.addressAddition"
          :disabled="!editable"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editable: false,
    }
  },
  props: {
    address: Object
  }
}
</script>