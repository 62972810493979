<template>
  <div
    class="product-variations"
    v-if="name === selectedVariationOption || editProduct"
  >
    <span
      v-if="productDetail"
      class="product-variations__name"
    >
      {{ display_string }}:
    </span>
    <ul class="product-variations__list">
      <li
        class="product-variations__variation"
        v-for="(option, number) in variations"
        :key="number"
      >
        <label
          class="radio-input"
          :class="{'radio-input--disabled' : !option.isAvailable}"
          :for="`${name}_${number}_${index}`"
        >
          <input
            type="radio"
            :name="`${name}_${index}`"
            :value="option.value"
            :id="`${name}_${number}_${index}`"
            class="radio-input__input"
            ref="radio"
            v-model="value"
            @change="productUpdateHandler"
            :disabled="!option.isAvailable"
          >
          <span
            class="radio-input__box"
            :class="[option.valueThumb ? `| swatch-color_${option.valueThumb}` : '']"
          >
            {{ !!option.valueThumb ? '' : option.label }}
          </span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
    props: {
      variations: {
        type: Array,
        default: () => []
      },
      name: String,
      display_string: String,
      initialSelectedValue: String,
      index: Number,
      productDetail: {
        type: Boolean,
        default: false
      },
      variationOption: {
        type: Array,
        default: () => []
      },
      editProduct: Boolean
    },
    computed: {
      selectedVariationOption(){
        return "var[" + this.variationOption[0] + "]";
      },
    },
    emits: ['changeVariation'],
    data() {
      return {
        value: this.initialSelectedValue ? this.initialSelectedValue : this.variations[0].value,
      };
    },
    updated() {
      this.value = this.initialSelectedValue ? this.initialSelectedValue : this.variations[0].value
    },
    methods: {
      productUpdateHandler() {
        this.$emit('changeVariation');
      },
      getValue() {
        return this.value;
      },
      setValue(newValue) {
        this.value = newValue;
      }
    }
  };
</script>
