<template>
  <div class="order-summary">
    <div
      v-if="showHeadline"
      class="order-summary__row"
    >
      <span class="h3">{{ $t('checkout.basket.total.amount.label') }}</span>
    </div>
    <div class="order-summary__row">
      <span class="order-summary__key">
        {{ $t('checkout.basket.invoiceDetails.cumulatedItemAmount') }}
      </span>
      <span
        v-if="user.showVat"
        class="order-summary__value"
      >
        {{ productsCosts.displayValue }}
      </span>
      <span
        v-else
        class="order-summary__value"
      >
        {{ withoutVat }}
      </span>
    </div>
    <div
      v-if="discount"
      class="order-summary__row"
    >
      <span class="order-summary__key">
        {{ $t('orderDiscountLabel') }}
      </span>
      <span class="order-summary__value">
        -{{ discount.displayValue }}
      </span>
    </div>

    <!--  VAT Display  -->
    <!--    <div
      v-for="(vatInfo, index) in vat"
      :key="index"
      class="order-summary__row"
    >
      <span
        v-if="user.showVat"
        class="order-summary__key order-summary__key&#45;&#45;secondary"
      >
        {{ $t('checkout.basket.invoiceDetails.b2c.vat', {'0': vatInfo.vatRate}) }}
      </span>
      <span class="order-summary__value order-summary__value&#45;&#45;secondary">
        {{ vatInfo.priceVat.displayValue }}
      </span>
    </div>-->

    <div
      class="order-summary__row"
      v-if="shippingCosts"
    >
      <span class="order-summary__key order-summary__key--secondary">
        <span class="order-summary__key--secondary">
          {{ $t('checkout.basket.invoiceDetails.normalDeliveryAmount.label') }}
        </span>
        <span
          class="order-summary__key--icon"
          @click.stop="showModal"
        />
        <modal
          id="order-summary-modal"
          :headline="$t('checkout.basket.invoiceDetails.normalDeliveryAmount.label')"
        >
          <div v-html="$t('checkout.basket.invoiceDetails.normalDeliveryAmount.info.calculation', [shippingCostMulitplier])" />
        </modal>
      </span>
      <span class="order-summary__value order-summary__value--secondary">
        {{ shippingCosts.displayValue }}
      </span>
    </div>
    <slot />
    <div class="order-summary__row order-summary__row--main">
      <span class="order-summary__key order-summary__key--main">
        {{ $t('orderSummary.totalAmount') }}
      </span>
      <span class="order-summary__value order-summary__value--main">
        {{ total.displayValue }}
      </span>
    </div>
    <div class="order-summary__row--hint">
      <span class="order-summary__hint"
      >
        {{ $t('checkout.basket.invoiceDetails.exclVAT') }}
        <template
          v-if="shippingCosts.value !== 0"
        >
          {{ $t('checkout.basket.invoiceDetails.plusShippingCosts') }}
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import Modal from "../modal/modal.vue";

export default {
  components: {
    Modal
  },
  mixins: [
    globalStateComputedMixin
  ],
  props: {
    productsCosts: {
      type: Object,
      default: () => {
      }
    },
    discount: {
      type: Object,
      default: () => {
      }
    },
    shippingCosts: {
      type: Object,
      default: () => {
      }
    },
    shippingCostMulitplier: {
      type: Number,
      default: ''
    },
    total: {
      type: Object,
      default: () => {
      }
    },
    showHeadline: {
      type: Boolean,
      default: true
    },
    vat: Array,
  },
  computed: {
    withoutVat() {
      let total = this.productsCosts.value;
      this.vat.forEach(a => total -= a.priceVat.value);
      return this.formattedPrice(total, this.productsCosts.currency);
    }
  },
  methods: {
    showModal() {
      this.$store.dispatch('showModal', {id: 'order-summary-modal', action: 'open'})
    },
  }
};
</script>
