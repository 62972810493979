<template>
  <div class="one-page-checkout__wrapper">
    <section class="one-page-checkout__user-section">
      <a
        :href="mainHref"
        class="header__logo-link"
        aria-label="Startpage"
      >
        <img
          class="header__logo"
          v-if="isMobile"
          :alt="logoTitle"
          :title="logoTitle"
          :src="`${baseAssetsUrl}/staticImages/audi_rings-black.svg`"
        >
        <img
          class="header__logo"
          v-else
          :alt="logoTitle"
          :title="logoTitle"
          :src="`${baseAssetsUrl}/staticImages/${logoImage}`"
        >
      </a>
      <a
        class="one-page-checkout__user-section-back-button"
        :href="basketPageUrl"
        v-t="'global.back_to_basket.button'"
      />
      <h1
        class="headline headline--type_h3"
        v-t="'global.user.your_data.headline'"
      />
      <ul>
        <li>
          <h2
            class="big-semi-bold"
            v-t="'order.address.invoice'"
          />
          <div v-if="checkoutObject.shBillingAddresses.length > 0">
            <radio-inputs-list
              :options="createOptions(checkoutObject.shBillingAddresses)"
              :initial-selected-option="billingId"
              name="shBillingAddress"
              @input="setBillingAddressId"
            />
          </div>
          <div v-else>
            {{ $t("checkout.address.billing.empty") }}
          </div>
          <hr>
        </li>
        <li>
          <h2
            class="big-semi-bold"
            v-t="'order.address.shipping'"
          />
          <div v-if="checkoutObject.shShippingAddresses.length > 0">
            <radio-inputs-list
              :options="createOptions(checkoutObject.shShippingAddresses)"
              :initial-selected-option="shippingId"
              name="shShippingAddress"
              @input="setShippingAddressId"
            />
          </div>
          <div v-else>
            {{ $t("checkout.address.shipping.empty") }}
          </div>
          <hr>
        </li>
        <li>
          <h2
            class="big-semi-bold"
            v-t="'order.payment.method.label'"
          />
          <p v-t="`order.payment.method.${checkoutObject.paymentType}`" />
          <hr class="one-page-checkout__mobile-separator">
        </li>
      </ul>
    </section>
    <section class="one-page-checkout__basket-section">
      <div class="one-page-checkout__basket-section-inner">
        <h1
          class="headline headline--type_h3"
        >
          {{ $t('checkout.basket.label') }}
          <span class="one-page-checkout__header--count big-regular">
            {{ productsCount }}
            <template v-if="productsCount > 1">
              {{ $t('global.articles.label') }}
            </template>
            <template v-else>
              {{ $t('global.article.label') }}
            </template>
          </span>
        </h1>
        <template v-for="(product, i) in basketPositions">
          <product-order-position
            v-if="i < 2"
            v-bind="product"
            :key="product.simpleId"
            class="cart-page__content__product one-page-checkout__product"
            type="checkout"
          />
          <product-order-position
            v-if="showMore && i >= 2"
            v-bind="product"
            :key="product.simpleId"
            class="cart-page__content__product one-page-checkout__product"
            type="checkout"
          />
        </template>
        <div
          v-if="showMoreText"
          class="one-page-checkout__showMore"
          :class="[showMore ? 'show-less' : 'show-more']"
          @click="toggleShowMore"
        >
          {{ showMore ? $t('global.number.product.less') : $t('global.number.product.more', [productsCount - 2]) }}
        </div>
        <hr>
        <order-summary
          class="cart-page__order-summary"
          :products-costs="promotionResult.subtotal"
          :shipping-costs="promotionResult.deliveryResult.deliveryCost"
          :shipping-cost-mulitplier="promotionResult.deliveryResult.shipCostMultiplierValue"
          :total="promotionResult.total"
          :show-headline="false"
        />
        <form
          class="one-page-checkout__submit"
          novalidate
          method="post"
        >
          <input
            type="text"
            hidden="hidden"
            name="billingId"
            :value="billingId"
          >
          <input
            type="text"
            hidden="hidden"
            name="shippingId"
            :value="shippingId"
          >
          <csrf-input
            ref="csrf"
          />
          <p
            class="one-page-checkout__basket-section-legal-info"
            v-html="$t('checkout.legal_info')"
          />
          <button
            class="button button--filled-default"
            type="submit"
            v-t="'checkout.button.buy_now'"
          />
        </form>
        <div class="one-page-checkout__error-wrapper">
          <span
            class="one-page-checkout__error-message"
            v-if="checkoutObject.errorMessage"
          >
            {{ checkoutObject.errorMessage }}
          </span>
        </div>
      </div>
    </section>
    <section class="one-page-checkout__footer">
      <hr class="one-page-checkout__mobile-separator">
      <ul>
        <li>
          <a
            :href="baseUrl + $t('global.terms.link')"
            v-t="'global.terms'"
          />
        </li>
        <li>
          <a
            :href="baseUrl + $t('global.imprint.link')"
            v-t="'global.imprint'"
          />
        </li>
        <li>
          <a
            :href="baseUrl + $t('global.privacy.link')"
            v-t="'global.privacy'"
          />
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import CsrfInput from "../csrfInput/csrfInput.vue";
import ProductOrderPosition from "../productOrderPosition/productOrderPosition.vue";
import OrderSummary from "../orderSummary/orderSummary.vue";
import RadioInputsList from "../radioInputsList/radioInputsList.vue";
import {breakpoints} from "../../variables/variables";

export default {
  components: {
    CsrfInput,
    ProductOrderPosition,
    OrderSummary,
    RadioInputsList,
  },
  mixins: [
    globalStateComputedMixin
  ],
  props: {
    checkout: {
      type: String,
      required: true
    },
    logoImage: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      showMore: false,
      billingId: "",
      shippingId: "",
      isMobile: window.innerWidth < breakpoints.laptop,
    };
  },
  created() {
    if (this.checkoutObject.shBillingAddresses.length > 0) {
      this.billingId = this.createAddressOption(this.checkoutObject.shBillingAddresses[0]).value;
    }
    if (this.checkoutObject.shShippingAddresses.length > 0) {
      this.shippingId = this.createAddressOption(this.checkoutObject.shShippingAddresses[0]).value;
    }
  },
  computed: {
    checkoutObject() {
      return JSON.parse(this.checkout);
    },
    basketPositions() {
      return this.checkoutObject.basket.positions;
    },
    productsCount() {
      return this.checkoutObject.basket.positions.length;
    },
    promotionResult() {
      return this.checkoutObject.basket.promotionResult;
    },
    showMoreText() {
      return this.productsCount > 2;
    }
  },
  mounted() {
    window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
  },
  methods: {
    createOptions(addresses) {
      let options = [];
      for (let i = 0; i < addresses.length; i++) {
        options.push(this.createAddressOption(addresses[i]))
      }
      return options;
    },
    createAddressOption(address) {
      let addressAdditon = address.addressAddition !== undefined ? address.addressAddition + ", " : "";
      let addressInfo = [addressAdditon,
        address.street,
        address.streetNumber + ",",
        address.zipCode,
        address.city + ",",
        address.country];
      return {
        value: address.internalId,
        label: addressInfo.join(" "),
      };
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    setBillingAddressId(value) {
      this.billingId = value;
    },
    setShippingAddressId(value) {
      this.shippingId = value;
    },
    breakpointChangeHandler() {
      this.isMobile = window.innerWidth < breakpoints.laptop;
    }
  }
}
</script>
