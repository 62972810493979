<template>
  <div class="category-image-teaser">
    <div
      v-if="categoryImages.headline"
      class="category-image-teaser__headline headline headline--type_h2"
    >
      {{ categoryImages.headline }}
    </div>
    <div class="category-image-teaser__content-wrapper">
      <category-image
        v-for="(categoryImage, index) in categoryImages.imageModels"
        :key="`category-image__${index}`"
        :category-image="categoryImage"
      />
    </div>
  </div>
</template>

<script>
import CategoryImage from "./categoryImage.vue";

export default {
  components: {
    CategoryImage,
  },
  props: {
    categoryImages: Object,
  },
}
</script>