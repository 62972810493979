<template>
  <div
    class="product-features"
    :id="id"
  >
    <accordion
      ref="accordion"
      class="product-features__accordion"
      :is-content-html="true"
      :id="id"
      :name="$t('featuresName')"
    >
      <div
        slot="content"
        class="product-features__list-wrapper | html-teaser"
      >
        <div class="product-features__list">
          <div
            class="product-features__element product-features__item"
            :class="{'product-features__item-even': featureIndex % 2 === 0}"
            v-for="(feature, featureIndex) in updatedFeatures"
            :key="`feature-${featureIndex}`"
          >
            <div
              class="product-features__item-name"
            >
              {{ feature.name }}
            </div>
            <div
              class="product-features__item-value"
            >
              <div
                v-for="(value, valueIndex) in feature.values"
                :key="valueIndex"
                class="product-features__element"
              >
                <img
                  v-if="feature.images[valueIndex]"
                  class="product-features__item-image"
                  :src="feature.images[valueIndex].src"
                  :alt="value"
                  :title="value"
                >
                <span>{{ value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </accordion>
  </div>
</template>
<script>
  import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';
  import productPageStateComputedMixin from '../../utilities/vueMixins/productPageStateComputedMixin/productPageStateComputedMixin.js';
  import Accordion from '../accordion/accordion.vue';

  export default {
    components: {
      Accordion
    },
    mixins: [
      translationsMixin,
      productPageStateComputedMixin
    ],
    props: {
      id: String
    },
    computed: {
      updatedFeatures() {
        return this.productDetails.features;
      }
    },
    methods: {
      openAccordion() {
        this.$refs.accordion.open();
      }
    }
  }
</script>
