const dateMappings = {
  "en":
    {
      separator: "/",
      format: "MM/dd/yyyy"
    },
  "de":
    {
      separator: ".",
      format: "dd.MM.yyyy"
    }
};

export default dateMappings;
