import backendRequestService from "../backendRequest/backendRequest";

let service;

class Cart {
  constructor() {
    this.urls = window.__globalConfig.apiUrls.cart;
  }

  removeSingleProduct(requestData) {
    requestData['title'] = document.title;
    return backendRequestService().deleteBackendRequest(this.urls.remove, requestData)
      .then((response) => {
        if (response.status === 200 || response.ok) {
          return response.json();
        }
    });
  }

  changeQuantity(data) {
    return createRequest(data, this.urls.edit);
  }

  addPromoItem(data) {
    return createRequest(data.data, data.url);
  }

  addExpeditiousItem(data) {
    return createRequest(data, '/basket/addExpeditious/');
  }

  update(data) {
    return createRequest(data, '/basket/update/', true);
  }

  activate(data) {
    return createRequest(data, '/basket/activate/');
  }

  delete(data) {
    return createRequest(data, '/basket/delete/');
  }
}

function createRequest(data, url, json = false, method = 'POST') {
  return backendRequestService().doBackendRequest(url, data, method, {}, json)
    .then(response => {
      return response.json();
    });
}

function cart() {
  if (service) {
    return service;
  } else {
    service = new Cart();
    return service;
  }
}

export default cart;
