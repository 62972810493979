const UrlParameterMixin = {
  methods: {
    paramsToObject(entries) {
      const result = []
      for(const [key, value] of entries) {
        result.push(
            {
              name: key,
              value: value
            }
        );
      }
      return result;
    },
  }
};

export default UrlParameterMixin;

