function createParametersString(parameters) {
  let parametersString = '';

  for (let parameter of parameters) {
    parametersString += `${parameter.name}=${parameter.value}&`;
  }

  return parametersString.replace(/&$|&{2,}/, '');
}

export default createParametersString;
