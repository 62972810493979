import wishlistPageState from './wishlistPageState.js';
import wishlistPageMutations from './wishlistPageMutations.js';
import wishlistPageGetters from './wishlistPageGetters.js';
import wishlistPageActions from './wishlistPageActions.js';

const store = {
  namespaced: true,
  state: wishlistPageState,
  mutations: wishlistPageMutations,
  getters: wishlistPageGetters,
  actions: wishlistPageActions
};

export default store;
