<template>
  <nav
    v-if="isMobile"
    @orientationchange="isMobile"
    class="burger-menu"
    :class="{
      'burger-menu--open': isBurgerMenuOpen
    }"
  >
    <div class="burger-menu__header">
      <img
        class="burger-menu__logo"
        :alt="logoTitle"
        :title="logoTitle"
        :src="`${baseAssetsUrl}/staticImages/audi_rings-black.svg`"
      >
      <span
        href="#"
        name="burger-menu"
        class="burger-menu__close-button"
        @click.prevent="closeButtonClick"
        role="button"
      />
    </div>
    <div class="burger-menu__nav-wrapper">
      <user-shortcut-header
        class="burger-menu__user-shortcuts"
        :class-name="'burger-menu__user-shortcut'"
      />
      <div
        class="burger-menu__select-car-model-mobil-wrapper"
      >
      <select-car-model-flyout
        v-if="isBurgerMenuOpen"
        :id="`select-car-model-flyout`"
        @show-car-model-flyout="checkShowModal"
      />
      </div>
      <burger-nav-tree
        class="burger-menu__navtree"
        :categories="shopCategoriesTree"
      />
    </div>
    <div class="burger-menu__button-wrapper">
      <language-switcher :without-border="true" />
      <button
        @click="logout"
        class="button button--outlined-default"
      >
        {{ $t('global.logout') }}
      </button>
    </div>
  </nav>
</template>

<script>
import pigeon from '../../utilities/js/pigeon/pigeon.js';
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';
import BurgerNavTree from '../burgerNavTree/burgerNavTree.vue';
import {breakpoints} from '../../variables/variables.js';
import LanguageSwitcher from "../languageSwitcher/languageSwitcher.vue";
import UserShortcutHeader from "../userShortcut/userShortcutHeader.vue";
import SelectCarModelFlyout from "../selectCarModelFlyout/selectCarModelFlyout.vue";

export default {
    mixins: [
      globalStateComputedMixin
    ],
    components: {
      SelectCarModelFlyout,
      BurgerNavTree,
      UserShortcutHeader,
      LanguageSwitcher
    },
    data() {
      return {
        navMinHeight: 0,
        showModal: false
      };
    },
    mounted() {
      pigeon.subscribe('burgernavtree:levelchange', this.navTreeLevelChangeHandler.bind(this));
    },
    methods: {
      navTreeLevelChangeHandler() {
        this.$el.scrollTop = 0;
      },
      closeButtonClick() {
        this.$store.dispatch('toggleBurgerMenu');
      },
      isMobile() {
        return window.innerWidth <= breakpoints.laptop;
      },
      logout() {
        window.location = this.logoutPageUrl
      },
      checkShowModal(showModal) {
        this.showModal = showModal;
      }
    }
  };
</script>
