<template>
  <div
    class="lazy-load-image"
    :class="{
      'lazy-load-image--fallback' : imageIsMissing
    }"
  >
    <img
      class="lazy-load-image__image"
      :class="{
        'lazy-load-image__image--not-loaded': imageIsMissing
      }"
      :src="imageCanBeLoaded ? imageURL(src, scalingWidth) : null"
      :title="title"
      :alt="alt"
      @load="imageLoadHandler"
      @error="imageLoadErrorHandler"
    >
    <span
      v-if="!imageLoaded && !loadError"
      class="lazy-load-image__loader"
    />
    <span
      v-if="loadError"
      class="lazy-load-image__error"
    />
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import {imageSizeDefault, imageUrlWithParam} from "../../utilities/js/image/image";

export default {
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    loadOn: {
      type: String,
      default: 'windowPosition'
    },
    src: String,
    alt: String,
    title: String,
    scalingWidth: {
      type: Number,
      default: imageSizeDefault
    }
  },
  data() {
    return {
      imageCanBeLoaded: !this.enabled,
      imageLoaded: !this.enabled,
      loadError: false
    };
  },
  computed: {
    imageIsMissing() {
      return !this.imageLoaded || this.loadError;
    }
  },
  mounted() {
    if (this.enabled && this.loadOn === 'windowPosition') {
      this.windowPositionObserverDebounced = _debounce(this.windowPositionObserver.bind(this), 300);
      window.addEventListener('scroll', this.windowPositionObserverDebounced);
      this.windowPositionObserver();
    }
  },
  methods: {
    windowPositionObserver() {
      let elementPosition = window.pageYOffset + this.$el.getBoundingClientRect().top;
      let windowBottomPosition = window.pageYOffset + window.innerHeight;

      if (windowBottomPosition >= elementPosition) {
        this.imageCanBeLoaded = true;
        this.loadError = false;
      }
    },
    imageLoadHandler() {
      this.imageLoaded = true;
      this.loadError = false;

      if (this.enabled && this.loadOn === 'windowPosition') {
        window.removeEventListener('scroll', this.windowPositionObserverDebounced);
      }
    },
    imageLoadErrorHandler() {
      this.loadError = true;
    },
    loadImage() {
      if (this.loadOn === 'methodCall') {
        this.imageCanBeLoaded = true;
      }
    },
    imageURL(src, width) {
      return imageUrlWithParam(src, width)
    }
  },
};
</script>
