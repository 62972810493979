import scrollTo from 'scroll-to';

class ScrollTo {
  constructor(mainElement, name = 'scroll-to') {

    this.mainElement = mainElement;
    this.name = name;

    this.elementToBescrolledTo = document.getElementById(this.mainElement.getAttribute('href').substring(1));
    if(this.elementToBescrolledTo){
      this.elementToBescrolledToTop = this.elementToBescrolledTo.getBoundingClientRect().top;
      this.bindEvents();
    }
  }

  bindEvents() {
    this.mainElement.addEventListener('click', () => {
      this.srollTo();
    });
  }

  srollTo() {
    scrollTo(0, this.elementToBescrolledToTop);
  }
}

export default ScrollTo;
