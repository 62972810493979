function randomID(a, b) {
  const prefix = typeof a == 'string' && a ? `${a}-` : (typeof b == 'string' && b ? `${b}-` : '');
  const length = typeof a == 'number' && a ? a : (typeof b == 'number' && b ? b : 6);
  const chars = '1234567890abcdefghijklmnoprstuvwxyz';
  let randomString = prefix;

  for(let i = 0; i < length; i++){
    randomString += chars[Math.round(Math.random() * (chars.length - 1))];
  }

  return randomString;
}

export default randomID;
