<template>
  <div
    class="account-page account-orders-list | user-area"
  >
    <h1 class="user-area__name | headline h1">
      {{ headline }}
    </h1>
    <div
      v-if="showMenu"
      class="user-area__column"
    >
      <account-sidebar
        @changeLocation="toggleMobileView"
      />
    </div>
    <div
      v-if="showRight"
      class="user-area__content"
    >
      <a
        v-if="isMobile && showBackButton"
        class="account__back-button"
        @click="goBackHandler()"
        v-t="'account.button.go_back.account'"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import {breakpoints} from "../../variables/variables";
import AccountSidebar from "../accountSidebar/accountSidebar.vue";

export default {
  components: {
    AccountSidebar
  },
  mixins: [
    globalStateComputedMixin
  ],
  props: {
    headline: String,
    showBackButton: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      isMobile: window.innerWidth <= breakpoints.tablet,
      showMenu: true,
      showRight: true
    };
  },
  created() {
    this.breakpointChangeHandler();
    this.initialView()
  },
  mounted() {
    window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
  },
  methods: {
    goBackHandler() {
      window.location = this.mobileAccountMenu;
    },
    initialView() {
      if (this.isMobile && this.showBackButton) {
        this.showMenu = false;
        this.showRight = true;
      }
    },
    toggleMobileView(href) {
      if (this.isMobile) {
        this.showMenu = !this.showMenu;
        this.showRight = !this.showRight;
      } else {
        window.location = href
      }
    },
    breakpointChangeHandler() {
      this.isMobile = window.innerWidth <= breakpoints.tablet;
      if (this.isMobile && this.showBackButton) {
        this.showMenu = false;
        this.showRight = true;
      } else if (this.isMobile && this.showRight) {
        this.showRight = true;
        this.showMenu = false;
      } else {
        this.showRight = true;
        this.showMenu = true;
      }
    }
  }
};
</script>
