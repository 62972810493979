<template>
  <div
    class="image-teasers-set"
    :class="[count ? `image-teasers-set--items_${count}` : '']"
  >
    <div
      v-for="(teaser, index) in teasers"
      :key="index"
      class="image-teasers-set__teaser"
    >
      <image-teaser
        v-bind="teaser"
      />
    </div>
  </div>
</template>
<script>
  import ImageTeaser from '../imageTeaser/imageTeaser.vue';

  export default {
    components: {
      ImageTeaser
    },
    props: {
      count: Number,
      teasers: Array,
      data: Object
    }
  };
</script>
