<template>
  <a
    class="brand-tile"
    :href="url"
    :target="[isOpenLinkInNewWindow ? '_blank' : '']"
  >
    <img
      v-if="image"
      class="brand-tile__image"
      :src="image.src"
      :alt="image.alt"
      :title="image.title"
    >
  </a>
</template>

<script>
export default {
  props: {
    image: Object,
    url: String,
    isOpenLinkInNewWindow: Boolean,
  }
}
</script>
