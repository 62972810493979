import productService from '../../services/product/product.js';
import cartService from '../../services/cart/cart.js';
import cartPage from "../../services/cart/cart";
import productPage from "../../services/productPage/productPage";

const actions = {
  toggleBurgerMenu({commit, state}) {
    if (state.isBurgerMenuOpen) {
      commit('hideBurgerMenu');
      commit('hideOverlay');
      document.body.classList.remove('modal-open')
    } else {
      commit('showBurgerMenu');
      commit('showOverlay');
      document.body.classList.add('modal-open')
    }
  },
  showModal({commit}, data) {
    commit('showModal', data);
    document.body.classList.add('modal-open')
  },
  hideModal({commit}, data) {
    commit('hideModal', data);
    document.body.classList.remove('modal-open')
  },
  handleEditProduct(store, data) {
    productService()
      .postNewProductData(data.url, data.data)
      .then((data) => {
        if (data) {
          if (data.wishlist.length > 0) {
            store.commit('updateWishlist', data.wishlist);
          } else if (data.cart) {
            store.commit('updateCart', data.cart);
          }
          store.dispatch('hideModal', {id: store.state.currentModalId});
        }
      });
  },
  editArticleInFlyout(store, data) {
    store.commit('editArticleInFlyout', data);
  },
  reduceCount(store, data) {
    store.commit('reduceCount', data);
  },
  addCount(store, data) {
    store.commit('addCount', data);
  },
  updateCart(store, data) {
    return cartService()
      .update(data)
      .then(response => {
        store.commit('updateCart', response.cart);
        store.commit('updateCarts', response.carts);
        store.dispatch('hideModal', {id: store.state.currentModalId});
      });
  },
  activateCart(store, data) {
    cartService()
      .activate(data)
      .then(response => {
        store.commit('updateCart', response.cart);
        store.commit('updateCarts', response.carts);
      });
  },
  deleteCart(store, data) {
    cartService()
      .delete(data)
      .then(response => {
        store.commit('updateCart', response.cart);
        store.commit('updateCarts', response.carts);
      });
  },
  addExpeditiousItem(store, data) {
    return new Promise(resolve => {
      cartPage()
        .addExpeditiousItem(data)
        .then(data => {
          if (!data.errorMessage) {
            store.commit('updateCart', data.cart);
            store.commit('updateCarts', data.carts);
          }
          resolve(data.errorMessage);
        });
    });
  },
  toggleShowVat(store) {
    let response = productPage().toggleVat(store.state.user.showVat);
    response.then(store.commit('toggleShowVat'));
  },
  imageGalleryClicked(store, data) {
    store.commit('initPhotoSwipe', data);
  },
  indexUpdate(store, data) {
    store.commit('indexUpdate', data);
  },
};

export default actions;
