<template>
  <div
    class="promo-item"
    :class="{
      'promo-item__disabled': disabled,
      'promo-item__selected': isSelected
    }"
    @click="buttonClickHandler"
  >
    <img
      class="promo-item__image"
      :class="{
        'promo-item__image--disabled': disabled
      }"
      :src="image.src"
      :title="image.title"
      :alt="image.alt"
    >
    <div
      class="promo-item__info"
      :class="{
        'promo-item__info--disabled': disabled
      }"
    >
      <span
        class="promo-item__name"
      >
        {{ name }}
      </span>
      <span class="promo-item__article-number">{{ number }}</span>
      <div
        class="promo-item__variations"
        v-if="variations && variations.length"
      >
        <span
          v-for="(variation, index) in variations"
          :key="index"
        >
          {{ variation }}
        </span>
        <span
          v-if="isSpecialPricePromotion"
        >
          {{ $attrs.price.displayValue }}
        </span>
      </div>
    </div>
    <div class="promo-item__form">
      <span
        v-if="isSelectable"
        class="promo-item__button"
        :class="{
          'promo-item__button--selected': isSelected
        }"
      />
    </div>
  </div>
</template>

<script>
  import cartService from '../../services/cart/cart.js';

  export default {
    components: {},
    props: {
      actionUrl: String,
      actionId: String,
      isSelectable: {
        type: Boolean,
        default: true
      },
      url: String,
      image: Object,
      name: String,
      number: String,
      variations: {
        type: Array,
        default() {
          return [];
        }
      },
      itemId: String,
      actionType: {
        type: String,
        default: ''
      },
      isSelected: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isSpecialPricePromotion() {
        return this.actionType === 'SPECIAL_PRICE_ITEM'
      },
      disabled() {
        return !this.isSelectable || this.isSelected;
      }
    },
    methods: {
      buttonClickHandler() {
        if (this.isSelectable) {
          cartService()
            .addPromoItem({url: this.actionUrl, data: this.collectFormData()})
            .then(data => {
              if (data) {
                this.$store.dispatch('cartPage/updateCart', data);
              }
            });
        }
      },
      collectFormData() {
        let data = {};
        data.identifier = this.actionId;
        data.itemId = this.itemId;
        data.selected = !this.isSelected;
        return data;
      }
    }
  };
</script>
