<template>
  <div class="navigation-flyout">
    <div class="navigation-flyout__wrapper">
      <div class="navigation-flyout__content">
        <ul
          class="navigation-flyout__categories-list navigation-flyout__categories-list--main"
          :class="{
            'navigation-flyout__categories-list--size_75': hasFlayoutImage
          }"
        >
          <li
            class="navigation-flyout__categories-element navigation-flyout__categories-element--main"
            v-for="(category, categoryIndex) in categories"
            :key="`category_${categoryIndex}`"
          >
            <a
              :href="category.url"
              class="navigation-flyout__categories-link navigation-flyout__categories-link--main"
            >
              {{ category.name }}
            </a>
            <ul
              v-if="category.subcategories && category.subcategories.length"
              class="navigation-flyout__categories-list"
            >
              <li
                class="navigation-flyout__categories-element"
                v-for="(subcategory, subcategoryIndex) in category.subcategories"
                :key="`subcategory_${categoryIndex}_${subcategoryIndex}`"
              >
                <a
                  :href="subcategory.url"
                  class="navigation-flyout__categories-link"
                >
                  {{ subcategory.name }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <picture
          v-if="hasFlayoutImage"
          class="navigation-flyout__image-wrapper"
        >
          <img
            class="navigation-flyout__image"
            :src="flyoutImage.src"
          >
        </picture>
      </div>
      <div
        v-if="allProductsLink"
        class="navigation-flyout__footer"
      >
        <a
          :href="allProductsLink"
          class="navigation-flyout__footer-link"
        >
          {{ $t('allProductsLink') }}<i class="fa fa-angle-right" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';

  export default {
    mixins: [
      translationsMixin
    ],
    props: {
      allProductsLink: String,
      flyoutImage: Object,
      categories: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    computed: {
      hasFlayoutImage() {
        return !!this.flyoutImage && !!this.flyoutImage.src;
      }
    }
  };
</script>
