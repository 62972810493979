<template>
  <div
    v-if="type !== 'checkout' && isUserLoggedIn"
  >
    <user-shortcut
      class="ordered-mobile_2"
      :class="[className, `${className}--login`]"
      id="USER_LOGIN_BTN"
      type="login"
      :active="isUserLoggedIn"
      :label="$t('global.account')"
      :url="loginPageUrl"
      :show-badge="false"
    />
    <user-shortcut
      class="ordered-mobile_1"
      :class="[className, `${className}--cart`]"
      id="SHOPPING_BASKET_BTN"
      type="cart"
      :active="basketCount > 0"
      :label="$t('global.cart')"
      :url="basketPageUrl"
      :show-badge="true"
      :badge-count="basketCount"
      flyout-css-postfix="cart"
    />
  </div>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';
import UserShortcut from "./userShortcut.vue";

export default {
    components: {
      UserShortcut
    },
    mixins: [
      globalStateComputedMixin
    ],
    props: {
      type: String,
      className: String,
    },
    data() {
      return {
      }
    },
  };
</script>
