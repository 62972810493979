<template>
  <div
    :class="withModal ? `${className} ${className}--with-modal` : `${className}`"
    @click.stop.prevent="showModal"
  >
    <span>
      <img
        :class="`${className}__image`"
        :src="imageUrl(imageVersions[0].big, imageSizeDefault())"
        :alt="imageVersions[0].alt"
        :title="images[0].title"
        v-on="!withModal && initPhotoSwipe ? { click: () => imageClickHandler(0, indexKey) } : {}"
      >
    </span>
    <span
      v-if="images.length > 1"
      :class="`${className}__additional-images`"
      v-on="!withModal && initPhotoSwipe ? { click: () => imageClickHandler(0, indexKey) } : {}"
    >
      <span
        :class="`${className}__additional-images--counter headline-big`"
      >
        +{{ images.length - 1 }}
      </span>
      <img
        :class="`${className}__image`"
        :src="imageUrl(imageVersions[1].big, imageSizeDefault())"
        :alt="imageVersions[1].alt"
        :title="images[1].title"
      >
    </span>
    <photo-swipe-gallery
      :id="indexKey"
      :product-name="id"
      :image-versions="imageVersions"
      :init-photo-swipe="!withModal && initPhotoSwipe"
    />
    <modal
      :id="id"
      :gallery="true"
      v-if="withModal"
    >
<!--    ToDo  -->
<!--      <product-gallery
        v-if="imageVersions.length"
        :product-images="imageVersions"
        :disable-zoom="true"
        :product-name="id"
        :index-key="this.indexKey"
        :id="`product_gallery_${this.id}`"
      />-->
    </modal>
  </div>
</template>

<script>
import Modal from '../modal/modal.vue';
import PhotoSwipeGallery from "../photoSwipeGallery/photoSwipeGallery.vue";
import {imageSizeDefault, imageUrlWithParam} from "../../utilities/js/image/image";

export default {
    components: {
      PhotoSwipeGallery,
      Modal
    },
    props: {
      id: String,
      images: Array,
      imageVersions: Array,
      className: {
        type: String,
        default: 'part-gallery'
      },
      withModal: {
        type: Boolean,
        default: false
      },
      initPhotoSwipe: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
      };
    },
    computed: {
      indexKey() {
        return 'part-gallery-fullscreen_' + this.id;
      },
    },
    methods: {
      imageSizeDefault() {
        return imageSizeDefault;
      },
      showModal() {
        if(this.withModal) {
          this.$store.dispatch('showModal', {id: this.id, action: 'open'})
        }
      },
      closeModal() {
        this.$store.dispatch('hideModal', {id: this.availablePart.id, action: 'close'}, {root: true});
        this.close();
      },
      imageClickHandler(index, evenIndexKey) {
        if (!this.withModal && this.initPhotoSwipe && evenIndexKey === this.indexKey) {
          this.$store.dispatch('imageGalleryClicked', {
            showImage: index,
            galleryId: evenIndexKey
          })
        }
      },
      imageUrl(src, width) {
        return imageUrlWithParam(src, width)
      }
    }
  };
</script>
