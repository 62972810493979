const getters = {
  isOnWishlist: (state) => (itemId) => {
    return (state && state.wishlist && state.wishlist.find(el => el.itemId === itemId)) || false;
  },
  isInCart: (state) => (itemId) => {
    return (state && state.cart && state.cart.positions && state.cart.positions.find(el => el.itemId === itemId)) || false;
  }
}


export default getters;
