import { createApp } from 'vue';
import { createStore } from 'vuex';
import { createI18n } from 'vue-i18n';
/*import VeeValidate from 'vee-validate';*/
import store from './store/store.js';
import {JsComponents, VueComponents} from './components/components';
import iShopEvents from './utilities/js/ishopEvents/ishopEvents.js';
import staticImage from './utilities/vueMixins/staticImageMixin/staticImageMixin.js';
import tooltipDirective from './utilities/vueDirectives/tooltipDirective/tooltipDirective';
import generateTranslations from './utilities/js/generateTranslations/generateTranslations';
import breakpointChangeEvent from './utilities/js/breakpointChangeEvent/breakpointChangeEvent.js';
import globalMixin from "./utilities/vueMixins/globalMixin/globalMixin";

class App {
  constructor() {
    const name = 'novosales';
    this.jsComponents = {};
    // let mergeComponents = [];
    const app = createApp({});

    // this.registerVuePlugins();
    // create and initialize an instance of VueI18n
    // This has to be done after configure Vue to use VueI18n!
    const fallbackLocale = 'en';
    const locale = window.__globalConfig.language || fallbackLocale;
    const translations = window.__translations || {};

    let i18n = createI18n({
      fallbackLocale,
      locale,
      messages: {
        [locale]: generateTranslations(translations)
      }
    });
    app.use(i18n);

    // this.createAppStore();
    const vuexAppStore = createStore(store);
    app.use(vuexAppStore);

    /*  #############  */
    /*app.use(VeeValidate, {
      i18n,
      events: 'blur|input'
    });*/

    // this.registerGlobalVueMixins();
    app.mixin(Object.assign(globalMixin, staticImage));

    // this.registerGlobalVueDirectives();
    app.directive('tooltip', tooltipDirective);

    // this.registerUsedVueComponents(mergeComponents);
    // app.config.compilerOptions.isCustomElement = tag => tag === 'plastic-button'
    if (VueComponents) {
      const componentsToRegister = VueComponents.filter(vueComponent =>
          !!document.querySelectorAll(`${vueComponent.name}, [is="${vueComponent.name}"]`).length
      );

      for (const vueComponent of componentsToRegister) {
        app.component(vueComponent.name, vueComponent.constructor);
      }
    }

    // this.createAndMountVueInstance();
    /* this.rootElement = document.getElementById(this.vueRootElementId);
    if(!this.rootElement) {
      throw(`${this.vueRootElementId} as root element not found in DOM, check if rendered App-Id under body-Tag is <div id="{{mcs-brand}}-app">...</div>?`);
    }

    this.vueInstance = new app({
      el: this.rootElement,
      i18n,
      store
    }); */

    // this.initGlobalUtilities();
    iShopEvents();
    breakpointChangeEvent();

    app.config.compilerOptions.whitespace = "preserve";
    app.mount(`#${name}-app`);

    // this.initAllJsComponents();
    if (JsComponents) {
      for (const component of Array.from(JsComponents)) {
        this.initJsComponent(component);
      }
    }
  }

  initJsComponent(component) {
    const componentNodeElements = document.querySelectorAll(`.${component.name}`);

    for (const componentNodeElement of componentNodeElements) {
      if (!Object.prototype.hasOwnProperty.call(this.jsComponents, component.name)) {
        this.jsComponents[component.name] = [];
      }

      this.jsComponents[component.name].push(new component.constructor(componentNodeElement));
    }
  }
}

export default App;
