<template>
  <div
    class="select"
    ref="selectWrapper"
    :class="{
      'inverse': inverse,
      'select__switcher': languageSwitcher,
      'select__without-border': withoutBorder,
      'select__small': small
    }"
  >
    <label
      v-if="label"
      class="select__label"
      :class="[
        required ? 'select__label--required' : '',
        labelType === 'only-mobile' ? 'select__label--only-mobile' : '',
        labelIcon.length > 0 ? labelIcon : '',
      ]"
    >
      {{ label }}
    </label>
    <div
      class="select__wrapper"
      :class="{
        'select__wrapper--error': !!error,
        'select__wrapper--disabled': disabled,
        'select__wrapper--custom': useCustomSelect && !(useNativeOnMobile && isMobile),
        'select__wrapper--active': active,
        'select__wrapper--icon' : useIcons
      }"
      @click.stop="selectWrapperClickHandler"
      tabindex="0"
    >
      <div
        class="select__selected-value"
        :class="{
          'select__selected-value--align-right': alignRight,
          'select__selected-value--icon' : useIcons
        }"
      >
        <img
          v-if="languageSwitcher"
          class="language-switcher__icon"
          :src="iconPath"
        >
        <span class="default-regular">{{ selectedValueLabel }}</span>
      </div>

      <!-- CUSTOM SELECT DROPDOWN -->
      <div
        v-if="useCustom"
        class="select__custom-dropdown"
        :class="{
          'select__custom-dropdown--expand-top': dropdownExpandTop,
          'select__custom-dropdown--visible': customDropdownIsVisible,
          'select__custom-dropdown--icon' : useIcons
        }"
        :style="{
          maxHeight: customDropdownIsVisible ? `${customDropdownMaxHeight}px` : '0px',
          overflowY: customDropdownIsVisible ? `${customDropdownOverflowY}` : 'hidden',
          opacity: customDropdownIsVisible ? 1 : 0
        }"
        ref="customDropdown"
      >
        <div
          class="select__custom-options-wrapper"
          ref="customOptionsWrapper"
        >
          <div
            class="select__custom-option"
            :class="{
              'select__custom-option--align-right': alignRight,
              'select__custom-option--selected': option.value === modelValue,
              'select__custom-option--icon' : useIcons,
              'select__custom-option--icon--selected' : useIcons && option.value === modelValue
            }"
            v-for="(option, index) in options"
            v-show="!option.disabled"
            :key="option.value"
            @click.stop="customOptionClickHandler(index)"
          >
            <slot :option="option">
              <span>{{ option.label }}</span>
            </slot>
          </div>
        </div>
      </div>

      <!-- NATIVE SELECT -->
      <select
        v-show="!useCustom"
        class="select__field"
        ref="nativeselect"
        :name="innerName"
        :disabled="disabled"
        v-model="modelValue"
        :id="id"
        @blur="blurHandler"
        @focus="focusHandler"
        @change="changeHandler"
      >
        <option
          v-for="(option, index) in options"
          :key="index"
          :disabled="option.disabled"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>

    <span
      v-if="!!error"
      class="input__error"
      v-html="error"
    />
  </div>
</template>

<script>
import breakpoints from '../../variables/js/breakpoints.js';
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';

export default {
  mixins: [
    globalStateComputedMixin
  ],
  props: {
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    labelType: String,
    alignRight: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    labelIcon: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    useCustomSelect: {
      type: Boolean,
      default: true
    },
    useNativeOnMobile: {
      type: Boolean,
      default: true
    },
    inverse: {
      type: Boolean,
      default: false
    },
    initialValue: String,
    useIcons: Boolean,
    languageSwitcher: Boolean,
    withoutBorder: Boolean,
    small: Boolean,
  },
  data() {
    return {
      modelValue: null,
      selectedValueLabel: null,
      customDropdownIsVisible: false,
      customDropdownMaxHeight: 0,
      customDropdownOverflowY: 'hidden',
      isMobile: window.innerWidth <= breakpoints.laptop,
      active: false,
      dropdownExpandTop: false,
      innerName: '',
      iconPath: '',
      selectContainerWidth: 'unset',
    };
  },
  created() {
    this.bodyClickHandlerBinded = this.bodyClickHandler.bind(this);
    window.addEventListener('breakpointChange', this.windowBreakpointChangeHandler.bind(this));
  },
  mounted() {
    this.innerName = this.name;
    if (this.$refs.customOptionsWrapper) {
      const dropdownHeight = 300;
      this.customDropdownMaxHeight = dropdownHeight;
      if (this.$refs.customOptionsWrapper.offsetHeight > dropdownHeight) {
        this.customDropdownOverflowY = 'scroll';
      }
      this.selectContainerWidth = this.$refs.customDropdown.offsetWidth + 'px';
    }
    this.setInitialValue();
    this.setNativeSelectValueGetter();
  },
  computed: {
    useCustom() {
      if (this.useCustomSelect && !(this.useNativeOnMobile && this.isMobile)) {
        return true;
      }
      return !!(this.useCustomSelect && this.useIcons);
    },
  },
  methods: {
    setInitialValue() {
      this.modelValue = this.initialValue ? this.initialValue : this.options[0].value;
      this.setSelectedOptionData(this.modelValue);
    },
    setSelectedOptionData: function (value) {
      let selectedOption = this.options.find(option => option.value === value);
      selectedOption = selectedOption ? selectedOption : this.options[0];

      this.modelValue = selectedOption.value;
      this.selectedValueLabel = selectedOption.label;
      if (this.languageSwitcher) {
        this.iconPath = this.staticImagesUrl + selectedOption.icon.src
      }
      this.$emit('input', this.value);
    },
    getValue() {
      return this.modelValue;
    },
    setValue(value) {
      this.modelValue = value;
      this.setSelectedOptionData(this.modelValue);
    },
    reset() {
      this.setInitialValue();
    },
    clear() {
      this.modelValue = this.options[0].value;
      this.setSelectedOptionData(this.modelValue);
    },
    blurHandler() {
      this.active = false;
    },
    focusHandler() {
      this.active = true;
    },
    changeHandler() {
      this.$emit('change', {value: this.modelValue});

      this.$nextTick(() => {
        this.setSelectedOptionData(this.modelValue);
        this.blurHandler();
      });
    },
    setNativeSelectValueGetter() {
      let getValue = this.getValue;

      Object.defineProperty(
        this.$refs.nativeselect,
        'value',
        {
          get() {
            return getValue();
          }
        }
      );
    },
    selectWrapperClickHandler() {
      if (this.useCustomSelect && !(this.useNativeOnMobile && this.isMobile) && !this.disabled) {
        if (!this.customDropdownIsVisible) {
          this.showCustomDropdown();
        } else {
          this.hideCustomDropdown();
        }
      }
    },
    showCustomDropdown() {
      document.querySelector('body').addEventListener('click', this.bodyClickHandlerBinded);
      this.dropdownExpandTop = this.checkIfDropdownShouldExpandTop();

      this.$nextTick(() => {
        this.customDropdownIsVisible = true;
        this.active = true;
      })
    },
    hideCustomDropdown() {
      document.querySelector('body').removeEventListener('click', this.bodyClickHandlerBinded);
      this.customDropdownIsVisible = false;
      this.active = false;
    },
    checkIfDropdownShouldExpandTop() {
      const selectRect = this.$el.getBoundingClientRect();
      return window.innerHeight - selectRect.bottom < this.customDropdownMaxHeight;
    },
    customOptionClickHandler(optionIndex) {
      this.modelValue = this.options[optionIndex].value;
      this.setSelectedOptionData(this.modelValue);
      this.$emit('change', {value: this.modelValue, selectedLabel: this.selectedValueLabel});
      this.hideCustomDropdown();
    },
    bodyClickHandler() {
      this.hideCustomDropdown();
    },
    windowBreakpointChangeHandler() {
      this.isMobile = window.innerWidth <= breakpoints.laptop;
    }
  }
};
</script>
