<template>
<!-- This has to be implemented if this component is needed, see ToDo -->
  <Form
    id="address-form"
    :method="method"
    :validation-schema="validationSchema"
    ref="addressForm"
    novalidate
    :action="action"
    @submit="submitAjaxForm"
    @invalid-submit="onInvalidSubmit"
  >
    <csrf-input
      ref="csrf"
    />

    <div class="form__group">
      <label
        class="input__label input__label--required"
      >
        {{ $t('global.salutation.label') }}
      </label>
      <!--  ToDo  -->
      <radio-input
        name="salutation"
        :label="$t('global.salutation.mr.label')"
        radio-value="MR"
        :initial-value="model.salutation.toUpperCase()"
        @input="showCompanyAdditions = false"
      />
      <radio-input
        v-model="model.salutation"
        name="salutation"
        :label="$t('global.salutation.mrs.label')"
        radio-value="MRS"
        :initial-value="model.salutation.toUpperCase()"
        @input="showCompanyAdditions = false"
      />
      <span
        class="input__error"
        v-if="!!errors.first('salutation')"
        v-html="errors.first('salutation')"
      />
    </div>

    <transition name="slide">
      <div
        v-if="showCompanyAdditions"
        class="form__group"
      >
        <text-input
          v-model="model.company"
          name="company"
          :label="$t('global.company.label')"
          :error="errors.first('company')"
        />
      </div>
    </transition>

    <div class="form__group">
      <!--  ToDo  change to Input-Vee-V  -->
      <input-vue
        v-model="model.firstName"
        name="firstname"
        :label="$t('global.firstname.label')"
        :required="true"
      />
    </div>
    <div class="form__group">
      <!--  ToDo  change to Input-Vee-V  -->
      <input-vue
        v-model="model.lastName"
        name="lastname"
        :label="$t('global.lastname.label')"
        :error="errors.first('lastname')"
        :required="true"
      />
    </div>

    <div class="form__group form__group--row">
      <div
        class="form__col-8"
        v-if="isPackstation"
      >
        <!--  ToDo  change to Input-Vee-V  -->
        <!--    v-validate="{required: isPackstation, min: 6, max: 10}"    -->
        <input-vue
          type="number"
          v-model="model.postNumber"
          name="postNumber"
          :label="$t('global.postNumber.label')"
          :error="errors.first('postNumber')"
          key="postNumber-input"
          :required="isPackstation"
        />
      </div>
      <div
        class="form__col-8"
        v-else
      >
        <!--  ToDo  change to Input-Vee-V  -->
        <!--    v-validate="{required: !isPackstation, min: 2, max: 60}"    -->
        <input-vue
          v-model="model.street"
          name="street"
          :label="$t('global.street.label')"
          :error="errors.first('street')"
          key="street-input"
          :required="!isPackstation"
        />
      </div>
      <div
        class="form__col-4"
        v-if="isPackstation"
      >
        <!--  ToDo  change to Input-Vee-V  -->
        <!--  v-validate="{required: isPackstation, min: 1, max: 4, numeric: true}"  -->
        <input-vue
          v-model="model.packstationNumber"
          name="packstationNumber"
          :label="$t('global.packstationNumber.label')"
          :error="errors.first('packstationNumber')"
          key="packstationNumber-input"
          :required="isPackstation"
        />
      </div>
      <div
        class="form__col-4"
        v-else
      >
        <!--  ToDo  change to Input-Vee-V  -->
        <!--  v-validate="{required: !isPackstation, min: 1, max: 4}"  -->
        <input-vue
          v-model="model.streetNumber"
          name="streetNumber"
          :label="$t('global.streetNumber.label')"
          :error="errors.first('streetNumber')"
          key="streetNumber-input"
          :required="!isPackstation"
        />
      </div>
    </div>

    <div
      class="form__group"
      v-if="!isPackstation"
    >
      <!--  ToDo  change to Input-Vee-V  -->
      <input-vue
        v-model="model.addressAddition"
        name="lastname"
        :label="$t('global.addressAddition.label')"
      />
    </div>

    <div class="form__group form__group--row">
      <div class="form__col-4">
        <!--  ToDo  change to Input-Vee-V  -->
        <!--  v-validate="'required|length:5'"  -->
        <input-vue
          v-model="model.zipCode"
          name="zipCode"
          :label="$t('global.zipcode.label')"
          :error="errors.first('zipCode')"
          :required="true"
        />
      </div>
      <div class="form__col-8">
        <!--  ToDo  change to Input-Vee-V  -->
        <!--  v-validate="'required|min:2|max:60'"  -->
        <input-vue
          v-model="model.city"
          name="city"
          :label="$t('global.city.label')"
          :error="errors.first('city')"
          :required="true"
        />
      </div>
    </div>

    <div class="form__group form__group--row">
      <div class="form__col-6">
        <!--  ToDo  change to Select-Vee-V  -->
<!--        <i-select
          v-model="model.country"
          name="country"
          :options="model.countries"
          :value="model.country"
          :error="errors.first('country')"
          :label="$t('global.country.label')"
          :placeholder="$t('global.country.label')"
          v-validate="'required'"
          :required="true"
        />-->
      </div>
    </div>

    <div class="form__group">
      <button
        type="submit"
        name="address-form-submit-button"
        class="address-form__submit-button | button button--alt button--icon button--icon_next"
        v-t="'global.save.button'"
      />
    </div>
  </Form>
</template>

<script>
import InputVue from '../input/input.vue';
import RadioInput from '../radioInput/radioInput.vue';
import CsrfInput from '../csrfInput/csrfInput.vue';
import FormMixin from '../../utilities/vueMixins/formMixin/formMixin';
// import InputVeeV from "../input/inputVeeV.vue";
import { Form } from 'vee-validate';
import * as yup from 'yup';

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    InputVue,
    // InputVeeV,
    RadioInput,
    CsrfInput,
  },
  mixins: [
    FormMixin
  ],
  data() {
    return {
      validationSchema: yup.object({
        // ToDo complete + error messages
        salutation: yup.boolean().required(),
        firstname: yup.string().required().min(2).max(60),
        lastname: yup.string().required().min(2).max(60),
        // postNumber
        // ...
      }),
      model: this.initialModel,
      showCompanyAdditions: false,
      results: null
    }
  },
  props: {
    initialModel: {
      type: Object
    },
    allowPackstation: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isPackstation() {
      return this.model.packstation;
    }
  },
  methods: {
    onInvalidSubmit({ values, errors, results }) {
      this.results.results = results;
      this.results.values = values;
      this.results.errors = errors;
    },
  }
};
</script>
