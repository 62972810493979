const wishlistPageStateComputedMixin = {
  computed: {
    isWishlist() {
      return this.$store.state.wishlistPage.wishlistItems.length > 0;
    },
    wishlistItems() {
      return this.$store.state.wishlistPage.wishlistItems;
    },
    addAllUrl() {
      return this.$store.state.wishlistPage.addAllUrl;
    }
  }
}

export default wishlistPageStateComputedMixin;
