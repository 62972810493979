<template>
  <div class="available-parts__damage-model">
    <div
      v-if="availablePartDamageModels.length > 0"
      class="available-parts__damage-model--wrapper"
    >
      <div
        :class="type ==='basket' ?
          'available-parts__damage-model--headline-basket default-bold'
          : 'available-parts__damage-model--headline big-semi-bold'"
      >
        {{ $t('global.product.damage') }}
      </div>
      <div
        v-for="(damageModel, indexDamageModel) in availablePartDamageModels"
        :key="`available-part__${indexDamageModel}`"
      >
        <span class="default-regular">{{ $t(damageModel.position) }}: </span>
        <span class="available-parts__damage-model--remark">{{ damageModel.remark }}</span>
        <span class="available-parts__damage-model--delivery-information">{{ $t('ITEM_STOCK_AVAILABLE') }}</span>
      </div>
    </div>
    <div
      v-if="remarkNotice"
      class="available-parts__damage-model--wrapper"
    >
      <div
        :class="type ==='basket' ?
          'available-parts__damage-model--headline-basket default-bold'
          : 'available-parts__damage-model--headline big-semi-bold'"
      >
        {{ $t('global.product.remarks') }}
      </div>
      <div class="available-parts__damage-model--remark" v-html="remarkNotice">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    availablePartDamageModels: {
      type: Array,
      default: () => []
    },
    remarkNotice: String,
    type: String,
  }
}
</script>
