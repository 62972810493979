<template>
  <div
    class="product-description"
    :id="id"
  >
    <accordion
      ref="accordion"
      class="product-description__accordion"
      :is-content-html="true"
      :id="id"
      :initial-open="true"
      :name="$t('descriptionName')"
    >
      <div
        class="product-description__content | html-teaser"
        slot="content"
      >
        <div
          v-if="thisDescription"
          v-html="thisDescription"
        />
        <div
          v-if="thisQualityKeys.length"
          class="product-description__quality-keys"
        >
          <span class="product-description__quality-keys-headline">
            {{ $t('contentName') }}
          </span>
          <ul
            class="product-description__keys-list"
            v-for="(qualityKey, index) in thisQualityKeys"
            :key="index"
          >
            <li class="product-description__keys-item">
              {{ $t(`qualityKey.${qualityKey}`) }}
            </li>
          </ul>
        </div>
      </div>
    </accordion>
  </div>
</template>

<script>
  import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';
  import Accordion from '../accordion/accordion.vue';

  export default {
    components: {
      Accordion
    },
    mixins: [
      translationsMixin
    ],
    props: {
      id: String,
      description: String,
      qualityKeys: Array
    },
    data() {
      return {
        thisQualityKeys: this.qualityKeys,
        thisDescription: this.description
      };
    },
    methods: {
      openAccordion() {
        this.$refs.accordion.open();
      }
    }
  };
</script>
