import JsComponent from '../jsComponent/jsComponent.js';
import pigeon from '../../utilities/js/pigeon/pigeon.js';

class Address extends JsComponent {
  constructor(mainElement, name = 'address') {
    super(mainElement, name);
    this.createClassNames(
      '__button--edit'
    );

    this.editAddressButton = this.mainElement.querySelector(`.${this.classNames.buttonEdit}`);
    if(this.editAddressButton) {
      this.editAddressButtonId = this.editAddressButton.dataset.id;
      this.bindEvents();
    }
  }

  bindEvents() {
    this.editAddressButton.addEventListener('click', () => {
      this.handleEditAddress();
    });
  }

  handleEditAddress() {
    pigeon.publish('addressChangeRequest', this.editAddressButtonId);
  }
}

export default Address;
