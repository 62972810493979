<template>
  <div class="accordions-list">
    <div
      class="accordions-list__html-content"
      v-if="htmlContent"
      v-html="htmlContent"
    />
    <accordion
      class="accordions-list__accordion"
      v-for="(accordion, index) in accordions"
      v-bind="accordion"
      :key="index"
    >
      <template #content>
        <div
          v-html="accordion.content"
        />
      </template>
    </accordion>
  </div>
</template>

<script>
  import Accordion from '../accordion/accordion.vue';

  export default {
    components: {
      Accordion
    },
    props: {
      accordions: {
        type: Array,
        default() {
          return [];
        }
      },
      htmlContent: String
    }
  };
</script>
