import { toRaw } from "vue";

const globalStateComputedMixin = {
  computed: {
    baseUrl() {
      return toRaw(this.$store.state.baseUrl);
    },
    cart() {
      return toRaw(this.$store.state.cart);
    },
    carts() {
      return toRaw(this.$store.state.carts);
    },
    wishlist() {
      return toRaw(this.$store.state.wishlist);
    },
    shopLocale() {
      return toRaw(this.$store.state.shopLocale);
    },
    mainHref() {
      return toRaw(this.$store.state.mainHref);
    },
    logoTitle() {
      return toRaw(this.$store.state.logoTitle);
    },
    baseAssetsUrl() {
      return toRaw(this.$store.state.baseAssetsUrl);
    },
    staticImagesUrl() {
      return this.baseAssetsUrl + '/staticImages/';
    },
    shopCategoriesTree() {
      return toRaw(this.$store.state.shopCategoriesTree);
    },
    searchBaseUrl() {
      return toRaw(this.$store.state.searchBaseUrl);
    },
    isBurgerMenuOpen() {
      return toRaw(this.$store.state.isBurgerMenuOpen);
    },
    isOverlayVisible() {
      return toRaw(this.$store.state.isOverlayVisible);
    },
    isUserLoggedIn() {
      return toRaw(this.$store.state.isUserLoggedIn);
    },
    loginPageUrl() {
      return toRaw(this.$store.state.loginPageUrl);
    },
    logoutPageUrl() {
      return toRaw(this.$store.state.logoutPageUrl);
    },
    showVat() {
      return toRaw(this.$store.state.user.showVat);
    },
    isDealer() {
      return toRaw(this.$store.state.isDealer)
    },
    isImporter() {
      return toRaw(this.$store.state.isImporter)
    },
    omsServiceAgentLoggedInAs() {
      return toRaw(this.$store.state.omsServiceAgentLoggedInAs);
    },
    wishlistPageUrl() {
      return toRaw(this.$store.state.wishlistPageUrl);
    },
    basketPageUrl() {
      return toRaw(this.$store.state.basketPageUrl);
    },
    basketChangeUrl() {
      return toRaw(this.$store.state.basketChangeUrl);
    },
    basketCount() {
      return toRaw(this.$store.state.basketCount);
    },
    wishlistCount() {
      return toRaw(this.$store.state.wishlistCount);
    },
    serviceUrl() {
      return toRaw(this.$store.state.service);
    },
    newsletterFormUrl() {
      return toRaw(this.$store.state.newsletter);
    },
    miniFlyout() {
      return toRaw(this.$store.state.miniFlyout);
    },
    checkoutUrl() {
      return toRaw(this.$store.state.checkoutUrl);
    },
    csrfToken() {
      return toRaw(this.$store.state.csrfToken);
    },
    currentModalId() {
      return toRaw(this.$store.state.currentModalId);
    },
    editedProduct() {
      return toRaw(this.$store.state.editedProduct);
    },
    pageType() {
      return toRaw(this.$store.state.pageType);
    },
    selectedCarModel() {
      return toRaw(this.$store.state.selectedCarModel);
    },
    carModelGroups() {
      return toRaw(this.$store.state.carModelGroups);
    },
    isCarModelSelectionEnabled() {
      return toRaw(this.$store.state.isCarModelSelectionEnabled);
    },
    mobileAccountMenu() {
      return toRaw(this.$store.state.mobileAccountMenu);
    }
  },
  methods: {
    formattedPrice(price, currency) {
      if (price !== null || price !== 'undefined') {
        return new Intl.NumberFormat(toRaw(this.$store.state.shopLocale), {style: 'currency', currency: currency}).format(price);
      }
    },
    currentGalleryIndex() {
      return toRaw(this.$store.state.galleryIndexes[this.id]) || 0;
    }
  }
};

export default globalStateComputedMixin;
