<template>
  <component
    :is="link ? 'a' : 'div'"
    class="simple-image-teaser"
    :href="link.url"
    :target="link.openInNewWindow ? '_blank' : ''"
  >
    <img
      class="simple-image-teaser__image"
      :src="image.src"
      :alt="image.alt"
      :title="image.title"
    >
  </component>
</template>

<script>
export default {
  props: {
    link: Object,
    image: Object,
  }
}
</script>
