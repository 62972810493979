<template>
  <div
    v-if="orderList.length > 0"
    class="account-single-order"
  >
    <a
      class="account__back-button"
      @click="goBackHandler()"
      v-t="'account.button.go_back.orders'"
    />
    <div class="account-single-order__header-wrapper">
      <h2 class="account-single-order__header headline--type_h2">
        {{ $t('account.order.headline', [toLocaleDateString(orderList[0].date)]) }}
      </h2>
      <span
        v-if="isImporter && orderList.some(order => order.number.startsWith('D'))"
        class="account-single-order__details-wrapper-badge default-regular"
      >
          {{ $t('account.order.dealerOrder') }}
      </span>
    </div>
    <div
      class="account-single-order__details-wrapper"
      v-for="(order, i) in orderList"
      :key="`orderdetails-${order.number}-${i}`"
    >
      <template v-if="orderList.length === 1">
        <span>
          {{ $t('account.order.subheadline', [order.number]) }}
        </span>
        <span v-if="order.invoiceId"
              class="account-single-order__invoiceId"
        >
          ({{ order.invoiceId }})
        </span>
        <hr>
      </template>
      <h3
        v-else
        class="account-single-order__details-wrapper-header"
      >
        <span class="headline--type_h3">
          {{ $t('account.order.subheadline', [order.number]) }}
          <span
            v-if="order.invoiceId"
          >
      ({{ order.invoiceId }})
    </span>
        </span>
      </h3>

      <div class="account-single-order__address-document-wrapper">
        <div class="account-single-order__address-wrapper">
          <address-wrapper
            :address="order.billingAddress"
            :headline="$t('global.address.billing')"
          />
          <address-wrapper
            :address="order.shippingAddress"
            :headline="$t('global.address.shipping')"
          />
        </div>
        <div class="account-single-order__document-button">
          <div class="account-single-order__document-align">
            <span>
              <button
                :disabled="!order.invoiceId"
                class="account-single-order__button-download | button button--filled-default"
                v-t="'account.invoice.button'"
                @click="getInvoicePdf(order.number)"
              />
            </span>
            <div
              :id="`account.invoice.button${order.number}`"
              style="display: none"
            >
              {{ $t('account.invoice.download.error') }}
            </div>
          </div>
        </div>
      </div>

      <hr>

      <account-single-order-cart
        :order="order"
        :as-dropdown="orderList.length > 1"
      />

      <hr>

      <div class="account-single-order__order-summary">
        <div class="account-single-order__headline order-summary__row big-semi-bold">
          {{ $t('account.order.order_summary.headline') }}
        </div>
        <div class="order-summary__row">
          {{ $t('account.order.order_summary.type') }}: {{ order.payment }}
        </div>
        <div class="order-summary__row">
          <span class="order-summary__key">{{ $t('account.order.order_summary.subtotal') }}</span>
          <span class="order-summary__value">{{ order.subTotal.displayValue }}</span>
        </div>
        <div class="order-summary__row">
          <span class="order-summary__key">{{ $t('account.order.order_summary.delivery') }}</span>
          <span class="order-summary__value">{{ order.shippingCost.displayValue }}</span>
        </div>
        <div class="order-summary__row">
          <span class="order-summary__key default-semi-bold">{{ $t('account.order.order_summary.total') }}</span>
          <span class="order-summary__value default-semi-bold">{{ order.grandTotal.displayValue }}</span>
        </div>
      </div>
      <hr v-if="i < orderList.length - 1">
    </div>
  </div>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin'
import SingleOrder from '../../services/accountOrders/accountOrders.js'
import DateFormatMixin from '../../utilities/vueMixins/dateFormatMixin/dateFormatMixin'
import AddressWrapper from '../addressWrapper/addressWrapper.vue'
import AccountSingleOrderCart from './accountSingleOrderCart.vue'
import accountOrderMixin from '../../utilities/vueMixins/account/accountOrderMixin'

export default {
  components: {
    AccountSingleOrderCart,
    AddressWrapper
  },
  mixins: [
    globalStateComputedMixin,
    DateFormatMixin,
    accountOrderMixin
  ],
  props: {
    orderNumber: String,
  },
  created() {
    if (this.orderList.length === 0) {
      SingleOrder()
        .getOrderDetails(this.orderNumber)
        .then(this.handleOrderDetails.bind(this))
    }
  },
  emits: ['goBack', 'getDokumentPdf'],
  data() {
    return {
      orderList: [],
    }
  },
  methods: {
    goBackHandler() {
      this.$emit('goBack')
    },
    handleOrderDetails(details) {
      this.orderList = details
    }
  }
}
</script>
