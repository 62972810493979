const productPageStateComputedMixin = {
  computed: {
    productDetails() {
      return this.$store.state.productPage.productData.productDetails;
    },
    availableParts() {
      return this.$store.state.productPage.productData.availableParts;
    },
    availablePartDummy() {
      return this.$store.state.productPage.productData.availablePartDummy;
    },
    availablePartsSize() {
      return this.$store.state.productPage.productData.availablePartsSize;
    },
    pagingData() {
      return this.$store.state.productPage.productData.pagingData;
    },
    sortingData() {
      return this.$store.state.productPage.productData.sortingData;
    },
    productBrandImage() {
      return this.$store.state.productPage.productData.productBrandImage;
    },
    productUpdatePathUrl() {
      return this.$store.state.productPage.productData.productUpdatePathUrl;
    },
    addToBasketUrl() {
      return this.$store.state.productPage.productData.addToBasketUrl;
    },
    productReminderUrl() {
      return this.$store.state.productPage.productData.productReminderUrl;
    },
    possibleAlternatives() {
      return this.$store.state.productPage.productData.possibleAlternatives;
    },
    qualityKeys() {
      return this.$store.state.productPage.productData.qualityKeys;
    },
    productFlags() {
      return this.$store.state.productPage.productData.productFlags;
    },
    longDescription() {
      return this.$store.state.productPage.productData.longDescription;
    },
    captchaImg() {
      return this.$store.state.productPage.productData.captchaImg;
    },
    // brandSeoText() {
    //   return this.$store.state.productPage.productData.brandSeoText;
    // },
    adsHashForwardPath() {
      return this.$store.state.productPage.productData.adsHashForwardPath;
    },
    isOnWishlist() {
      return this.$store.state.productPage.productData.productDetails.product.onWishlist;
    },
    sellingPoints() {
      return this.$store.state.productPage.productData.sellingPoints;
    },
    currentReservationId() {
      return this.$store.state.productPage.productData.currentReservationId;
    },
    inSelectedCarModel() {
      return this.$store.state.productPage.productData.inSelectedCarModel;
    },
    reservationDurations() {
      return this.$store.state.productPage.productData.reservationDurations;
    }
  }
}

export default productPageStateComputedMixin;
