<template>
  <component
    :is="link ? 'a' : 'div'"
    class="image-teaser"
    :class="[
      subHeadline || headline ? 'image-teaser--with-text' : '',
      zoom ? `image-teaser--zoom_${zoom}` : ''
    ]"
    :href="link.url"
    :target="link.openInNewWindow ? '_blank' : ''"
  >
    <div
      v-if="link.overlay"
      :class="link.overlay ? `image-teaser__overlay image-teaser__overlay--${link.overlay}` : ''"
    >
      <div
        class="image-teaser__image"
        :style="`background-image: url('${image.src}')`"
        role="img"
        :aria-label="image.title"
      />
      <div
        v-if="headline"
        class="image-teaser__headline"
        :class="{'scalable-element' : isScalable}"
        data-scale-origin="top left"
        data-scale-on-breakpoints="tablet"
        v-html="headline"
      />
      <div
        v-if="subHeadline"
        class="image-teaser__subheadline button"
        :class="[
          link.overlay ? `button--${link.overlay}` : '',
          isScalable ? 'scalable-element' : '']"
        data-scale-origin="bottom left"
        data-scale-on-breakpoints="tablet"
        v-html="subHeadline"
      />
    </div>
  </component>
</template>

<script>
export default {
  props: {
    link: Object,
    image: Object,
    headline: String,
    subHeadline: String,
    isScalable: Boolean
  }
}
</script>
