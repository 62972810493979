import scrollToElement from "../../utilities/js/scrollToElement/scrollToElement";

const mutations = {
  showBurgerMenu(state) {
    state.isBurgerMenuOpen = true;
  },
  hideBurgerMenu(state) {
    state.isBurgerMenuOpen = false;
  },
  showOverlay(state) {
    state.isOverlayVisible = true;
  },
  hideOverlay(state) {
    state.isOverlayVisible = false;
  },
  updateCartCount(state, updatedCartData) {
    state.basketCount = updatedCartData.cart.size;
  },
  updateWishlistCount(state, updatedWishlist) {
    state.wishlistCount = updatedWishlist.wishlist.length;
    if (state.productPage.productData.productDetails) {
      state.productPage.productData.productDetails.product.onWishlist = updatedWishlist.isAddedToWishlist
    }
    if (state.productsListPage && state.productsListPage.elementsList) {
      state.productsListPage.elementsList.map(product => {
        if (updatedWishlist.wishlist.length && product.data.itemId === updatedWishlist.wishlist[0].itemId) {
          product.data.onWishlist = updatedWishlist.isAddedToWishlist
        }
      })
    }
  },
  updateOnWishlistState(state, updatedWishlist) {
    state.wishlist = updatedWishlist.wishlist;
  },
  editProduct(state, updatedData) {
    state.editedProduct = updatedData;
  },
  showModal(state, data) {
    state.currentModalId = data.id;
    state.isOverlayVisible = true;
    state.isModalOpened = true;
  },
  hideModal(state) {
    state.currentModalId = '';
    state.isOverlayVisible = false;
    state.isModalOpened = false;
  },
  scrollTo(state, data) {
    const offset = data.offset ? data.offset : 0;
    scrollToElement(document.getElementById(data.id), offset);
  },
  updateCart(state, newCart) {
    if (newCart) {
      state.cartPage = newCart;
      state.cart = newCart;
      state.basketCount = newCart.size;
    }
  },
  updateWishlist(state, newWishlist) {
    if (newWishlist) {
      state.wishlistPage.wishlistItems = newWishlist;
    }
  },
  editArticleInFlyout(state, data) {
    const cart = state.cart.positions;
    const wishlist = state.wishlist;
    const addedItem = data.itemId;

    switch (data.type) {
      case 'delete': {
        /*remove item from cart flyout*/
        let deletedItemIndex = cart.findIndex(deletedItem => deletedItem.itemId === addedItem);
        cart.splice(deletedItemIndex, 1);
        break;
      }
      case 'addToCartFromWishlist': {
        /*add item to cart flyout from wishlist*/
        let existsInCart = cart.find(x => x.itemId === addedItem);
        state.miniFlyout.itemAdded = 'cart';
        state.miniFlyout.show = 'cart';

        if (existsInCart) {
          /*item already is in cart, find it and add the selected quantity, insert at first position*/
          let itemIndex = cart.findIndex(cart => cart.itemId === addedItem);
          cart[itemIndex].quantity += 1;
        } else {
          /*not in cart yet, insert item in flyout*/
          let addItemToCart = wishlist.find(foundItem => foundItem.itemId === addedItem);
          cart.unshift(addItemToCart);
        }

        setTimeout(() => {
          state.miniFlyout.itemAdded = null;
        }, 3000);

        break;
      }
      case 'addToWishlistFromFlyout': {
        /*add product to wishlist from cart flyout*/
        let deletedItemIndex = cart.findIndex(deletedItem => deletedItem.itemId === addedItem);
        let addedItemToWishlist = cart.find(item => item.itemId === addedItem);

        state.miniFlyout.show = 'wishlist';
        state.miniFlyout.itemAdded = 'wishlist';
        cart.splice(deletedItemIndex, 1);
        if (state.wishlistPage.wishlistItems) {
          state.wishlistPage.wishlistItems.unshift(addedItemToWishlist)
        }

        setTimeout(() => {
          state.miniFlyout.itemAdded = null;
        }, 3000);

        break;
      }
    }
  },
  reduceCount(state, data) {
    if (data.count === 'cart') {
      state.basketCount -= 1;
    } else if (data.count === 'wishlist') {
      state.wishlistCount -= 1;
    }
  },
  addCount(state, data) {
    if (data.count === 'cart') {
      state.basketCount += 1;
    }
  },
  updateCarts(state, newCarts) {
    if (newCarts) {
      state.carts = newCarts;
    }
  },
  toggleShowVat(state) {
    state.user.showVat = !state.user.showVat;
  },
  initPhotoSwipe(state, imagesData) {
    state.galleryIndexes[imagesData.galleryId] = imagesData.showImage;
  },
  indexUpdate(state, imagesData) {
    state.galleryIndexes[imagesData.galleryId] = imagesData.showImage;
  }
};

export default mutations;
