<template>
  <div>
    <div
      v-if="selectedCarModel"
      class="select-car-model__filter | tag tag--button small-regular"
      @click="removeFilter"
    >
      {{ selectedCarModel.displayName }}
      <span
        class="tag__button tag__button--icon_close-small"
      />
    </div>

    <div
      class="select-car-model__search"
      :class="{
        'select-car-model__search--no-filter': !selectedCarModel
      }"
    >
      <input
        class="select-car-model__search--input default-regular"
        v-model="searchQuery"
        :placeholder="$t('global.select.car.search.placeholder')"
      >
    </div>
    <div
      class="select-car-model__modal default-bold"
      v-for="carModelGroup in searchCarModelGroups"
      :key="carModelGroup.id"
    >
      <span class="headline headline--type_h3">
        {{ carModelGroup.name }}
      </span>
      <a
        class="default-regular"
        v-for="carModel in carModelGroup.carModels"
        :key="carModel.id"
        @click="carModelSelected(carModel)"
      >
        <div
          :title="isPartAvailableInModel(carModel.partsAvailable)"
          class="select-car-model__select"
          :class="{
            'select-car-model__select--disable': !carModel.partsAvailable
          }"
        >
          <p class="select-car-model__select--text">
            {{ carModel.name }}
          </p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import backendRequestService from '../../services/backendRequest/backendRequest.js';

export default {
  mixins: [
    globalStateComputedMixin
  ],
  components: {},
  emits: ['car-model-selected'],
  data() {
    return {
      carModel: null,
      searchQuery: '',
    };
  },
  computed: {
    searchCarModelGroups() {
      if (this.searchQuery === '') {
        return this.carModelGroups;
      } else {
        let carModelGroupsFiltered = JSON.parse(JSON.stringify(this.carModelGroups));
        return carModelGroupsFiltered.filter(carModelGroupFiltered => {
            let filtered = carModelGroupFiltered.carModels.filter(car => car.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
            carModelGroupFiltered.carModels = filtered
            return filtered.length > 0;
          }
        );
      }
    }
  },
  methods: {
    carModelSelected(carModel) {
      if (carModel.partsAvailable) {
        this.carModel = carModel;
        this.$emit('car-model-selected', this.carModel)
      }
    },
    isPartAvailableInModel(available) {
      return available ? '' : this.$t('global.select.car.parts.not.available');
    },
    removeFilter() {
      if (this.selectedCarModel) {
        backendRequestService().deleteBackendRequest('/car-model/remove/', {}).then(() => {
          location.reload();
        });
      }
    }
  }
};
</script>
