import cartPage from "../../../services/cart/cart";

const actions = {
  updateCart(store, data) {
    store.commit('updateCart', data.cart, {root: true});
    store.commit('updateCartCount', data, {root: true});
    store.commit('updateCarts', data.carts, {root: true});
  },
  removeSingleProduct(store, data) {
    cartPage()
        .removeSingleProduct(data)
        .then((data) => store.dispatch('updateCart', data));
  }
};

export default actions;
