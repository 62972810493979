const plpStateComputedMixin = {
  computed: {
    productsList() {
      return this.$store.state.productsListPage.elementsList;
    },
    productUpdateLink() {
      return this.$store.state.productsListPage.listProductUpdatePathUrl;
    },
    sorting() {
      return this.$store.state.productsListPage.sorting;
    },
    navigation() {
      return this.$store.state.productsListPage.navigation;
    },
    pagination() {
      return this.$store.state.productsListPage.pagination;
    },
    filters() {
      return this.$store.state.productsListPage.filters;
    },
    activeFilters() {
      return this.$store.getters['productsListPage/activeFilters'];
    }
  }
};

export default plpStateComputedMixin;
