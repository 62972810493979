<template>
  <label
    class="checkbox"
    :for="checkboxID"
    :class="{'inverse': inverse}"
    @keyup.space="selectBoxOnClick"
    @keyup.enter="selectBoxOnClick"
  >
    <span class="checkbox__wrapper">
      <input
        :id="checkboxID"
        type="checkbox"
        class="checkbox__input"
        :checked="checked"
        :disabled="disabled"
        :value="modelValue"
        @change="updateValue"
        ref="inputRef"
      >
      <span class="checkbox__box" />
      <span
        class="checkbox__label"
        :class="{
          'checkbox__label--disabled': disabled
        }"
        v-html="label"
      />
      <span
        class="input__error"
        v-if="!!error"
        v-html="error"
      />
    </span>
  </label>
</template>

<script>
import randomId from '../../utilities/js/randomId/randomId';

export default {
  props: {
    checked: Boolean,
    name: {
      type: String,
      default() {
        return '';
      }
    },
    modelValue: {
      type: Boolean,
      required: false,
      default: false
    },
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: null
    },
    inverse: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      checkboxID: this.id ? this.id : randomId(this.checked),
    }
  },
  methods: {
    selectBoxOnClick() {
      this.$refs.inputRef.click();
    },
    updateValue(e) {
      this.$emit('update:modelValue', e.target.checked);
    }
  },
};
</script>
