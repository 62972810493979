<template>
  <a
    class="service-teaser"
    :class="[
      icon ? `service-teaser--icon_${icon}` : ''
    ]"
    :href="link.url"
    :target="link.openInNewWindow ? '_blank' : '_self'"
  >
    <div
      class="service-teaser__content"
      :class="[
        theme ? `service-teaser--theme_${theme}` : ''
      ]"
      v-html="content"
    />
  </a>
</template>

<script>
export default {
  props: {
    link: Object,
    content: Object,
    icon: String,
    theme: String,
  }
}
</script>
