import encodeFormData from "../../js/encodeFormData/encodeFormData";
import serializeForm from "../../js/formSerializer/formSerializer";
import pigeon from '../../../utilities/js/pigeon/pigeon.js';
import scrollToElement from '../../js/scrollToElement/scrollToElement';

const FormMixin = {
  props: {
    successUrl: {
      type: String,
      default: null
    },
    action: {
      type: String,
      default: null,
    },
    method: {
      type: String,
      default: 'POST'
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      innerSuccessUrl: this.successUrl,
      innerAction: this.action
    }
  },
  methods: {
    // submits the serialized vue form model with ajax
    submitAjaxForm() {
      const vm = this;
      let data = vm.$data.model;
      data[vm.$refs.csrf.getName()] = vm.$refs.csrf.getValue();
      submit(vm, encodeFormData(data));
    },

    // submits the serialized form with ajax
    submitFormAjax(form) {
      submit(this, serializeForm(form));
    },

    submitForm(form) {
      disableSubmitButton(form);
      form.submit();
    }
  }
};

function disableSubmitButton(form) {
  toggleSubmitButton(form, true);
}

function enableSubmitButton(form) {
  toggleSubmitButton(form);
}

function toggleSubmitButton(form, disabled = false) {
  const button = form ? form.querySelector('button[type = "submit"]') : undefined;
  if (button) {
    button.disabled = disabled;
  }
}

function getFirstInvalidInput(vm) {
  if (vm.errors && vm.errors.items && vm.errors.items.length) {
    for (let i = 0; i < vm.errors.items.length; ++i) {
      const element = vm.$el.querySelector(`[name="${vm.errors.items[i].field}"]`);

      if (element.getAttribute('type') !== 'hidden') {
        return element;
      }
    }
  }

  return null;
}

function scrollToFirstError(vm) {
  if (vm.id) {
    pigeon.publish('formsubmit:error', vm.id);
  }

  const firstInvalidInput = getFirstInvalidInput(vm);

  if (firstInvalidInput) {
    scrollToElement(firstInvalidInput, 20);
  }
}

// ToDo
// invalid submit
// Vee-Validate Form @invalid-submit="onInvalidSubmit"
// scrollToFirstError(vm);

// This a valid submit
function submit(vm, body) {
  let requestHeaders = {};
  requestHeaders['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
  requestHeaders['X-Requested-With'] = 'XMLHttpRequest';
  requestHeaders[window.__globalConfig.csrfToken.header] = window.__globalConfig.csrfToken.value;
  vm.errors.clear();
  const form = vm.$el;
  disableSubmitButton(form);
  fetch(vm.innerAction, {
    method: vm.method,
    credentials: 'same-origin',
    headers: requestHeaders,
    body: body
  })
      .then(res => res.json())
      .then(response => {
        if (response.fieldErrors && response.fieldErrors.length > 0) {
          response.fieldErrors.forEach(fieldError => {
            vm.$validator.flag(fieldError.field, {invalid: true});
            vm.errors.add(fieldError);
          });
          vm.$nextTick(() => {
            scrollToFirstError(vm);
          });
          enableSubmitButton(form);
        } else if (response.redirectOnRestriction) {
          window.location.href = response.redirectOnRestriction;
        } else {
          if (vm.innerSuccessUrl) {
            window.location.href = vm.innerSuccessUrl;
          }
          if (vm.id) {
            pigeon.publish('formsubmit:success', vm.id);
          }
        }
      })
      .catch(reason => {
        pigeon.publish('GlobalAppError:show', {error: `Server repsonded with status ` + reason})});
}

export default FormMixin;
