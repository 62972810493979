<template>
  <div
    class="cookie-disclaimer"
    v-if="cookies"
  >
    <div class="cookie-disclaimer__text">
      <h3 class="cookie-disclaimer__text--header h3">
        {{ $t('cookie.disclaimer.text.header') }}
      </h3>
      <p
        class="default-regular"
        v-html="$t('cookie.disclaimer.text')"
      />
    </div>
    <div class="cookie-disclaimer__types">
      <checkbox
        id="cookie-disclaimer__types--other"
        v-model="cookies.other"
        name="other"
        :label="$t('cookie.disclaimer.type.other')"
        :inverse="true"
      />
      <checkbox
        id="cookie-disclaimer__types--tracking"
        v-model="cookies.tracking"
        name="tracking"
        :label="$t('cookie.disclaimer.type.tracking')"
        :inverse="true"
      />
      <checkbox
        id="cookie-disclaimer__types--marketing"
        v-model="cookies.marketing"
        name="marketing"
        :label="$t('cookie.disclaimer.type.marketing')"
        :inverse="true"
      />
      <checkbox
        id="cookie-disclaimer__types--essential"
        v-model="cookies.essential"
        name="essential"
        :label="$t('cookie.disclaimer.type.essential')"
        :disabled="true"
        :inverse="true"
      />
    </div>
    <div class="cookie-disclaimer__buttons">
      <button
        class="button button--filled-white button--icon button--icon--front button--icon_checked"
        v-html="$t('cookie.disclaimer.accept')"
        @click="acceptAll"
      />
      <button
        class="button button--outlined-white"
        v-html="$t('cookie.disclaimer.save')"
        @click="saveSelection"
      />
    </div>
  </div>
</template>

<script>
import Checkbox from '../checkbox/checkbox.vue';
import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';
import cookieMonster from '../../utilities/js/cookieMonster/cookieMonster.js';

export default {
    props: {
      cookieName: {
        type: String,
        default: 'COOKIECONSENT'
      },
      expireInDays: {
        type: Number,
        default: 365
      }
    },
    components: {
      Checkbox
    },
    mixins: [
      translationsMixin
    ],
    data() {
      return {
        cookies: null
      }
    },
    created() {
      this.setInitialValue();
    },
    methods: {
      setInitialValue() {
        if (!cookieMonster.get(this.cookieName)) {
          this.cookies = {
            other: false,
            tracking: false,
            marketing: false,
            essential: true
          };
        }
      },
      acceptAll() {
        this.cookies.other = true;
        this.cookies.tracking = true;
        this.cookies.marketing = true;
        this.saveSelection();
      },
      saveSelection() {
        let expireDate = (new Date()).getTime() + 86400000 * this.expireInDays;
        cookieMonster.set(this.cookieName, btoa(JSON.stringify(this.cookies)), new Date(expireDate));
        this.cookies = null;
      }
    }
  };
</script>
