<template>
  <div class="error-page__content">
    <h1 class="error-page__headline h2">
      {{ $t('global.general_error.headline') }}
    </h1>
    <p
      class="error-page__text default-regular"
      v-html="$t('global.general_error.description')"
    />
    <div class="error-page__button-group">
      <a
        class="button button--filled-default error-page__button"
        href="/"
        v-t="'global.error.backToStartpage'"
      />
    </div>
  </div>
</template>
