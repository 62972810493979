<template>
  <div class="product-navigation | navigation">
    <ul class="navigation__list navigation__list--width_auto">
      <li
        v-for="(navigationItem, index) in updatedNavigationItems"
        :key="index"
        class="navigation__element"
      >
        <a
          class="navigation__link"
          :href="`#${navigationItem.link}`"
          ref="navigationItem"
          @click.prevent="linkClickHandler(navigationItem.link)"
        >
          {{ navigationItem.name }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    navigationItems: Array
  },
  emits: ['click'],
  data() {
    return {
      updatedNavigationItems: this.navigationItems
    }
  },
  methods: {
    linkClickHandler(id) {
      this.$emit('click', id);
    }
  }
}
</script>
