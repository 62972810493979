<template>
  <div
    v-if="isMobile"
    class="breadcrumbs breadcrumbs__mobile"
  >
    <a
      class="breadcrumbs__link"
      :href="linkBackToPreviousElement"
      v-html="textBackToPreviousElement"
    />
  </div>
  <ul
    v-else
    id="MAIN_NAVIGATION_BREADCRUMB"
    class="breadcrumbs"
    vocab="http://schema.org/"
    typeof="BreadcrumbList"
  >
    <li
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
      class="breadcrumbs__item"
      property="itemListElement"
      typeof="ListItem"
    >
      <template
        v-if="breadcrumbs.length-1 !== index"
      >
        <a
          class="breadcrumbs__link small-regular"
          :href="breadcrumb.link"
          property="item"
          typeof="WebPage"
          v-html="breadcrumb.displayName"
        />
      </template>
      <template
        v-else
      >
        <span
          class="breadcrumbs__link breadcrumbs__link--current small-regular"
          property="name"
          v-html="breadcrumb.displayName"
        />
      </template>
      <meta
        property="position"
        :content="index+1"
      >
    </li>
  </ul>
</template>
<script>
import { breakpoints } from "../../variables/variables";

export default {
  props: {
    breadcrumbs: Array
  },
  data() {
    return {
      isMobile: window.innerWidth < breakpoints.tablet
    }
  },
  mounted() {
    window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
  },
  methods: {
    breakpointChangeHandler() {
      this.isMobile = window.innerWidth < breakpoints.laptop;
    },
  },
  computed: {
    linkBackToPreviousElement() {
      return this.breadcrumbs[this.breadcrumbs.length - 2].link
    },
    textBackToPreviousElement() {
      return this.breadcrumbs[this.breadcrumbs.length - 2].displayName
    }
  }
};
</script>
