// JS COMPONENTS ---------------------------------------------------------------
import NavAllCategories from './navAllCategories/navAllCategories.js';
import SidebarMenu from './sidebarMenu/sidebarMenu.js';
import GoTop from './goTop/goTop.js';
import ScrollTo from './scrollTo/scrollTo.js';
import ScalableElement from './scalableElement/scalableElement.js';
import Address from './address/address.js';
// VUE COMPONENTES -------------------------------------------------------------
import PageOverlay from './pageOverlay/pageOverlay.vue';
import HeaderVue from './header/header.vue';
import HeaderMetaContent from './headerMetaContent/headerMetaContent.vue';
import UserShortcut from './userShortcut/userShortcut.vue';
import UserShortcutHeader from './userShortcut/userShortcutHeader.vue';
import MiniWishlist from './miniWishlist/miniWishlist.vue';
import Input from './input/input.vue';
import TextArea from './textarea/textarea.vue';
import RadioInput from './radioInput/radioInput.vue';
import RadioInputsList from './radioInputsList/radioInputsList.vue';
import Checkbox from './checkbox/checkbox.vue';
import DateInput from './dateInput/dateInput.vue';
import DatePicker from './datePicker/datePicker.vue';
import CookieBox from './cookieBox/cookieBox.vue'
import FormStructure from './formStructure/formStructure.vue';
import BurgerMenu from './burgerMenu/burgerMenu.vue';
import BurgerNavTree from './burgerNavTree/burgerNavTree.vue';
import Loader from './loader/loader.vue';
import LanguageSwitcher from './languageSwitcher/languageSwitcher.vue';
import Select from './select/select.vue';
import SelectVeeV from './select/selectVeeV.vue'
import Captcha from './captcha/captcha.vue';
import SearchAutosuggest from './searchAutosuggest/searchAutosuggest.vue';
import SearchAutosuggestBox from './searchAutosuggestBox/searchAutosuggestBox.vue';
import Filter from './filter/filter.vue';
import FilterPrice from './filterPrice/filterPrice.vue';
import RangeSlider from './rangeSlider/rangeSlider.vue';
import ProductBox from './productBox/productBox.vue';
import CartSelect from './cartSelect/cartSelect.vue';
import ProductsList from './productsList/productsList.vue';
import ProductsSort from './productsSort/productsSort.vue';
import Pagination from './pagination/pagination.vue';
import ActiveFilters from './activeFilters/activeFilters.vue';
import LazyLoadImage from './lazyLoadImage/lazyLoadImage.vue';
import FilterCategory from './filterCategory/filterCategory.vue';
import Modal from './modal/modal.vue';
import GlobalAppError from './globalAppError/globalAppError.vue';
import Counter from './counter/counter.vue';
import CsrfInput from './csrfInput/csrfInput.vue';
import ProductGallery from './productGallery/productGallery.vue';
import Minicart from './minicart/minicart.vue';
import ProductVariations from './productVariations/productVariations.vue';
import HeroSlider from './heroSlider/heroSlider.vue';
import Carousel from './carousel/carousel.vue';
import VueComponentCloner from './vueComponentCloner/vueComponentCloner.vue';
import ProductFeatures from './productFeatures/productFeatures.vue';
import GeneralMessage from './generalMessage/generalMessage.vue';
import PhotoSwipeGallery from './photoSwipeGallery/photoSwipeGallery.vue';
import HeadlineTeaser from './headlineTeaser/headlineTeaser.vue';
import RecommendationsTeaser from './recommendationsTeaser/recommendationsTeaser.vue';
import ImageTeaser from './imageTeaser/imageTeaser.vue';
import HalfSizeTeaser from './halfSizeTeaser/halfSizeTeaser.vue';
import ServiceTeaser from './serviceTeaser/serviceTeaser.vue';
import ServicesListTeaser from './servicesListTeaser/servicesListTeaser.vue';
import ImageTeasersSet from './imageTeasersSet/imageTeasersSet.vue';
import SimpleImageTeaser from './simpleImageTeaser/simpleImageTeaser.vue';
import BoxInsertionTeaser from './boxInsertionTeaser/boxInsertionTeaser.vue';
import HtmlTeaser from './htmlTeaser/htmlTeaser.vue';
import BrandTile from './brandTile/brandTile.vue';
import BrandsTilesTeaser from './brandsTilesTeaser/brandsTilesTeaser.vue';
import BrandsTeaser from './brandsTeaser/brandsTeaser.vue';
import Accordion from './accordion/accordion.vue';
import AccordionsList from './accordionsList/accordionsList.vue';
import FooterTeaser from './footerTeaser/footerTeaser.vue';
import ProductDetailedPage from './productDetailedPage/productDetailedPage.vue';
import ProductDescription from './productDescription/productDescription.vue';
import ProductInfo from './productInfo/productInfo.vue';
import AvailableParts from './availableParts/availableParts.vue';
import ProductAdditionalInformation from './productAdditionalInformation/productAdditionalInformation.vue';
import ProductFlags from './productFlags/productFlags.vue';
import ProductPagePagination from './productPagePagination/productPagePagination.vue';
import ProductNavigation from './productNavigation/productNavigation.vue';
import ProductPageBrand from './productPageBrand/productPageBrand.vue';
import Navigation from './navigation/navigation.vue';
import NavigationFlyout from './navigationFlyout/navigationFlyout.vue';
import NavigationSlider from './navigationSlider/navigationSlider.vue';
import CheckoutProcessBreadcrumbs from './checkoutProcessBreadcrumbs/checkoutProcessBreadcrumbs.vue';
import Breadcrumbs from './breadcrumbs/breadcrumbs.vue';
import Footer from './footer/footer.vue';
import ImageAssetList from './imageAssetList/imageAssetList.vue';
import SocialMedia from './socialMedia/socialMedia.vue';
import ServiceLinks from './serviceLinks/serviceLinks.vue';
import LoginPage from "./loginPage/loginPage.vue";
import AddressWrapper from "./addressWrapper/addressWrapper.vue";
import Filters from './filters/filters.vue';
import ProductsListPage from './productsListPage/productsListPage.vue';
import CartPage from './cartPage/cartPage.vue';
import ProductOrderPosition from './productOrderPosition/productOrderPosition.vue';
import PromoItemsList from './promoItemsList/promoItemsList.vue';
import PromoItem from './promoItem/promoItem.vue';
import OrderSummary from './orderSummary/orderSummary.vue';
import WishlistPage from './wishlistPage/wishlistPage.vue';
import CookieDisclaimer from "./cookieDisclaimer/cookieDisclaimer";
import TemplateCompiler from './templateCompiler/templateCompiler.vue';
import Security from "./security/security";
import AudiAssets from "./audiAssets/audiAssets.vue";
import SelectCarModel from "./selectCarModel/selectCarModel.vue";
import SelectCarModelFlyout from "./selectCarModelFlyout/selectCarModelFlyout.vue";
import SelectCarModelMotorizations from "./selectCarModelMotorizations/selectCarModelMotorizations.vue";
import ErrorPage from "./errorPage/errorPage";
import ErrorPage404 from "./errorPage/errorPage404";
import OnePageCheckout from "./onePageCheckout/onePageCheckout.vue";
import AccountPage from "./accountPage/accountPage.vue"
import AccountSidebar from "./accountSidebar/accountSidebar.vue"
import AccountOrdersList from "./accountOrdersList/accountOrdersList.vue";
import AccountOrders from "./accountOrders/accountOrders.vue";
import AccountSingleOrder from "./accountSingleOrder/accountSingleOrder.vue";
import CarCompatibility from "./carCompatibility/carCompatibility.vue";
import AvailablePartDamageModel from "./availableParts/availablePartDamageModel.vue";
import AccountPersonalData from "./accountPersonalData/accountPersonalData.vue";
import AccountPersonalDataAddress from "./accountPersonalDataForm/accountPersonalDataAddress.vue";
import AccountMenu from "./accountPage/accountMenu.vue";
import AccountPersonalDataInfo from "./accountPersonalDataForm/accountPersonalDataInfo.vue";
import CategoryImageTeaser from "./categoryImageTeaser/categoryImageTeaser.vue";
import CategoryImage from "./categoryImageTeaser/categoryImage.vue";
import AddressForm from "./addressForm/addressForm.vue";
import AccountAddressForm from "./accountAddressForm/accountAddressForm.vue";
import InputVeeV from "./input/inputVeeV.vue";
import CheckboxVeeV from "./checkbox/checkboxVeeV.vue";
// autoImportVuePlaceholder

const JsComponents = [
  {name: 'nav-all-categories', constructor: NavAllCategories},
  {name: 'sidebar-menu', constructor: SidebarMenu},
  {name: 'go-top', constructor: GoTop},
  {name: 'scroll-to', constructor: ScrollTo},
  {name: 'scalable-element', constructor: ScalableElement},
  {name: 'address', constructor: Address},
  // autoRegisterJsPlaceholder
];

const VueComponents = [
  {name: 'audi-assets', constructor: AudiAssets},
  {name: 'page-overlay', constructor: PageOverlay},
  {name: 'header-vue', constructor: HeaderVue, global: true},
  {name: 'header-meta-content', constructor: HeaderMetaContent, global: true},
  {name: 'user-shortcut', constructor: UserShortcut},
  {name: 'user-shortcut-header', constructor: UserShortcutHeader},
  {name: 'mini-wishlist', constructor: MiniWishlist},
  {name: 'input-vue', constructor: Input},
  {name: 'input-vee-v', constructor: InputVeeV},
  {name: 'text-area', constructor: TextArea},
  {name: 'radio-input', constructor: RadioInput},
  {name: 'radio-inputs-list', constructor: RadioInputsList},
  {name: 'checkbox', constructor: Checkbox},
  {name: 'checkbox-vee-v', constructor: CheckboxVeeV},
  {name: 'date-input', constructor: DateInput},
  {name: 'date-picker', constructor: DatePicker},
  {name: 'cookie-box', constructor: CookieBox},
  {name: 'loader', constructor: Loader},
  {name: 'form-structure', constructor: FormStructure},
  {name: 'burger-menu', constructor: BurgerMenu},
  {name: 'burger-nav-tree', constructor: BurgerNavTree},
  {name: 'image-magnify', constructor: () => import(/*webpackChunkName: 'productDetails'*/ './imageMagnify/imageMagnify.vue')},
  {name: 'language-switcher', constructor: LanguageSwitcher},
  {name: 'select-vue', constructor: Select},
  {name: 'select-vee-v', constructor: SelectVeeV},
  {name: 'captcha', constructor: Captcha},
  {name: 'modal', constructor: Modal},
  {name: 'address-wrapper', constructor: AddressWrapper},
  {name: 'search-autosuggest', constructor: SearchAutosuggest},
  {name: 'search-autosuggest-box', constructor: SearchAutosuggestBox},
  {name: 'filter-vue', constructor: Filter},
  {name: 'filter-price', constructor: FilterPrice},
  {name: 'range-slider', constructor: RangeSlider, global: true},
  {name: 'product-box', constructor: ProductBox},
  {name: 'cart-select', constructor: CartSelect},
  {name: 'account-page', constructor: AccountPage},
  {name: 'account-sidebar', constructor: AccountSidebar},
  {name: 'account-orders', constructor: AccountOrders},
  {name: 'account-orders-list', constructor: AccountOrdersList},
  {name: 'account-single-order', constructor: AccountSingleOrder},
  {name: 'products-list', constructor: ProductsList},
  {name: 'products-sort', constructor: ProductsSort},
  {name: 'pagination', constructor: Pagination},
  {name: 'active-filters', constructor: ActiveFilters},
  {name: 'lazy-load-image', constructor: LazyLoadImage},
  {name: 'filter-category',constructor: FilterCategory},
  {name: 'global-app-error', constructor: GlobalAppError},
  {name: 'counter', constructor: Counter},
  {name: 'csrf-input', constructor: CsrfInput},
  {name: 'product-gallery', constructor: ProductGallery},
  {name: 'minicart', constructor: Minicart},
  {
    name: 'product-variations',
    constructor: ProductVariations
  },
  {name: 'hero-slider', constructor: HeroSlider, global: true},
  {name: 'carousel', constructor: Carousel},
  {name: 'vue-component-cloner', constructor: VueComponentCloner},
  {
    name: 'product-features',
    constructor: ProductFeatures
  },
  {name: 'general-message', constructor: GeneralMessage},
  {
    name: 'photo-swipe-gallery',
    constructor: PhotoSwipeGallery
  },
  {name: 'headline-teaser', constructor: HeadlineTeaser, global: true},
  {name: 'recommendations-teaser', constructor: RecommendationsTeaser, global: true},
  {name: 'image-teaser', constructor: ImageTeaser},
  {
    name: 'product-description',
    constructor: ProductDescription
  },
  {name: 'product-info', constructor: ProductInfo},
  {
    name: 'available-parts',
    constructor: AvailableParts
  },
  {
    name: 'product-additional-information',
    constructor: ProductAdditionalInformation
  },
  {name: 'half-size-teaser', constructor: HalfSizeTeaser, global: true},
  {name: 'service-teaser', constructor: ServiceTeaser},
  {name: 'services-list-teaser', constructor: ServicesListTeaser, global: true},
  {name: 'image-teasers-set', constructor: ImageTeasersSet},
  {name: 'simple-image-teaser', constructor: SimpleImageTeaser, global: true},
  {name: 'box-insertion-teaser', constructor: BoxInsertionTeaser, global: true},
  {name: 'html-teaser', constructor: HtmlTeaser, global: true},
  {name: 'brand-tile', constructor: BrandTile},
  {name: 'brands-tiles-teaser', constructor: BrandsTilesTeaser, global: true},
  {name: 'brands-teaser', constructor: BrandsTeaser, global: true},
  {name: 'accordion', constructor: Accordion},
  {name: 'accordions-list', constructor: AccordionsList},
  {name: 'footer-teaser', constructor: FooterTeaser, global: true},
  {name: 'product-flags', constructor: ProductFlags},
  {
    name: 'product-navigation',
    constructor: ProductNavigation
  },
  {
    name: 'product-page-pagination',
    constructor: ProductPagePagination
  },
  {
    name: 'product-page-brand',
    constructor: ProductPageBrand
  },
  {name: 'navigation', constructor: Navigation},
  {name: 'navigation-flyout', constructor: NavigationFlyout},
  {name: 'navigation-slider', constructor: NavigationSlider},
  {name: 'breadcrumbs', constructor: Breadcrumbs},
  {name: 'footer-vue', constructor: Footer},
  {name: 'image-asset-list', constructor: ImageAssetList},
  {name: 'social-media', constructor: SocialMedia},
  {name: 'checkout-process-breadcrumbs', constructor: CheckoutProcessBreadcrumbs},
  {name: 'one-page-checkout', constructor: OnePageCheckout},
  {name: 'filters', constructor: Filters},
  {
    name: 'products-list-page',
    constructor: ProductsListPage
  },
  {
    name: 'product-detailed-page',
    constructor: ProductDetailedPage
  },
  {name: 'cart-page', constructor: CartPage},
  {
    name: 'product-order-position',
    constructor: ProductOrderPosition
  },
  {
    name: 'promo-items-list',
    constructor: PromoItemsList
  },
  {name: 'promo-item', constructor: PromoItem},
  {
    name: 'order-summary',
    constructor: OrderSummary
  },
  {name: 'wishlist-page', constructor: WishlistPage},
  {name: 'login-page', constructor: LoginPage},
  {name: 'address-form', constructor: AddressForm},
  {
    name: 'account-address-form',
    constructor: AccountAddressForm
  },
  {name: 'service-links', constructor: ServiceLinks},
  {name: 'template-compiler', constructor: TemplateCompiler},
  {name: 'cookie-disclaimer', constructor: CookieDisclaimer},
  {name: 'security', constructor: Security},
  {name: 'error-page', constructor: ErrorPage},
  {name: 'error-page-404', constructor: ErrorPage404},
  {name: 'select-car-model', constructor: SelectCarModel},
  {name: 'select-car-model-flyout', constructor: SelectCarModelFlyout},
  {name: 'select-car-model-motorizations', constructor: SelectCarModelMotorizations},
  {name: 'car-compatibility', constructor: CarCompatibility},
  {name: 'account-personal-data', constructor: AccountPersonalData},
  {name: 'account-personal-data-address', constructor: AccountPersonalDataAddress},
  {name: 'account-personal-data-info', constructor: AccountPersonalDataInfo},
  {name: 'account-menu', constructor: AccountMenu},
  {name: 'available-part-damage-model', constructor: AvailablePartDamageModel},
  {name: 'category-image-teaser', constructor: CategoryImageTeaser},
  {name: 'category-image', constructor: CategoryImage},
  // autoRegisterVuePlaceholder
];

export { JsComponents, VueComponents };
