<template>
  <div
    class="account-page account-orders-list | user-area"
  >
    <account-page
      :headline="$t('account.data')"
      ref="accountPersonalData"
    >
      <div v-if="billingAddresses.length > 0 || shippingAddresses.length > 0 ">
        <div class="account-orders-list__headline h2">
          {{ $t('account.data') }}
        </div>
        <account-personal-data-info :address="billingAddresses.length > 0 ? billingAddresses[0] : shippingAddresses[0]" />
      </div>
      <div v-if="billingAddresses.length > 0">
        <div class="account-orders-list__headline h2">
          {{ $t('global.addresses.billing') }}
        </div>
        <div
          v-for="(billingAddress, billingIndex) in billingAddresses"
          :key="`billing-address__${billingIndex}`"
        >
          <account-personal-data-address
            :address="billingAddress"
            :index="billingIndex"
          />
        </div>
      </div>
      <div v-if="shippingAddresses.length > 0">
        <div class="account-orders-list__headline h2">
          {{ $t('global.addresses.shipping') }}
        </div>
        <div
          v-for="(shippingAddress, shippingIndex) in shippingAddresses"
          :key="`shipping-address__${shippingIndex}`"
        >
          <account-personal-data-address
            :address="shippingAddress"
            :index="shippingIndex"
          />
        </div>
      </div>
      <div v-if="billingAddresses.length === 0 && shippingAddresses.length === 0">
        {{ $t('account.data.empty') }}
      </div>
    </account-page>
  </div>
</template>

<script>
import AccountPage from "../accountPage/accountPage.vue";
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import AccountPersonalDataAddress from "../accountPersonalDataForm/accountPersonalDataAddress.vue";
import AccountPersonalDataInfo from "../accountPersonalDataForm/accountPersonalDataInfo.vue";

export default {
  components: {
    AccountPage,
    AccountPersonalDataAddress,
    AccountPersonalDataInfo
  },
  mixins: [
    globalStateComputedMixin
  ],
  props: {
    billingAddresses: Array,
    shippingAddresses: Array
  }
}
</script>
