<template>
  <div
    v-if="productLogoName && productLogoImage"
    class="product-page-brand"
  >
    <component
      :is="productBrandLink ? 'a' : 'span'"
      class="product-page-brand__link"
      :href="productBrandLink ? productBrandLink : ''"
    >
      <img
        class="product-page-brand__logo"
        :src="productLogoImageSrc"
        :alt="productLogoName"
      >
    </component>
  </div>
</template>

<script>
export default {
  props: {
    productLogoImage: Object,
    productBrandLink: String,
    productLogoImageSrc: String,
    productLogoName: String,
  }
}
</script>
