import cartPageState from './cartPageState.js';
import cartPageGetters from './cartPageGetters.js';
import cartPageActions from './cartPageActions.js';
import cartPageMutations from './cartPageMutations.js';

const store = {
  namespaced: true,
  state: cartPageState,
  getters: cartPageGetters,
  actions: cartPageActions,
  mutations: cartPageMutations
};

export default store;
