import {round} from "lodash/math";

export const imageUrlWithParam = (url, wid) => {
  const hei = round(wid/4*3)
  const encodedWidth = btoa("wid=" + wid + "&hei=" +hei)
  return `${url}?${encodedWidth}`
}

export const imageSizeXS = 100;
export const imageSizeS = 150;
export const imageSizeM = 350;
export const imageSizeL = 650;
export const imageSizeXL = 750;
export const imageSizeDefault = 1280;

// === Mobile ===
export const imageSizeMobileS = 50;
export const imageSizeMobileM = 100;
export const imageSizeMobile = 300;
export const imageSizeMobileL = 400;
