<template>
  <div class="user-area-navigation | sidebar-menu">
    <ul class="sidebar-menu__list">
      <li
        class="sidebar-menu__item"
        :class="isActive('/account/orders/') ? 'sidebar-menu__item--active' : ''"
      >
        <a
          @click="toggleMobileView('/account/orders/')"
          class="sidebar-menu__link order-history"
        >
          {{ $t('account.orders') }}
        </a>
      </li>
      <li
        v-if="isImporter"
        class="sidebar-menu__item"
        :class="isActive('/account/importer/orders/') ? 'sidebar-menu__item--active' : ''"
      >
        <a
          @click="toggleMobileView('/account/importer/orders/')"
          class="sidebar-menu__link order-history"
        >
          {{ $t('account.order.dealerOrders') }}
        </a>
      </li>
      <li
        class="sidebar-menu__item"
        :class="isActive('/account/personal/') ? 'sidebar-menu__item--active' : ''"
      >
        <a
          @click="toggleMobileView('/account/personal-data/')"
          class="sidebar-menu__link personal-data"
        >
          {{ $t('account.data') }}
        </a>
      </li>
      <li class="sidebar-menu__item">
        <a
          href="/logout/"
          class="sidebar-menu__link logout"
        >
          {{ $t('global.logout.link') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin'

export default {
  mixins: [
    globalStateComputedMixin
  ],
  emits: ['changeLocation'],
  data() {
    return {}
  },
  methods: {
    toggleMobileView(href) {
      this.$emit('changeLocation', href)
    },
    isActive(path) {
      return window.location.href.indexOf(path) > -1
    }
  }
}
</script>
