<template>
  <div class="box-insertion-teaser">
    <component
      :is="link ? 'a' : 'div'"
      class="box-insertion-teaser__box"
      :class="[
        `box-insertion-teaser__theme--${theme}`
      ]"
      v-bind="{
        href: link ? link.url : '',
        target: link ? link.openInNewWindow ? '_blank' : '': ''
      }"
    >
      <div
        v-if="icon || image"
        class="box-insertion-teaser__image-wrapper"
      >
        <span
          v-if="icon"
          class="box-insertion-teaser__icon"
          :class="[
            `box-insertion-teaser__icon--${icon}`
          ]"
        />
        <img
          v-if="image"
          class="box-insertion-teaser__image"
          :src="image.src"
          :alt="image.alt"
          :title="image.title"
        >
      </div>
      <div class="box-insertion-teaser__content-wrapper">
        <div
          v-if="headline && headline !== ''"
          class="box-insertion-teaser__headline"
          v-html="headline"
        />
        <div
          v-if="text && text !== ''"
          class="box-insertion-teaser__text"
          v-html="text"
        />
      </div>
    </component>
  </div>
</template>

<script>
export default {
  props: {
    link: Object,
    image: Object,
    icon: String,
    headline: String,
    text: String,
    theme: String,
  }
}
</script>
