<template>
  <div
    class="vue-component-cloner"
    :style="styles"
  >
    <template v-if="component === 'product-box'">
      <product-box
        class="vue-component-cloner__clone"
        v-bind="componentConfig"
      />
    </template>
  </div>
</template>

<script>
  import ProductBox from '../productBox/productBox.vue';
  import pigeon from '../../utilities/js/pigeon/pigeon.js';

  export default {
    components: {
      ProductBox
    },
    data() {
      return {
        styles: {
          display: 'none',
          top: '0px',
          left: '0px',
          width: '0px',
          height: '0px'
        },
        component: null,
        componentConfig: null
      };
    },
    created() {
      pigeon.subscribe('vueComponentCloner:create', this.createClone.bind(this));
      pigeon.subscribe('vueComponentCloner:kill', this.killClone.bind(this));
    },
    methods: {
      createClone(originalDNA) {
        this.component = originalDNA.name;
        this.componentConfig = originalDNA.config;
        this.styles = {
          display: 'block',
          top: `${originalDNA.posY}px`,
          left: `${originalDNA.posX}px`,
          width: `${originalDNA.width}px`,
          height: `${originalDNA.height}px`,
          zIndex: originalDNA.zIndex ? originalDNA.zIndex : 1
        };
      },
      killClone() {
        this.styles = {
          display: 'none',
          top: '0px',
          left: '0px',
          width: '0px',
          height: '0px'
        };

        this.component = null;
        this.componentConfig = null;
      }
    }
  };
</script>
