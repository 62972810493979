<template>
  <div
    class="select-car-model-flyout default-regular"
    @click="showModal"
    v-if="isCarModelSelectionEnabled"
  >
    <div
      v-if="selectedCarModel"
      class="select-car-model-flyout__label"
    >
      {{ selectedCarModel.displayName }}
    </div>
    <div v-else>
      {{ $t('global.select.car') }}
    </div>

    <modal
      class="select-car-model-flyout__modal"
      :id="id"
      :left-adjusted="true"
      :headline="carModel ? $t('global.select.car.motorization.header') : $t('global.select.car')"
      @hide="hideModal"
    >
      <div v-if="carModel">
        <select-car-model-motorizations
          :car-model="carModel"
          @motorization-selected="onMotorizationSelected"
          @back-to-selection="resetSelected"
        />
      </div>
      <div v-else>
        <select-car-model
          @car-model-selected="onCarModelSelected"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import Modal from '../modal/modal.vue';
import SelectCarModel from '../selectCarModel/selectCarModel.vue';
import SelectCarModelMotorizations from '../selectCarModelMotorizations/selectCarModelMotorizations.vue';

export default {
  mixins: [
    globalStateComputedMixin,
  ],
  components: {
    Modal,
    SelectCarModel,
    SelectCarModelMotorizations
  },
  props: {
    id: String
  },
  emits: ['show-car-model-flyout'],
  data() {
    return {
      carModel: null
    };
  },
  methods: {
    showModal() {
      this.$store.dispatch('showModal', {id: this.id, action: 'open'});
      this.$emit('show-car-model-flyout', true);
    },
    hideModal() {
      this.$store.dispatch('hideModal', {id: this.id, action: 'close'});
      this.$emit('show-car-model-flyout', false);
      this.carModel = null;
    },
    onCarModelSelected(carModel) {
      this.carModel = carModel;
    },
    onMotorizationSelected() {
      this.hideModal();
      this.$store.dispatch('hideModal', {id: this.id, action: 'close'});
    },
    resetSelected() {
      this.carModel = null;
    }
  }
};
</script>

