import encodeFormData from '../../utilities/js/encodeFormData/encodeFormData.js';

let service;

class BackendRequest {
  getBackendRequest(url, requestHeaders = {}, data) {
    return this.doBackendRequest(url, data, 'GET', requestHeaders)
  }

  postBackendRequest(url, data, requestHeaders = {}, json = false) {
    return this.doBackendRequest(url, data, 'POST', requestHeaders, json)
  }

  deleteBackendRequest(url, data) {
    return this.doBackendRequest(url, data,'DELETE');
  }

  doBackendRequest(url, data, method, requestHeaders = {}, json = false, credentials = 'include') {
    let useBody = method.toLowerCase() !== 'get' && method.toLowerCase() !== 'delete'
    let thisUrl = data && !useBody ? this.getUrlWithDataAsUrlParams(url, data) : `${url}`;
    let headers = {};
    let requestInit = {};

    headers[window.__globalConfig.csrfToken.header] = window.__globalConfig.csrfToken.value;
    for (const [key, value] of Object.entries(requestHeaders)) {
      headers[key] = value;
    }
    requestInit['method'] = method;
    requestInit['credentials'] = credentials;

    if (data && useBody) {
      headers['Content-Type'] = json ? 'application/json; charset=utf-8' : 'application/x-www-form-urlencoded; charset=UTF-8';
      data[window.__globalConfig.csrfToken.name] = window.__globalConfig.csrfToken.value;
      requestInit['body'] = json ? JSON.stringify(data) : encodeFormData(data);
    }
    requestInit['headers'] = headers;

    return fetch(thisUrl, requestInit)
  }

  getUrlWithDataAsUrlParams(url, dataObject) {
    let joiner = url.includes("?") ? '&' : '?';
    return `${url}${joiner}${encodeFormData(dataObject)}`;
  }
}

function backendRequest() {
  if (service) {
    return service;
  } else {
    service = new BackendRequest();
    return service
  }
}

export default backendRequest;
