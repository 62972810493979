<template>
  <div class="service-links">
    <ul class="service-links__list">
      <li
        class="service-links__item"
        v-for="(item, index) in links"
        :key="index"
      >
        <a
          :href="item.link"
          class="service-links__link"
        >
          {{ item.text }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    links: Object,
  }
}
</script>
