const productIDsMixin = {
  methods: {
    renderProductNumbers(jzbNumber, bundleCode) {
      let ids = [];
      if (bundleCode && bundleCode !== '') {
        ids.push(this.$t('global.article.unifiy.product.bundle_code') + ' ' + bundleCode)
      }
      else if (jzbNumber && jzbNumber !== '') {
        ids.push(this.$t('global.article.unifiy.product.jzb_number') + ' ' + jzbNumber)
      }
      return ids.length ? ids.reduce((a, b) => a + ' / ' + b) : '';
    }
  }
}

export default productIDsMixin;
