<template>
  <div
    class="modal"
    :class="{
      'gallery': gallery,
      'left': leftAdjusted
    }"
    :id="id"
    v-if="visible"
  >
    <div
      class="modal__header"
      :class="{
        'modal__header--justify-end' : !headline
      }"
    >
      <h1
        class="headline headline--type_h3 modal__header-headline"
        :class="[headlineType ? `modal__header--${headlineType}` : '']"
        v-if="headline"
        v-html="headline"
      />
      <span
        class="modal__close-button"
        @click.stop.prevent="close"
      />
    </div>
    <div
      class="modal__content"
      :class="{'modal__content--extended' : !withFooter}"
      ref="modalContent"
    >
      <slot />
    </div>
    <div
      v-if="withFooter"
      class="modal__footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';

export default {
    mixins: [
      globalStateComputedMixin
    ],
    props: {
      id: String,
      headline: String,
      headlineType: String,
      initialVisible: {
        type: Boolean,
        default: false
      },
      gallery: {
        type: Boolean,
        default: false
      },
      leftAdjusted: {
        type: Boolean,
        default: false
      },
      scrollOnClose: {
        type: Object,
        default: undefined
      },
      withFooter: {
        type: Boolean,
        default: false
      },
    },
    emits: ['show', 'hide'],
    data() {
      return {
        visible: this.initialVisible
      };
    },
    created() {
      this.$store.subscribe(mutation => {
        if(mutation.type === 'showModal') {
          this.subscribeModalOpenHandler(mutation.payload);
        }

        if(mutation.type === 'hideModal') {
          this.subscribeModalCloseHandler(mutation.payload);
        }
      });
    },
    methods: {
      subscribeModalOpenHandler(data) {
        if (data.id === this.id) {
          this.visible = true;
        }
      },
      subscribeModalCloseHandler(data) {
        if (data.id === this.id) {
          this.visible = false;
        }
      },
      open() {
        this.$emit('show');
        this.$store.dispatch('showModal', {id: this.id});
      },
      close() {
        this.$emit('hide');
        this.$store.dispatch('hideModal', {id: this.id});
        if (this.scrollOnClose) {
          this.$nextTick(() => {
            this.$store.commit('scrollTo', {'id': this.scrollOnClose.id, 'offset': this.scrollOnClose.offset}, {root: true})
          });
        }
      },
      scrollTop() {
        this.$refs.modalContent.scrollTop = 0;
      }
    }
  };
</script>
