const mutations = {
  updateFilterOptions(state, data) {
    // .map() here is to get rid of vue observer
    state.filters[data.index].activeOptions = data.activeOptions.map(value => value);
  },
  updateFilterRange(state, data) {
    state.filters[data.index].minPrice = data.minPrice;
    state.filters[data.index].maxPrice = data.maxPrice;
    state.filters[data.index].priceRangeChanged = true;
  },
  updateCurrentPage(state, data) {
    state.pagination.currentPage = data.value;
  },
  updateSortValue(state, data) {
    state.sorting.selectedOptionValue = data.value
  },
  updateProductsList(state, newList) {
    state.elementsList = newList;
  },
  updateResultCount(state, resultCount) {
    state.resultCount = resultCount;
  },
  updateProductOnList(state, updatedData){
    if(!updatedData) {
      return;
    }
    state.elementsList[updatedData.boxIndex].data = updatedData;
  },
  updateFiltersState(state, newFiltersData) {
    state.filters = newFiltersData;
  },
  updatePagination(state, pagination) {
    state.pagination.currentPage = pagination.currentPage;
    state.pagination.pagesCount = pagination.pagesCount;
  },
  updateHeadline(state, headline) {
    state.headline = headline;
  },
  removeActiveFilterOption(state, filterData) {
    const filter = state.filters.find(filter => filter.name === filterData.name);

    if (filter) {
      const optionIndex = filter.activeOptions.indexOf(filterData.value);

      if (optionIndex >= 0) {
        filter.activeOptions.splice(optionIndex, 1);
      }

      if (filter.type === 'price' && optionIndex === -1) {//do not apply if active Options "reduced" is removed
        filter.selectedRange = '';
        filter.minPrice = filter.minPriceRange;
        filter.maxPrice = filter.maxPriceRange;
      }
    }
  },
  removeAllActiveFilterOptions(state) {
    for (let filter of state.filters) {
      filter.activeOptions = [];
      if (filter.type === 'price') {
        filter.selectedRange = '';
        filter.minPrice = filter.minPriceRange;
        filter.maxPrice = filter.maxPriceRange;
      }
    }
  }
};

export default mutations;
