import scrollTo from 'scroll-to';

function scrollToElement(element, offset = 0) {
  const headerHeight = document.getElementById('header-sticky').getBoundingClientRect().height;
  let isScrolling = false;
  let scrollTimeout;

  if(isScrolling) {
    clearTimeout(scrollTimeout);
  }

  const elementOffset = element.getBoundingClientRect().top + window.pageYOffset;

  if (elementOffset > window.pageYOffset && window.pageYOffset + window.innerHeight >= document.body.offsetHeight) {
    return;
  }

  isScrolling = true;

  scrollTo(0, elementOffset - headerHeight - offset, {duration: 800});

  scrollTimeout = setTimeout(() => {
    isScrolling = false;
  }, 1000);
}

export default scrollToElement;
