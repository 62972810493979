<template>
  <div
    class="product-info"
  >
    <div class="product-info__wrapper--headline">
      <h1
        class="headline headline--type_h1 product-info__headline"
        :style="{'font-size': headingFontSize + 'rem'}"
      >
        {{ productDetails.product.name }}
      </h1>
      <span class="product-info__product-number">
        {{ renderProductNumbers(productDetails.product.jzbNumber, productDetails.product.bundleCode) }}
      </span>
    </div>
    <template v-if="!productDetails.product.bundleCode">
      <product-price />
      <div
        class="product-info__available-parts"
        @click="scrollToClickHandler"
      >
        {{ $t('ads.product.info.show.offers') }}
      </div>
    </template>

    <div
      v-if="hasModelCompatibility() && isCarModelSelectionEnabled"
      class="product-info__info"
    >
      <div class="product-info__info--additional">
        <div class="product-info__headline big-semi-bold">
          {{ $t('ads.product.info.additional') }}
        </div>
        <div class="product-info__info--additional-wrapper">
          <div
            v-for="(modelLevel0, modelLevel0Index) in productDetails.product.modelCompatibility"
            :key="`modelLevel0-${modelLevel0Index}`"
          >
            <car-compatibility :model="modelLevel0" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="product-info__in-selected-car-model"
      v-if="isCarModelSelectionEnabled && !inSelectedCarModel"
    >
      <div class="product-info__in-selected-car-model--text big-semi-bold">
        <span>{{ $t('global.product.incompatible.model') }}</span>
      </div>
    </div>
    <template v-if="productDetails.product.bundleCode">
      <product-price
        class="product-info__bundle-price"
      />
      <available-part-add-to-basket
        class="product-info__buttons"
        :available-part="availablePartDummy"
        :id="availablePartDummy.id"
        :key="`available-part-dummy__${availablePartDummy.id}`"
      />
      <available-part-modal
        :available-part="availablePartDummy"
        :id="availablePartDummy.id"
        :key="`available-part-modal__${availablePartDummy.id}`"
      />
    </template>
  </div>
</template>

<script>
import productPageStateComputedMixin
  from "../../utilities/vueMixins/productPageStateComputedMixin/productPageStateComputedMixin";
import globalStateComputedMixin
  from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import headlineSizeCalculation from "../headline/headline";
import CarCompatibility from "../carCompatibility/carCompatibility.vue";
import productIDsMixin from "../../utilities/vueMixins/productMixin/productIDsMixin";
import ProductPrice from "./productPrice.vue";
import AvailablePartAddToBasket from "../availableParts/availablePartAddToBasket.vue";
import AvailablePartModal from "../availableParts/availablePartModal.vue";

export default {
  components: {
    CarCompatibility,
    ProductPrice,
    AvailablePartAddToBasket,
    AvailablePartModal
  },
  mixins: [
    productPageStateComputedMixin,
    globalStateComputedMixin,
    headlineSizeCalculation,
    productIDsMixin
  ],
  props: {
    scrollToId: String,
  },
  emits: ['scrollToAvailablePart'],
  data() {
    return {};
  },
  methods: {
    scrollToClickHandler() {
      this.$emit('scrollToAvailablePart', this.scrollToId);
    },
    hasModelCompatibility() {
      return this.productDetails.product.modelCompatibility[0]?.categoryChilds[0]?.categoryChilds[0] !== undefined;
    }
  }
};
</script>
