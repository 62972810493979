<template>
  <header
    class="header"
    :class="[
      type ? `header__${type}` : '',
      showModal ? 'header__unset' : ''
    ]"
  >
    <div
      class="header__wrapper"
      id="SHOP_HOMEPAGE_BTN"
      ref="wrapperElement"
      :style="{
        height: wrapperElementHeight
      }"
    >
      <div
        class="header__sticky"
        :class="{
          'header__sticky--active': stickyIsActive
        }"
        id="header-sticky"
      >
        <div
          class="header__main"
          :class="[
            type ? `header__main--type_${type}` : '',
            !isUserLoggedIn ? 'header__main--type_not-logged-in' : ''

          ]"
        >
          <span
            v-if="type !== 'checkout'"
            class="header__burger-menu-trigger"
            @click="burgerMenuTriggerClick"
          />
          <a
            :href="mainHref"
            class="header__logo-link"
            :aria-label="$t('global.home')"
          >
            <img
              class="header__logo--mobile"
              v-if="isMobile"
              :alt="logoTitle"
              :title="logoTitle"
              :src="`${baseAssetsUrl}/staticImages/audi_rings-black.svg`"
            >
            <img
              class="header__logo"
              v-else
              :alt="logoTitle"
              :title="logoTitle"
              :src="`${baseAssetsUrl}/staticImages/${logoImage}`"
            >
          </a>
          <checkout-process-breadcrumbs
            v-if="type === 'checkout'"
            class="header__checkout-process-breadcrumbs"
          />
          <user-shortcut-header
            :type="type"
            class="header__user-shortcuts"
            :class-name="'header__user-shortcut'"
          />
          <select-car-model-flyout
            :id="`select-car-model-flyout`"
            class="select-car-model-flyout-mobile"
            @show-car-model-flyout="checkShowModal"
          />
          <div
            class="header__search"
            :class="{'header__search--active' : searchActivated}"
            v-if="isUserLoggedIn"
          >
            <search-autosuggest
              v-if="type !== 'checkout'"
              class="header__search-autosuggest"
              id="SEARCH_FIELD"
              :action="searchBaseUrl"
              name="q"
              @searchActivated="toggleSearchActivated"
            />
          </div>
          <navigation
            v-if="type !== 'checkout' && isUserLoggedIn"
            class="header__navigation"
            @show-child-modal="checkShowModal"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';
import SearchAutosuggest from '../searchAutosuggest/searchAutosuggest.vue';
import Navigation from '../navigation/navigation.vue';
import CheckoutProcessBreadcrumbs from '../checkoutProcessBreadcrumbs/checkoutProcessBreadcrumbs.vue';
import SelectCarModelFlyout from "../selectCarModelFlyout/selectCarModelFlyout.vue";
import {breakpoints} from "../../variables/variables";
import UserShortcutHeader from "../userShortcut/userShortcutHeader.vue";

export default {
  mixins: [
    globalStateComputedMixin
  ],
  components: {
    SearchAutosuggest,
    Navigation,
    CheckoutProcessBreadcrumbs,
    UserShortcutHeader,
    SelectCarModelFlyout
  },
  props: {
    type: String,
    stickyEnabled: {
      type: Boolean,
      default: true
    },
    logo: String,
    logoImage: String,
  },
  data() {
    return {
      stickyIsActive: false,
      wrapperElementHeight: '',
      searchActivated: false,
      isMobile: window.innerWidth < breakpoints.laptop,
      showModal: false
    };
  },
  mounted() {
    if (this.stickyEnabled) {
      this.headerStickyToggler();
      window.addEventListener('scroll', this.headerStickyToggler.bind(this));
    }
    window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
  },
  methods: {
    headerStickyToggler() {
      const windowScrollPos = window.pageYOffset;

      // skip if Modal is currently open (fixes iOS iPad issues)
      if (this.showModal) {
        return;
      }

      if (windowScrollPos > this.$refs.wrapperElement.offsetTop && !this.stickyIsActive) {
        this.activateStickyPos();
      } else if (windowScrollPos <= this.$refs.wrapperElement.offsetTop && this.stickyIsActive) {
        this.deactivateStickyPos();
      }
    },
    activateStickyPos() {
      this.stickyIsActive = true;
      this.wrapperElementHeight = `${this.$refs.wrapperElement.offsetHeight}px`;
    },
    deactivateStickyPos() {
      this.stickyIsActive = false;
      this.wrapperElementHeight = '';
    },
    burgerMenuTriggerClick() {
      this.$store.dispatch('toggleBurgerMenu');
    },
    breakpointChangeHandler() {
      this.isMobile = window.innerWidth < breakpoints.laptop;
    },
    toggleSearchActivated(state) {
      this.searchActivated = state;
    },
    checkShowModal(showModal) {
      this.showModal = showModal;
    }
  }
};
</script>
