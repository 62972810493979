<template>
  <div
    v-if="productsList.length > 0"
    class="minicart"
  >
    <span
      v-if="miniFlyout.itemAdded !== 'cart'"
      class="minicart__close"
      @click="closeHandler"
    />
    <div
      class="minicart__header"
      :class="{'minicart__header--add' : miniFlyout.itemAdded === 'cart'}"
    >
      <div v-if="miniFlyout.itemAdded !== 'cart'">
        {{ $t('global.minicart.cart') }}, {{ basketCount }} {{ $t('global.article.label') }}
      </div>
      <div
        v-else-if="miniFlyout.itemAdded === 'cart'"
        class="minicart__header--added"
      >
        {{ $t('global.add.message.cart') }}
      </div>
    </div>
    <div
      :class="[productsList.length >= 3 ? 'minicart__products-scroll' : 'minicart__products']"
      :id="`minicart`"
    >
      <product-order-position
        class="minicart__product-order-position"
        v-for="(product, index) in productsList"
        v-bind="product"
        :key="`minicart-${index}`"
        :index="index"
        :flyout="true"
        :id="`minicart-${product.itemId}`"
        type="minicart"
      />
    </div>
    <div
      class="minicart__footer"
      :class="{
        'minicart__footer--many' : productsList.length >= 3
      }"
    >
      <div
        class="minicart__price-box"
      >
        <!--    ToDo ??      -->
        <div
          class="minicart__footer-box"
          v-if="cartInfo.promotionResult.promotionsSaving"
        />
        <div class="minicart__footer-box">
          <span class="minicart__shipping">
            {{ $t('ads.main.shipping.cost') }}
          </span>
          <span
            class="minicart__shipping"
            v-html="cartInfo.promotionResult.deliveryResult.deliveryCost.displayValue"
          />
        </div>
        <div class="minicart__footer-box">
          <span class="minicart__total">
            {{ $t('global.order.total.label') }} <small>({{ $t('global.inclVAT') }})</small>
          </span>
          <span class="minicart__total">
            {{ cartInfo.promotionResult.total.displayValue }}
          </span>
        </div>
      </div>
      <a
        class="minicart__button | button button--size_40"
        :href="'/basket/'"
      >
        {{ $t('global.to_basket') }}
      </a>
    </div>
  </div>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';
import ProductOrderPosition from "../productOrderPosition/productOrderPosition";
import VueScrollTo from 'vue-scrollto'

export default {
    mixins: [
      globalStateComputedMixin
    ],
    components: {
      ProductOrderPosition
    },
    emits: ['closeFlyout'],
    data() {
      return {
        scroll: '',
        mutationType: ''
      }
    },
    mounted() {
      this.$store.subscribe(mutation => {
        if (mutation.type === 'showMinicart') {
          this.mutationType = mutation.payload.type;
          if (mutation.payload.itemId) {
            this.scroll = 'mini' + this.mutationType + '-' + mutation.payload.itemId;
          }
        }
      });
    },
    updated() {
      if(this.pageType !== 'cart' && this.mutationType === 'cart' || this.mutationType === 'addToCartFromWishlist') {
        if(this.scroll) {
          let elementToBeScrolledTo = document.getElementById(this.scroll);
          VueScrollTo.scrollTo(elementToBeScrolledTo, 1000, {
            container: '#minicart',
            cancelable: true,
            onDone: () => this.scroll = ''
          })
        }
      }
    },
    computed: {
      productsList() {
        return this.cart.positions;
      },
      cartInfo() {
        return this.cart
      }
    },
    methods: {
      closeHandler() {
        this.$emit('closeFlyout')
      }
    }
  };
</script>
