<template>
  <div class="brands-teaser">
    <div
      v-if="headline"
      class="brands-teaser__headline | headline headline--type_teaser"
    >
      {{ headline }}
    </div>
    <carousel
      class="brands-teaser__carousel"
      v-if="brands"
      :slides-count="brands.length"
      :items="brands"
      :slides-per-view-desktop="7"
      :slides-per-view-mobile="5"
      :slides-per-view-tablet="2"
    >
      <template #slide="item">
        <brand-tile
          v-bind="item"
        />
      </template>
    </carousel>
    <brands-tiles-teaser
      class="brands-teaser__additional-brands"
      :initial-brands="additionalBrands"
    />
  </div>
</template>
<script>
  import Carousel from '../carousel/carousel.vue';
  import BrandTile from '../brandTile/brandTile.vue';
  import BrandsTilesTeaser from '../brandsTilesTeaser/brandsTilesTeaser.vue';

  export default {
    components: {
      Carousel,
      BrandTile,
      BrandsTilesTeaser
    },
    props: {
      headline: String,
      additionalBrands: {
        type: Array,
        default() {
          return [];
        }
      },
      topBrands: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        brands: this.topBrands
      }
    }
  };
</script>
