<template>
  <div class="car-compatibility">
    <div
      class="car-compatibility-level-one"
      v-for="(model, modelIndex) in model.categoryChilds"
      :key="`modelLevel1-${modelIndex}`"
    >
      <a
        @click="toggleIsOpen"
        :class="isOpen ? 'car-compatibility-level-one__active' : 'car-compatibility-level-one__inactive'"
      >
        {{ model.name }}
      </a>

      <div v-if="isOpen">
        <div
          class="car-compatibility-level-two"
          v-for="(modelLevel2, modelLevel2Index) in model.categoryChilds"
          :key="`modelLevel2-${modelLevel2Index}`"
        >
          <span class="product-info__info--additional-entry">{{ '&#9679; ' + modelLevel2.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: Object
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    }
  }
}
</script>