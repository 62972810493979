const translationsMixin = {
  created() {
    const t = this.$t.bind(this);

    this.$t = (key, options) => {
      const componentKey = `${this.$options.name}.${key}`;

      return this.$te(componentKey)
        ? t(componentKey, options)
        : t(key, options);
    }
  }
};

export default translationsMixin;
