<template>
  <label
    class="radio-inputs-list__option"
  >
    <Field
      class="radio-inputs-list__input"
      :name="name"
      type="radio"
      :value="modelValue"
      :id="`radio-${name}-${modelValue}`"
      @click="$emit('update:modelValue', $event.target.value)"
    />
    <span class="radio-inputs-list__radio-circle" />
    <div class="radio-inputs-list__option-content">
      <span class="radio-inputs-list__option-name">{{ label }}</span>
      <span
        v-if="description"
        class="radio-inputs-list__option-description"
      >
        {{ description }}
      </span>
    </div>
  </label>
</template>

<script>
import { Field } from "vee-validate";

export default {
  components: {
    Field,
  },
  props: {
    name: {
      type: String,
      default() {
        return '';
      }
    },
    initialValue: {
      type: String,
      default: '',
    },
    label: String,
    description: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      modelValue: this.initialValue
    }
  }
};
</script>
