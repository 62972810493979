import productsListPageState from './productsListPageState.js';
import productsListPageMutations from './productsListPageMutations.js';
import productsListPageGetters from './productsListPageGetters.js';
import productsListPageActions from './productsListPageActions.js';

const store = {
  namespaced: true,
  state: productsListPageState,
  mutations: productsListPageMutations,
  getters: productsListPageGetters,
  actions: productsListPageActions
};

export default store;
