const mutations = {
  updateProduct(state, updatedData) {
    if (!updatedData) {
      return;
    }
    state.productData = updatedData;
  },
  updateCurrentPage(state, data) {
    state.productData.pagingData.currentPage = data.value;
  },
  updateAvailablePartsList(state, newList) {
    state.productData.availableParts = newList
  },
  updateAvailablePartDummy(state, newDummy) {
    state.productData.availablePartDummy = newDummy
  },
  updatePagination(state, pagingData) {
    state.productData.pagingData.currentPage = pagingData.currentPage;
    state.productData.pagingData.pagesCount = pagingData.pagesCount;
  },
  updateSortValue(state, data) {
    state.productData.sortingData.selectedOptionValue = data.value
  },
  updateItemsPerPage(state, data) {
    state.productData.pagingData.entriesPerPage = data.value;
  },
  updateAvailablePart(state, availablePart) {
    const index = state.productData.availableParts.findIndex(part => part.id === availablePart.id);
    if (index !== -1) {
      state.productData.availableParts.splice(index, 1, availablePart);
    } else {
      state.productData.availableParts.push(availablePart);
    }
  }
};

export default mutations;
