function encodeFormData(dataObject) {
  let encodedData = [];

  dataObject = flatten(dataObject);
  for (let key in dataObject) {
    if (dataObject[key] != null && key !== '_csrf') {
      encodedData.push(`${encodeURIComponent(key)}=${encodeURIComponent(dataObject[key])}`);
    }
  }
  return encodedData.join('&');
}

const flatten = (function (data) {
  let result = {};

  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      let l = cur.length;
      for (let i = 0; i < l; i++)
        recurse(cur[i], prop + "[" + i + "]");
      if (l === 0) result[prop] = [];
    } else {
      let isEmpty;
      for (const p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }

  recurse(data, "");
  return result;
});

export default encodeFormData;
