<template>
  <div class="image-asset-list">
    <ul
      class="image-asset-list__list"
      :class="[
        align ? `image-asset-list__list--align_${align}` : ''
      ]"
    >
      <li
        v-for="(image, index) in assetList"
        :key="index"
        class="image-asset-list__image-wrapper"
        :class="[
          type ? `image-asset-list__image-wrapper--type_${type}` : ''
        ]"
      >
        <img
          class="image-asset-list__image"
          :src="`${globalImagePath}/staticImages/${image.name}`"
          :alt="image.alt"
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    assetList: Object,
    align: String,
    type: String,
    globalImagePath: String,
  }
}
</script>
