<template>
  <div class="filter">
    <input-vue
      v-if="filters[index].searchable"
      class="filter__search"
      :placeholder="$t('filter.search.placeholder')"
      v-model="searchValue"
    />
    <div
      class="filter__options"
      :class="{
        'filter__options--swatches': swatches
      }"
    >
      <label
        class="filter__option"
        :class="{
          'filter__option--swatch': swatches,
          'filter__option--swatch-active': swatches && activeOptions.includes(option.value),
          'filter__option--disabled': showOptionOnCountZero && option.count === 0
        }"
        v-for="option in transformedOptions"
        v-show="showOptionOnCountZero || option.count > 0"
        :key="`${name}-${option.value}`"
        :for="`${name}-${option.value}`"
      >
        <input
          class="filter__input"
          type="checkbox"
          name="name"
          v-model="activeOptions"
          :value="option.value"
          :id="`${name}-${option.value}`"
          :disabled="showOptionOnCountZero && option.count === 0"
          @change="optionChangeHandler"
        >
        <span
          class="icon-regular"
          :class="[
            swatches ? 'filter__box--swatch' : 'filter__box',
            swatches && option.swatchType === 'name' ? `swatch-color_${option.swatch.toLowerCase()}` : ''
          ]"
        />
        <span
          class="filter__label default-regular"
          :class="{
            'filter__label--swatch': swatches
          }"
        >
          {{ option.label }}
          <span
            class="filter__option-product-count default-regular"
            v-if="option.count"
          >
            ({{ option.count }})
          </span>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import plpStateComputedMixin from '../../utilities/vueMixins/plpStateComputedMixin/plpStateComputedMixin.js';
import InputVue from '../input/input.vue';

export default {
  components: {
    InputVue
  },
  mixins: [
    plpStateComputedMixin
  ],
  props: {
    name: {
      type: String,
      default: null
    },
    updatePageOnChange: {
      type: Boolean,
      default: true
    },
    showOptionOnCountZero: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchValue: '',
      activeOptions: this.$store.state.productsListPage.filters.find(filter => filter.name === this.name).activeOptions,
      selectedOptions: []
    };
  },
  created() {
    this.$store.subscribe(mutation => {
      if (mutation.type === 'productsListPage/updateFiltersState') {
        this.updateFiltersStateHandler();
      }
    });
    this.$store.subscribeAction({
      after: (action) => {
        if (action.type === 'productsListPage/historyPopState') {
          this.updateFiltersStateHandler();
        }
      }
    });
  },
  methods: {
    optionChangeHandler(event) {
      this.$store.dispatch('productsListPage/filterUpdate', {
        index: this.index,
        activeOptions: this.activeOptions,
        updatePageOnChange: this.updatePageOnChange
      });

      const selectedIndex = this.selectedOptions.findIndex(option => option === event.target.value);
      if (selectedIndex === -1) {
        this.selectedOptions.push(event.target.value);
      } else {
        this.selectedOptions.splice(event.target.value, 1);
      }
    },
    updateFiltersStateHandler() {
      if (this.filter) {
        this.activeOptions = this.filter.activeOptions;
      }
    },
    cancelFilter() {
      this.selectedOptions.forEach(selectedOption => {
        const index = this.activeOptions.findIndex(option => option === selectedOption);
        if (index === -1) {
          this.activeOptions.push(selectedOption);
        } else {
          this.activeOptions.splice(index, 1);
        }
      });

      this.$store.dispatch('productsListPage/filterUpdate', {
        index: this.index,
        activeOptions: this.activeOptions,
        updatePageOnChange: this.updatePageOnChange
      });

      this.selectedOptions = [];
    }
  },
  computed: {
    index() {
      return this.filters.findIndex(filter => filter.name === this.name);
    },
    filter() {
      return this.filters.find(filter => filter.name === this.name);
    },
    transformedOptions() {
      let test = new RegExp(this.searchValue, 'gi');

      return this.filter.options
        .filter(option => {
          return this.searchValue === '' || option.label.match(test);
        })
        .sort((option1, option2) => {
          if (this.swatches) {
            return 0;
          }

          let option1Selected = this.activeOptions.includes(option1.value);
          let option2Selected = this.activeOptions.includes(option2.value);

          return option1Selected && !option2Selected ? -1 : (!option1Selected && option2Selected ? 1 : 0);
        });
    },
    swatches() {
      return this.filter.type === 'color';
    }
  }
};
</script>
