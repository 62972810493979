import JsComponent from '../jsComponent/jsComponent';

class NavAllCategories extends JsComponent {
  constructor(mainElement, name = 'nav-all-categories') {
    super(mainElement, name);

    this.createClassNames(
      '--flyout-visible',
      '__link',
      '__link--active',
      '__navigation-flyout',
      '__navigation-flyout--visible',
      '__list',
      '__main-column',
      '__main-column--flyout-visible'
    );

    this.triggers = this.mainElement.querySelectorAll(`.${this.classNames.link}`);
    this.flyouts = this.mainElement.querySelectorAll(`.${this.classNames.navigationFlyout}`);
    this.mainList = this.mainElement.querySelector(`.${this.classNames.list}`);
    this.mainColumn = this.mainElement.querySelector(`.${this.classNames.mainColumn}`);
    this.flyoutMouseIn = false;

    this.bindEvents();
  }

  bindEvents() {
    this.triggers.forEach((trigger, index) => {
      trigger.addEventListener('mouseenter', () => {
        this.openSubcategory(index);
      });
    });

    this.flyouts.forEach(flyout => {
      flyout.addEventListener('mouseenter', this.flyoutsMouseEnterHandler.bind(this));
      flyout.addEventListener('mouseleave', this.flyoutsMouseLeaveHandler.bind(this));
    });

    this.mainElement.addEventListener('mouseleave', this.closeAllSubcategories.bind(this));
    this.mainList.addEventListener('mouseleave', this.mainListMouseLeaveHandler.bind(this));
  }

  openSubcategory(index) {
    this.closeAllSubcategories();
    this.triggers[index].classList.add(this.classNames.linkActive);
    this.flyouts[index].classList.add(this.classNames.navigationFlyoutVisible);
    this.mainColumn.classList.add(this.classNames.mainColumnFlyoutVisible);
    this.mainElement.classList.add(this.classNames.flyoutVisible);
  }

  flyoutsMouseEnterHandler() {
    this.flyoutMouseIn = true;
  }

  flyoutsMouseLeaveHandler() {
    this.flyoutMouseIn = false;
  }

  closeAllSubcategories() {
    this.flyouts.forEach(flyout => {
      flyout.classList.remove(this.classNames.navigationFlyoutVisible);
    });

    this.triggers.forEach(trigger => {
      trigger.classList.remove(this.classNames.linkActive);
    });

    this.mainColumn.classList.remove(this.classNames.mainColumnSubcategoryVisible);
    this.mainElement.classList.remove(this.classNames.flyoutVisible);
  }

  mainListMouseLeaveHandler() {
    setTimeout(() => {
      if (!this.flyoutMouseIn) {
        this.closeAllSubcategories();
      }
    }, 50);
  }
}

export default NavAllCategories;
