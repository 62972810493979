import {collapseAnimation} from "../../js/collapseAnimation/collapseAnimation";
import pigeon from "../../js/pigeon/pigeon";

const AccordionMixin = {
  methods: {
    buttonClickHandler() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open() {
      if (this.isOpen || (this.mobileOnly && !this.isMobile)) return;
      this.isOpen = true;
      if (this.currentAnimation) {
        this.currentAnimation.stop();
      }

      if (this.id) {
        this.currentAnimation = collapseAnimation(
            this.$refs.content,
            300,
            () => pigeon.publish('accordion:open', this.id)
        );
      } else {
        this.currentAnimation = collapseAnimation(this.$refs.content);
      }

    },
    close() {
      if (!this.isOpen || (this.mobileOnly && !this.isMobile)) return;
      this.isOpen = false;
      if (this.currentAnimation) {
        this.currentAnimation.stop();
      }
      this.currentAnimation = collapseAnimation(this.$refs.content);
    },
  }
};

export default AccordionMixin;

