<template>
  <div
    v-if="prevProduct || nextProduct"
    class="product-page-pagination"
  >
    <a
      v-if="prevProduct"
      class="product-page-pagination__product product-page-pagination__product--prev"
      :href="prevProduct"
    >
      {{ prevProductMessage }}
    </a>
    <a
      v-if="nextProduct"
      class="product-page-pagination__product product-page-pagination__product--next"
      :href="nextProduct"
    >
      {{ nextProductMessage }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    prevProduct: String,
    nextProduct: String,
    prevProductMessage: String,
    nextProductMessage: String,
  }
}
</script>
