<template>
  <Field
    class="select"
    :name="name"
    :label="label"
    tabindex="0"
    v-slot="{ field, meta, errorMessage }"
  >
    <label
      v-if="label"
      class="select__label"
      :class="{
        'select__label--required': isRequired,
      }"
    >
      {{ label }}
    </label>
    <div
      class="select__wrapper"
      :class="{
        'select__wrapper--error': !!errorMessage,
        'select__wrapper--valid': meta.valid,
        'select__wrapper--disabled': disabled,
        'select__wrapper--focus': focus
      }"
    >
      <select
        v-bind="field"
        class="select__field"
        :aria-label="name"
        :disabled="disabled"
        :id="id"
        @blur="blurHandler"
        @focus="focusHandler"
        @change="event => {
          changeHandler(event)
        }"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :disabled="option.disabled"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>

    <ErrorMessage
      v-if="!suppressErrorMessages"
      as="span"
      class="input__error | input-error"
      :name="name"
    />
  </Field>
</template>

<script>
import { ErrorMessage, Field } from "vee-validate";

export default {
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    id: {
      type: String,
      default: ''
    },
    inverse: {
      type: Boolean,
      default: false
    },
    initialValue: String,
    suppressErrorMessages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValueLabel: null,
      focus: false,
      iconPath: '',
    };
  },
  emits: ['input', 'change', 'blur'],
  methods: {
    blurHandler() {
      this.focus = false;
      this.$emit('blur');
    },
    focusHandler() {
      this.focus = true;
    },
    changeHandler(event) {
      this.$emit('change', { value: event.target.value });
    },
  }
};

</script>
