<template>
  <address class="address-wrapper">
    <span class="address-wrapper__headline big-semi-bold">{{ headline }}</span>
    <span>{{ address.addressAddition }}</span>
    <span>{{ address.firstName }} {{ address.lastName }}</span>
    <span>{{ address.street }} {{ address.streetNr }}</span>
    <span>{{ address.zipCode }} {{ address.city }}</span>
  </address>
</template>

<script>
export default {
  props: {
    address: Object,
    headline: String,
  }
}
</script>
