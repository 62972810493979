function createTooltip(props) {
  const tooltip = document.createElement('div');
  tooltip.className = 'tooltip';
  tooltip.style.opacity = '0';
  tooltip.style.visibility = 'hidden';
  setProperties(tooltip, props);

  return tooltip;
}

function setProperties(tooltip, props) {
  if (props !== undefined) {
    if (typeof props === 'string') {
      tooltip.innerHTML = props;
    } else if (typeof props === 'object') {
      if (props.text) {
        tooltip.innerHTML = props.text;
      }

      if (props.position) {
        switch (props.position) {
          case 'bottom':
            tooltip.classList.add('tooltip--bottom');
            break;
          default:
            break;
        }
      }
    }
  }
}

const tooltip = {
  inserted(el, binding) {
    el.tooltip = createTooltip(binding.value);
    el.appendChild(el.tooltip);

    el.addEventListener('mouseover', () => {
      el.tooltip.style.opacity = '1';
      el.tooltip.style.visibility = 'visible';
      
    })
  
    el.addEventListener('mouseleave', () => {
      el.tooltip.style.opacity = '0';

      setTimeout(() => {
        el.tooltip.style.visibility = 'hidden';
      }, 300);
    })
  },
  update(el, binding) {
    setProperties(el.tooltip, binding.value);
  },
  unbind(el) {
    el.tooltip = null;
  }
};

export default tooltip;
