<template>
  <div class="wishlist-page">
    <div class="wishlist-page__top">
      <h1 class="wishlist-page__name">
        {{ $t('headline') }}
      </h1>
    </div>
    <div class="wishlist-page__content">
      <template v-if="isWishlist">
        <div class="wishlist__products-container">
          <product-order-position
            v-for="product in wishlistItems"
            v-bind="product"
            :key="`wishlist-${product.itemId}`"
            :editable="!product.isFreeItem"
            type="wishlistPage"
            :flyout="false"
          />
        </div>
      </template>
      <template v-else>
        <h3 class="wishlist-page__info">
          {{ $t('whishlistEmpty') }}
        </h3>
      </template>
    </div>
    <div class="wishlist-page__button-wrapper">
      <form
        v-if="isWishlist"
        class="wishlist-page__all-to-basket"
        :action="addAllUrl"
        method="post"
        @submit.prevent="addAllItemsToBasket"
      >
        <csrf-input
          ref="csrf"
        />
        <button
          type="submit"
          class="wishlist-page__button wishlist-page__button--all | button"
        >
          {{ $t('addAllToBasket') }}
        </button>
      </form>
      <a
        href="/"
        class="wishlist-page__button | button button--alt button--type_back"
        :class="{'wishlist-page__button--left' : !isWishlist}"
      >
        {{ $t('goBacktoShop') }}
      </a>
    </div>
  </div>
</template>

<script>
  import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';
  import wishlistPageStateComputedMixin from '../../utilities/vueMixins/wishlistPageStateComputedMixin/wishlistPageStateComputedMixin.js';
  import CsrfInput from '../csrfInput/csrfInput.vue';
  import ProductOrderPosition from '../productOrderPosition/productOrderPosition.vue'

  export default {
    mixins: [
      translationsMixin,
      wishlistPageStateComputedMixin
    ],
    components: {
      CsrfInput,
      ProductOrderPosition
    },
    methods: {
      addAllItemsToBasket() {
        let data = {
          url: this.addAllUrl,
          formData: {
            _csrf: this.$refs.csrf.getValue(),
            count: this.wishlistItems.length
          }
        }
        this.$store.dispatch('wishlistPage/addAllItemsToBasket', data);
      }
    }
  };
</script>
