const getters = {
  productsCount(state) {
    return state.size;
  },
  productsList(state) {
    return state.positions;
  },
  productsCosts(state) {
    if (state.promotionResult) {
      return state.promotionResult.subtotal;
    }
  },
  orderDiscount(state) {
    if (state.promotionResult) {
      return state.promotionResult.promotionsSaving;
    }
  },
  orderShippingCosts(state) {
    if (state.promotionResult) {
      return state.promotionResult.deliveryResult.deliveryCost;
    }
  },
  orderShippingCostMultiplier(state) {
    if (state.promotionResult) {
      return state.promotionResult.deliveryResult.shipCostMultiplierValue;
    }
  },
  orderTotalCosts(state) {
    if (state.promotionResult) {
      return state.promotionResult.total;
    }
  },
  vatDetails(state) {
    return state.vatDetails;
  },
  taxInfoUrl(state) {
    return state.taxInfoUrl;
  },
};

export default getters;
