import productPageState from './productPageState.js';
import productPageMutations from './productPageMutations.js';
import productPageGetters from './productPageGetters.js';
import productPageActions from './productPageActions.js';

const store = {
  namespaced: true,
  state: productPageState,
  mutations: productPageMutations,
  getters: productPageGetters,
  actions: productPageActions
};

export default store;
