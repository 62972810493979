<template>
  <div class="brands-tiles-teaser">
    <div
      v-if="headline"
      class="brands-tiles-teaser__headline | headline headline--type_teaser"
    >
      {{ headline }}
    </div>
    <div
      v-if="brands.length"
      class="brands-tiles-teaser__list"
    >
      <div
        v-for="(brand, index) in brands"
        :key="index"
        class="brands-tiles-teaser__tile"
      >
        <brand-tile
          v-bind="brand"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import BrandTile from '../brandTile/brandTile.vue';

  export default {
    components: {
      BrandTile
    },
    props: {
      headline: String,
      initialBrands: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        brands: this.initialBrands
      }
    }
  };
</script>
