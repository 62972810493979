<template>
  <div>
    <csrf-input
      ref="csrf"
    />
    <modal
      class="available-parts__modal"
      :with-footer="added"
      ref="modal"
      :id="id"
      :headline="addToBasketHeadline"
      @hide="close"
    >
      <template v-if="!added">
        <p
          v-if="showError"
          class="available-parts__modal--addToCart__error"
          v-t="errorMessage"
        />
        <Form
          id="cartSelectForm"
          :validation-schema="validationSchema"
          v-slot="context"
          @submit="addToCart"
          @invalid-submit="onInvalidSubmit"
          :initial-values="{
            selectedBasketId: initialBasketId,
            moreReservationTime: moreReservationTime,
            timeReservation: reservationTime
          }"
          ref="cartSelectForm"
        >
          <div class="available-parts__modal__content">
            <div class="radio-inputs-list">
              <template
                v-for="cart in basketSelections"
                :key="cart.value"
              >
                <radio-input
                  name="selectedBasketId"
                  :label="cart.label"
                  :initial-value="cart.value"
                />
              </template>
              <radio-input
                name="selectedBasketId"
                :label="$t('reservation.duration.new.basket')"
                :initial-value="''"
              />
            </div>
            <input-vee-v
              v-if="context.values.selectedBasketId === ''"
              class="available-parts__modal--newBasketName"
              name="newBasketName"
              :label="$t('reservation.duration.new.basket.reference')"
              type="text"
            />
            <checkbox
              class="available-parts__modal--reservationTime"
              v-model="moreReservationTime"
              :label="$t('reservation.duration.text.checkbox')"
            />
            <div
              v-if="moreReservationTime"
              class="available-parts__modal--reservation-time-wrapper"
            >
              <select-vue
                class="available-parts__modal--timeReservation"
                :options="reservationDurations"
                :initial-value="initialReservationTime"
                :label="$t('reservation.duration.label')"
                :label-icon="'reservation-time-icon'"
                :use-custom-select="true"
                :use-native-on-mobile="false"
                :without-border="true"
                name="timeReservation"
                @change="timeReservation"
              />
              <p class="input__hint">
                {{ $t('reservation.duration.hint') }}
              </p>
            </div>
          </div>

          <div
            class="available-parts__modal--bottom"
          >
            <div class="available-parts__modal--bottom__label">
              <p v-if="moreReservationTime">
                {{ $t('reservation.duration.text', [reservationTimeLabel]) }}
              </p>
              <template v-else>
                <p>
                  {{ $t('reservation.duration.text.initial') }}
                  <br>
                  <a
                    class="available-parts__modal--bottom__link underlined"
                    @click.stop="toggleMoreReservationTime"
                  >
                    {{ $t('reservation.duration.text.initial.underlined') }}
                  </a>
                </p>
              </template>
            </div>
            <button
              class="available-parts__modal--addToCart button button--filled-default"
              id="ADD_TO_CART_BTN"
              v-t="'global.addToBasket'"
              type="submit"
              form="cartSelectForm"
              :disabled="context.errors.newBasketName || context.values.newBasketName === ''"
            />
          </div>
        </Form>
      </template>
      <template v-else>
        <div class="available-parts__modal__added--headline">
          <h2
            class="headline headline--type_h1"
            :style="{'font-size': headingFontSize + 'rem'}"
          >
            {{ productDetails.product.name }}
          </h2>
          <p
            class="product-info__product-number"
          >
            {{ renderProductNumbers(productDetails.product.jzbNumber, productDetails.product.bundleCode) }}
          </p>
        </div>
        <div class="available-parts__modal__added--gallery">
          <part-gallery
            :images="availablePart.images.length > 0 ? availablePart.images : productDetails.images"
            :image-versions="availablePart.images.length > 0 ? availablePart.imageVersions : productDetails.imageVersions"
            :id="id"
            :init-photo-swipe="false"
          />
        </div>
        <p
          v-if="availablePart.qualityClass"
          class="available-parts__modal__added--qualityClass"
        >
          {{ $t('global.product.quality.class') }}: {{ availablePart.qualityClass }}
        </p>
        <p class="product-info__price product-info__price--base bigger-regular">
          {{ availablePart.price.displayValue }}
        </p>
      </template>

      <template #footer>
        <div
          class="available-parts__modal--bottom"
          ref="modalFooter">
          <div class="available-parts__modal__added--actions | available-parts__modal--bottom">
            <a
              class="button button--outlined-default"
              v-t="'reservation.duration.continue_shopping'"
              @click.stop="closeModal"
            />
            <a
              :href="getBasketUrl"
              class="button button--filled-default"
              v-html="$t('reservation.duration.open.basket')"
            />
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import productPageStateComputedMixin from "../../utilities/vueMixins/productPageStateComputedMixin/productPageStateComputedMixin";
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import CsrfInput from "../csrfInput/csrfInput.vue";
import Modal from "../modal/modal.vue";
import Checkbox from "../checkbox/checkbox.vue";
import SelectVue from "../select/select.vue";
import PartGallery from "../partGallery/partGallery.vue";
import headlineSizeCalculation from "../headline/headline";
import ProductIDsMixin from "../../utilities/vueMixins/productMixin/productIDsMixin";
import pigeon from "../../utilities/js/pigeon/pigeon";
import {Form} from "vee-validate";
import * as yup from "yup";
import RadioInput from "../radioInput/radioInput.vue";
import InputVeeV from "../input/inputVeeV.vue";

export default {
  components: {
    PartGallery,
    CsrfInput,
    Modal,
    InputVeeV,
    Checkbox,
    SelectVue,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    RadioInput
  },
  mixins: [
    productPageStateComputedMixin,
    globalStateComputedMixin,
    headlineSizeCalculation,
    ProductIDsMixin
  ],
  props: {
    id: String,
    availablePart: Object,
  },
  data() {
    return {
      validationSchema: yup.object({
        selectedBasketId: yup.string(),
        newBasketName: yup.string()
          .when('selectedBasketId', {
            is: (value) => value === '',
            then: schema => schema.required(this.$t('global.validation.required'))
              .min(2, this.$t('global.validation.min', [2]))
              .max(20, this.$t('global.validation.max', [20]))
              .matches(/^[a-zA-Z0-9_-]*$/, this.$t('global.validation.alphaDash')),
            otherwise: schema => schema.optional(),
          }),
        timeReservation: yup.object()
      }),
      initialReservationTime: "1",
      baseReservationTime: "1", // used as default when the checkbox is not selected
      showInput: false,
      moreReservationTime: false,
      initialBasketId: '',
      reservationTime: undefined,
      showError: false,
      errorMessage: 'global.addToBasket.error',
      added: false,
      results: null
    };
  },
  created() {
    this.initialBasketId = this.cart.externalId;
    this.reservationTime = this.reservationDurations.find(selection => selection.value === this.initialReservationTime);
  },
  mounted() {
    pigeon.subscribe(`cancel:reservation:${this.availablePart.id}`, this.cancelReservation.bind(this))
  },
  computed: {
    newBasketName() {
      return this.$refs.cartSelectForm?.values.newBasketName === undefined ? "" : this.$refs.cartSelectForm.values.newBasketName;
    },
    selectedBasketId() {
      return this.$refs.cartSelectForm?.values.selectedBasketId === undefined ? this.initialBasketId :
        this.$refs.cartSelectForm.values.selectedBasketId;
    },
    reservationTimeLabel() {
      let reservationTime = this.reservationTime ? this.reservationTime.value : this.initialReservationTime;
      return this.reservationDurations.find(option => option.value === reservationTime).label
    },
    basketSelections() {
      return this.carts.map(cart => {
        return {
          value: cart.externalId,
          label: cart.name ? `${cart.name} (${cart.size})` : this.$t('global.cart') + ` (${cart.size})`,
          cartlabel: cart.name ? cart.name : this.$t('global.cart'),
        };
      }).sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    },
    getBasketUrl() {
      if (this.availablePart.reservation.externalBasketId &&
        this.cart.externalId !== this.availablePart.reservation.externalBasketId) {
        return `${this.basketChangeUrl}?externalId=${this.availablePart.reservation.externalBasketId}`;
      }
      return this.basketPageUrl;
    },
    addToBasketHeadline() {
      return !this.added ? this.$t('reservation.duration.headline') :
        this.$t('reservation.addedToBasket', [this.findSelectedAddToCartLabel]);
    },
    findSelectedAddToCartLabel() {
      return this.selectedBasketId === '' ? this.newBasketName : this.basketSelections.find(basket => basket.value ===
        (this.$refs.cartSelectForm?.values.selectedBasketId)).cartlabel;
    }
  },
  methods: {
    onInvalidSubmit({ values, errors, results }) {
      this.results.results = results;
      this.results.values = values;
      this.results.errors = errors;
    },
    addToCart() {
      this.$store.dispatch('productPage/addToCart', {
        formData: this.getFormData(),
        url: '/basket/add/',
        itemId: this.availablePart.id,
        isBundle: this.availablePartDummy !== undefined
      })
        .then(() => {
          this.added = true
        })
        .catch((response) => {
          this.errorMessage = response.message;
          this.$refs.modal.scrollTop();
          this.showError = true
        });
    },
    cancelReservation(id) {
      this.$store.dispatch('productPage/removeFromToCart', {
        formData: this.getFormData(),
        url: '/basket/delete-reservation/',
        itemId: id,
        isBundle: this.availablePartDummy !== undefined
      });
    },
    getFormData() {
      let data = {};
      data[this.$refs.csrf.getName()] = this.$refs.csrf.getValue();
      data["productId"] = this.productDetails.product.id;
      data["itemId"] = this.availablePart.id;
      data["quantity"] = this.availablePart.quantity;
      if (this.moreReservationTime) {
        data["reservationDuration"] = parseInt(this.reservationTime ? this.reservationTime.value : this.initialReservationTime);
      } else {
        data["reservationDuration"] = parseInt(this.baseReservationTime);
      }
      data.externalId = this.selectedBasketId;
      data.newBasketName = this.newBasketName;
      return data;
    },
    timeReservation(value) {
      this.initialReservationTime = value.value;
      this.reservationTime = value;
    },
    toggleMoreReservationTime() {
      this.moreReservationTime = true
    },
    closeModal() {
      this.$store.dispatch('hideModal', {id: this.availablePart.id, action: 'close'}, {root: true});
      this.close();
    },
    close() {
      this.initialReservationTime = "1";
      this.showInput = false;
      this.moreReservationTime = false;
      this.newBasketName = '';
      this.selectedBasketId = this.cart.externalId;
      this.reservationTime = this.reservationDurations.find(selection => selection.value === this.initialReservationTime);
      this.showError = false;
      this.added = false;
    }
  }
};
</script>
