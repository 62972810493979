<template>
  <div
    :class="[!listView ? 'products-list__tiles' : 'products-list__list']"
  >
    <div
      v-for="(element, index) in inSelectedModelProducts"
      :class="[!listView ? `products-list__tiles__element products-list__tiles__element--type_${element.type}` : `products-list__list__element--type_${element.type}`]"
      :key="`in-${index}`"
    >
      <template v-if="element.type === 'product'">
        <product-box
          class="products-list__product-box"
          v-bind="element.data"
          :index="index"
          :key="`in-product-${index}`"
          :list-view="listView"
          :product-update-path="productUpdatePath"
        />
      </template>
      <template v-else-if="element.type === 'insertion'">
        <slot
          :name="`slot-insertion-${element.data.id}`"
        />
        <template-compiler
          :template="getTeaser(`insertion-${element.data.id}`)"
        />
      </template>
    </div>
    <div
      v-if="notInSelectedModelProductsCounter > 0"
      class="products-list__not-in-selected-model-header-wrapper"
    >
      <span class="h3">{{ $t('global.product.not.in.model.label') }}</span>
    </div>
    <div
      v-for="(element, index) in notInSelectedModelProducts"
      :class="[!listView ? `products-list__tiles__element products-list__tiles__element--type_${element.type}` : `products-list__list__element--type_${element.type}`]"
      :key="`not-in-${index}`"
    >
      <template v-if="element.type === 'product'">
        <product-box
          class="products-list__product-box"
          v-bind="element.data"
          :index="index"
          :key="`not-in-product-${index}`"
          :list-view="listView"
          :product-update-path="productUpdatePath"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ProductBox from '../productBox/productBox.vue';
import TemplateCompiler from '../templateCompiler/templateCompiler.vue';

export default {
  components: {
    ProductBox,
    TemplateCompiler
  },
  props: {
    productsList: Array,
    productUpdatePath: String
  },
  computed: {
    inSelectedModelProducts: function () {
      return this.productsList.filter(function (product) {
        return product.data.inSelectedCarModel === true;
      });
    },
    notInSelectedModelProducts: function () {
      const notInSelected = this.productsList.filter(function (product) {
        return product.data.inSelectedCarModel === false;
      });
      this.notInSelectedModelProductsCounter = notInSelected.length;
      return notInSelected;
    },
  },
  mounted() {
    let articlesPerRow = this.$store.getters["productsListPage/articlesPerRow"];
    if (articlesPerRow === 3) {
      this.listView = false;
    } else {
      this.listView = true;
    }
  },
  methods: {
    getTeaser(id) {
      const insertion = this.$store.getters["productsListPage/insertion"](id);
      if (insertion !== undefined) {
        return insertion.data;
      }
    }
  },
  data() {
    return {
      teaserIndex: 0,
      listView: false,
      notInSelectedModelProductsCounter: 0
    }
  }
};
</script>
