<template>
  <div
    class="user-shortcut"
  >
    <a
      :href="url"
      class="user-shortcut__link"
      :class="[
        type ? `user-shortcut__link--${type}` : '',
        active ? `user-shortcut__link--active` : ''
      ]"
    >
      <span
        v-if="showBadge"
        class="user-shortcut__notification-badge"
        :class="[
          type ? `user-shortcut__notification-badge--${type}` : ''
        ]"
      >
        {{ badgeCount }}
      </span>
      <span
        v-if="label"
        class="user-shortcut__label"
      >
        {{ label }}
      </span>
    </a>
  </div>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';

export default {
  mixins: [
    globalStateComputedMixin
  ],
  props: {
    type: String,
    showBadge: {
      type: Boolean,
      default: false
    },
    badgeCount: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    flyoutCssPostfix: String,
    label: String,
    url: String
  },
  data() {
    return {
      showMiniFlyout: '',
    }
  },
  methods: {}
};
</script>
