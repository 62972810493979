<template>
  <footer
    id="footer"
    class="footer"
  >
    <div
      v-if="!isCheckout"
      class="footer__row-wrapper"
    >
      <div class="footer__row">
        <div class="footer__wrapper">
          <footer-teaser
            v-if="footerTeaserTabs && footerTeaserTabs.length && isUserLoggedIn"
            :tabs="footerTeaserTabs"
          />
          <div class="footer__links">
            <div
              v-if="!isMobile"
              class="footer__logotypes"
            >
              <language-switcher :inverse="true" />
            </div>
            <div class="footer__column">
              <span class="footer__link">
                <a
                  :href="baseUrl + $t('global.terms.link')"
                  class="default-regular"
                >
                  {{ $t('footer.terms') }}
                </a>
              </span>
              <span class="footer__link">
                <a
                  :href="baseUrl + $t('global.privacy.link')"
                  class="default-regular"
                >
                  {{ $t('footer.privacy') }}
                </a>
              </span>
              <span class="footer__link">
                <a
                  :href="baseUrl + $t('global.imprint.link')"
                  class="default-regular"
                >
                  {{ $t('footer.imprint') }}
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="footer__copyright">
          <span class="default-regular"> {{ $t('footer_navi_legal.copyright') }} </span>
        </div>
      </div>
    </div>
    <cookie-disclaimer />
  </footer>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';
import FooterTeaser from '../footerTeaser/footerTeaser.vue';
import CookieDisclaimer from '../cookieDisclaimer/cookieDisclaimer';
import LanguageSwitcher from '../languageSwitcher/languageSwitcher.vue';
import {breakpoints} from '../../variables/variables.js';

export default {
  components: {
    LanguageSwitcher,
    FooterTeaser,
    CookieDisclaimer
  },
  mixins: [
    globalStateComputedMixin,
  ],
  props: {
    isCheckout: {
      type: Boolean,
      default: false,
    },
    footerTeaserTabs: Array,
  },
  data() {
    return {
      isMobile: window.innerWidth < breakpoints.laptop,
    };
  },
  mounted() {
    window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
  },
  methods: {
    breakpointChangeHandler() {
      this.isMobile = window.innerWidth < breakpoints.laptop;
    },
  },
};
</script>
