import accountPageState from './accountPageState.js';
import accountPageMutations from './accountPageMutations.js';
import accountPageGetters from './accountPageGetters.js';
import accountPageActions from './accountPageActions.js';

const store = {
  namespaced: true,
  state: accountPageState,
  mutations: accountPageMutations,
  getters: accountPageGetters,
  actions: accountPageActions,
};

export default store;
