<template>
  <form
    class="search-autosuggest"
    ref="form"
    @click.stop
    @submit.prevent
  >
    <input-vue
      class="search-autosuggest__input"
      :class="{'search-autosuggest__input--active' : inputExpanded}"
      ref="input"
      :placeholder="$t('searchbox.placeholder')"
      :button="{
        type: 'button',
        icon: 'search'
      }"
      :name="name"
      :is-focused="inputExpanded"
      autocomplete="off"
      @keyUpEvent="inputKeyUpHandler"
      @focusin="inputFocusInHandler"
      @buttonclicked="submitForm"
      type="search"
    />
    <input
      type="hidden"
      name="ms"
      value="true"
    >
    <div v-if="suggestions && suggestions.length && showSuggestions">
      <div class="search-autosuggest__wrapper">
        <div class="search-autosuggest__suggestions-grid">
          <div
            v-for="(suggestionsList, listIndex) in suggestions"
            :key="listIndex"
            :class="getGridClass(suggestionsList.type)"
            class="search-autosuggest__suggestions-list"
          >
            <template
              v-if="suggestionsList.suggestions && suggestionsList.suggestions.length"
            >
              <h1
                v-if="getSuggestionsTitle(suggestionsList) && suggestionsList.type !== SearchAutosuggestType.PRODUCT_SUGGESTIONS"
                class="headline headline--type_h3 "
              >
                {{ getSuggestionsTitle(suggestionsList) }}
              </h1>
              <div
                :class="{'search-autosuggest__tiles' : suggestionsList.type === SearchAutosuggestType.PRODUCT_SUGGESTIONS}"
              >
                <div
                  v-for="(suggestion, suggestionIndex) in suggestionsList.suggestions"
                  :key="suggestionIndex"
                >
                  <div v-if="suggestionsList.type === SearchAutosuggestType.PRODUCT_SUGGESTIONS">
                    <search-autosuggest-box
                      v-bind="suggestion"
                    />
                  </div>
                  <div v-else>
                    <!-- '#' here reloads and also redirects the page. It redirects the user to
                    the 'no such result' page and inform the user that there are no such
                    suggestions. -->
                    <a
                      :class="{'search-autosuggest__suggestion--active': isAutosuggestionActive(listIndex, suggestionIndex)}"
                      :href="suggestion.link ? suggestion.link : '#'"
                      class="search-autosuggest__suggestion default-regular"
                      @click.prevent="chooseSuggestion(listIndex, suggestionIndex)"
                    >
                      {{ suggestion.value }}
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="search-autosuggest__suggestions-button">
          <button
            v-t="'global.search.suggest.button'"
            class="button button--filled-default default-regular"
            @click="submitForm"
          />
        </div>
      </div>
      <div
        class="search-autosuggest__overlay"
        @click.prevent="hideSuggestions"
      />
    </div>
  </form>
</template>

<script>
import InputVue from '../input/input.vue';
import SearchAutosuggestBox from '../searchAutosuggestBox/searchAutosuggestBox.vue';
import searchSuggestions from '../../services/searchSuggestions/searchSuggestions.js';
import {SearchAutosuggestType} from './searchAutosuggestType.js';
import pigeon from '../../utilities/js/pigeon/pigeon.js';
import breakpoints from '../../variables/js/breakpoints.js';

export default {
  computed: {
    SearchAutosuggestType() {
      return SearchAutosuggestType;
    }
  },
  components: {
    InputVue,
    SearchAutosuggestBox
  },
  props: {
    initialValue: {
      type: String,
      default: ''
    },
    name: String
  },
  data() {
    return {
      example: 'searchAutosuggest',
      showSuggestions: false,
      inputExpanded: false,
      previousQuery: '',
      pendingRequest: null,
      suggestions: [],
      activeListIndex: -1,
      activeSuggestionIndex: -1,
      query: this.initialValue
    };
  },
  mounted() {
    const body = document.querySelector('body');
    body.addEventListener('click', this.hideSuggestions.bind(this));
  },
  emits: ['searchActivated'],
  methods: {
    getGridClass(suggestionsType) {
      switch (suggestionsType) {
        case SearchAutosuggestType.CATEGORY_SUGGESTIONS:
          return 'search-autosuggest__suggestions-grid-category';
        case SearchAutosuggestType.PRODUCT_SUGGESTIONS:
          return 'search-autosuggest__suggestions-grid-products';
        case SearchAutosuggestType.TOP_SEARCH_SUGGESTIONS:
          return 'search-autosuggest__suggestions-grid-suggestion';
        default:
          return '';
      }
    },
    isAutosuggestionActive(listIndex, suggestionIndex) {
      return  this.activeListIndex === listIndex && this.activeSuggestionIndex === suggestionIndex;
    },
    inputFocusInHandler() {
      const suggest = new Promise(resolve => {
        if (this.initialValue !== '') {
          this.search();
          resolve(this.suggestions.length);
        } else {
          resolve(0);
        }
      });
      this.showSuggestions = suggest.then(length => length > 0);
    },
    inputKeyUpHandler(event) {
      let query = this.$refs.input.getValue();

      if (event.key === 'Enter') {
        this.submitKeyHandler();
      } else if (event.key === 'Alt ArrowLeft' && this.suggestions && this.suggestions.length) {
        this.prevSuggestion();
      } else if (event.key === 'Alt ArrowRight' && this.suggestions && this.suggestions.length) {
        this.nextSuggestion();
      } else if (query !== '' && query !== this.previousQuery) {
        this.previousQuery = query;
        this.search();
      } else if (query === '') {
        this.previousQuery = '';
        this.showSuggestions = false;
        this.suggestions = [];
        this.activeListIndex = -1;
        this.activeSuggestionIndex = -1;
      }
    },
    search() {
      this.pendingRequest = searchSuggestions()
        .searchForQuery(encodeURIComponent(this.$refs.input.getValue()))
        .catch(error => {
          pigeon.publish('globalAppError:show', {error: error})
        });

      this.pendingRequest
        .then(this.updateSuggestions.bind(this));
    },
    updateSuggestions(suggestions) {
      this.suggestions = suggestions;
      this.showSuggestions = !!this.suggestions.length;
    },
    chooseSuggestion(listIndex, suggestionIndex) {
      const item = this.suggestions[listIndex].suggestions[suggestionIndex];

      if (item.link) {
        window.location.href = item.link;
      } else {
        this.$refs.input.setValue(item.value);
        this.$nextTick(this.submitForm.bind(this));
      }
    },
    submitForm() {
      if (this.inputExpanded || window.innerWidth > breakpoints.laptop) {
        this.$refs.form.submit();
      } else {
        this.inputExpanded = true;
        this.$emit('searchActivated', true)
      }
    },
    submitKeyHandler() {
      if (this.activeListIndex > -1 && this.activeSuggestionIndex > -1) {
        this.chooseSuggestion(this.activeListIndex, this.activeSuggestionIndex);
      } else {
        this.submitForm();
      }
    },
    getSuggestionsTitle(suggestionsList) {
      return suggestionsList.suggestions[0].title;
    },
    hideSuggestions() {
      this.showSuggestions = false;
      this.inputExpanded = false;
      this.$emit('searchActivated', false);
    },
    nextSuggestion() {
      this.activeListIndex = this.activeListIndex < 0 ? this.getNextListIndex() : this.activeListIndex;

      if (this.activeSuggestionIndex + 1 > this.suggestions[this.activeListIndex].suggestions.length - 1) {
        this.activeListIndex = this.getNextListIndex();
        this.activeSuggestionIndex = this.activeListIndex >= 0 ? 0 : -1;
      } else {
        this.activeSuggestionIndex += 1;
      }
    },
    getNextListIndex() {
      let currentIndex = this.activeListIndex;
      let nextIndex;

      for (let i = currentIndex; i < this.suggestions.length; i++) {
        if (this.suggestions[i + 1]) {
          if (this.suggestions[i + 1].suggestions && this.suggestions[i + 1].suggestions.length) {
            nextIndex = i + 1;
            break;
          }
        } else {
          nextIndex = -1;
          break
        }
      }

      return nextIndex;
    },
    prevSuggestion() {
      if (this.activeSuggestionIndex - 1 < 0) {
        this.activeListIndex = this.getPrevListIndex();
        this.activeSuggestionIndex = this.activeListIndex >= 0 ? this.suggestions[this.activeListIndex].suggestions.length - 1 : -1;
      } else {
        this.activeSuggestionIndex -= 1;
      }
    },
    getPrevListIndex() {
      let currentIndex = this.activeListIndex;
      let prevIndex;

      for (let i = currentIndex; i >= -1; i--) {
        if (i - 1 < -1) {
          i = this.suggestions.length;
        }

        if (this.suggestions[i - 1]) {
          if (this.suggestions[i - 1].suggestions && this.suggestions[i - 1].suggestions.length) {
            prevIndex = i - 1;
            break;
          }
        } else {
          prevIndex = -1;
          break;
        }
      }

        return prevIndex;
      }
    }
  };
</script>
