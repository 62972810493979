<template>
  <div class="carousel">
    <swiper
      :modules="modules"
      :slides-per-view="slidesPerView"
      :slides-per-group="slidesPerView"
      :pagination="pagination"
      :items="items"
      :space-between="20"
      :grab-cursor="true"
      :loop="false"
      :navigation="sliderCustomNavigationObject"
    >
      <div
        class="carousel__button carousel__button--prev"
        :aria-label="$t('carousel.prev.button.label')"
      />
      <div
        class="carousel__button carousel__button--next"
        :aria-label="$t('carousel.next.button.label')"
      />
      <swiper-slide
        class="carousel__slide"
        v-for="(item, index) in items"
        :key="`slide-${item}`"
      >
        <slot
          name="slide"
          v-bind="{...item, index}"
        />
      </swiper-slide>
      <div
        v-if="pagination && slidesCount > 1"
        class="carousel__pagination"
        ref="pagination"
      />
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import { breakpoints } from '../../variables/variables.js';
import pigeon from '../../utilities/js/pigeon/pigeon.js';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    items: {
      type: Object,
      default() {
        return {}
      }
    },
    slidesCount: Number,
    slidesPerViewDesktop: {
      type: Number,
      default: 4
    },
    slidesPerViewTablet: Number,
    slidesPerViewMobile: Number,
    pagination: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modules: [Navigation, Pagination],
      slidesPerView: this.slidesPerViewDesktop,
      sliderCustomNavigationObject: {
        prevEl: `.carousel__button--prev`,
        nextEl: `.carousel__button--next`,
        disabledClass: 'carousel__button--hidden'
      }
    }
  },
  created() {
    this.breakpointChangeHandler();
  },
  mounted() {
    pigeon.publish('carousel:mounted');
    window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
  },
  methods: {
    breakpointChangeHandler() {
      if ( window.innerWidth <= breakpoints.phone ) {
        this.slidesPerView = this.slidesPerViewMobile
      } else if ( window.innerWidth <= breakpoints.tablet ) {
        this.slidesPerView = this.slidesPerViewTablet
      } else {
        this.slidesPerView = this.slidesPerViewDesktop
      }
    },
  }
};
</script>
