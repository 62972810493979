<template>
  <modal
    id="globalAppError"
    ref="modal"
    :initial-open="true"
  >
    <div class="global-app-error">
      <div class="global-app-error__message">
        {{ message ? message : 'Sorry, something went wrong' }}
      </div>
      <div
        v-if="error && itsDevMode"
        class="global-app-error__error"
      >
        ERROR (DEV MODE ENABLED)<br><br>
        {{ error }}
      </div>
    </div>
  </modal>
</template>

<script>
  import Modal from '../modal/modal.vue';
  import pigeon from '../../utilities/js/pigeon/pigeon.js';

  export default {
    components: {
      Modal
    },
    data() {
      return {
        message: null,
        error: null,
        // eslint-disable-next-line
        itsDevMode: _WEBPACK_DEV_MODE
      };
    },
    created() {
      pigeon.subscribe('globalAppError:show', this.show.bind(this));
      window._testge = function(data) {
        pigeon.publish('globalAppError:show', data);
      }
    },
    methods: {
      show(data) {
        this.message = data && data.message ? data.message : null;
        this.error = data && data.error ? data.error : null;
        this.$refs.modal.open();
      }
    }
  };
</script>
