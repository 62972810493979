<template>
  <div>
    <div
      class="select-car-model-motorizations__back default-regular"
      @click="backToSelection"
    >
      {{ $t('global.select.car.back.button.label') }}
    </div>

    <div class="select-car-model-motorizations__selected-car-model default-semi-bold">
      {{ carModel.name }}
    </div>

    <div class="select-car-model-motorizations__motorization default-semi-bold">
      {{ $t('global.select.car.motorization') }}
    </div>
    <div class="select-car-model-motorizations__wrapper">
      <a
        class="select-car-model__select"
        :class="{'select-car-model__select--disable': !motorization.partsAvailable }"
        :title="isPartAvailableInMotorization(motorization.partsAvailable)"
        v-for="motorization in carModel.carModelMotorizations"
        :key="motorization.id"
        @click="selectCarModel(motorization)"
      >
        <p class="select-car-model__select--text">
          {{ motorization.name }}
        </p>
      </a>
    </div>
  </div>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin';
import backendRequestService from '../../services/backendRequest/backendRequest.js'

export default {
  mixins: [
    globalStateComputedMixin
  ],
  components: {
  },
  props: {
    carModel: {
      type: Object,
      default: () => {}
    },
  },
  emits: ['motorization-selected', 'back-to-selection'],
  data() {
    return {}
  },
  methods: {
    selectCarModel(motorization) {
      if (motorization.partsAvailable) {
        let data = {};
        data['selectedCarModelId'] = motorization.id;

        backendRequestService().postBackendRequest('/car-model/add/', data)
          .then(response => {
            return response.json();
          })
          .then(() => {
            this.$emit('motorization-selected');
            location.reload();
        });
      }
    },
    isPartAvailableInMotorization(available) {
      return available ? '' : this.$t('global.select.car.parts.not.available');
    },
    backToSelection() {
      this.$emit('back-to-selection');
    }
  }
}
</script>
