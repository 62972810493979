import createParametersString from "../../utilities/js/createParametersString/createParametersString";
import backendRequestService from "../backendRequest/backendRequest";

let service;

class ProductPage {
  constructor() {
    this.pageBaseUrl = window.location.href.replace(/\?productId.*$/, '');
    this.requestUrl = `${window.__initialAppState.global.baseUrl}ajax/p/`;
  }

  getItemsList(parameters) {
    const createdParameters = createParametersString(parameters);
    const hashParameter = 'itemId=';
    const currentUrl = window.location.href;
    const extractItemId = (url) => {
      const hashIndex = url.indexOf(hashParameter);
      if (hashIndex !== -1) {
        const endIndex = url.indexOf('&', hashIndex) !== -1 ? url.indexOf('&', hashIndex) : url.length;
        return url.substring(hashIndex + hashParameter.length, endIndex);
      }
      return '';
    };

    let itemId = extractItemId(currentUrl);
        // clean
    let baseUrlWithoutHash = currentUrl.split('#')[0];
    let cleanedBaseUrl = baseUrlWithoutHash.replace(/\?.*$/, '');

    const baseUrlWithParameters = `${cleanedBaseUrl}?${createdParameters}${itemId ? `#${hashParameter}${itemId}` : ''}`;
    const requestUrl = `${this.requestUrl}?${createdParameters}${itemId ? `#${hashParameter}${itemId}` : ''}`;

    return fetch(requestUrl)
        .then(response => {
          if (response.status === 205) {
            window.location.href = requestUrl;
            return null;
          }
          return response.json();
        })
        .then(response => {
          if (response) {
            return {
              state: response,
              url: requestUrl,
              baseUrl: baseUrlWithParameters,
              itemId: itemId
            };
          }
        });
  }

  handleGeneralRequest(url, data, method) {
    return backendRequestService().doBackendRequest(url, data, method)
        .then(response => {
          if (response.status === 200 || response.ok) {
            return response.json();
          }
          if (response.status === 500) {
            return response.text();
          }
          if (response.status === 400){
            return response.text()
          }
        });
  }

  toggleVat(showVat) {
    let url = '/showVat/?b2b=' + showVat;
    return fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
        .then(response => {
          if (response.status === 200 || response.ok) {
            return response.json();
          }
        });
  }

}

function productPage() {
  if (service) {
    return service;
  } else {
    service = new ProductPage();
    return service;
  }
}

export default productPage;
