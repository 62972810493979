<template>
  <div class="filter-price">
    <range-slider
      class="filter-price__range-slider"
      ref="rangeSlider"
      :min-value-symbol="filters[index].currencySymbol"
      :max-value-symbol="filters[index].currencySymbol"
      :initial-range-min="filters[index].minPriceRange"
      :initial-range-max="filters[index].maxPriceRange"
      :initial-min-value="filters[index].minPrice"
      :initial-max-value="filters[index].maxPrice"
      :min-input-name="filters[index].priceMinInputName"
      :max-input-name="filters[index].priceMaxInputName"
      @change="priceRangeChangeHandler"
    />
  </div>
</template>

<script>
import plpStateComputedMixin from '../../utilities/vueMixins/plpStateComputedMixin/plpStateComputedMixin.js';
import RangeSlider from '../rangeSlider/rangeSlider.vue';

export default {
  mixins: [
    plpStateComputedMixin
  ],
  components: {
    RangeSlider
  },
  props: {
    name: {
      type: String,
      default: null
    },
    updatePageOnChange: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      prevMinPrice: 0,
      prevMaxPrice: 0
    }
  },
  updated() {
    const vm = this;
    this.$store.subscribe(mutation => {
      if (mutation.type === 'productsListPage/removeAllActiveFilterOptions' ||
        mutation.type === 'productsListPage/removeActiveFilterOption') {
        vm.$refs.rangeSlider?.resetValues();
      }
      if (mutation.type === 'productsListPage/updateFiltersState') {
        const priceFilter = mutation.payload.find(filter => filter.name === this.name);
        if (priceFilter) {
          vm.$refs.rangeSlider?.setValues([priceFilter.minPrice, priceFilter.maxPrice]);
        }
      }
    });
  },
  computed: {
    index() {
      return this.filters.findIndex(filter => filter.name === this.name);
    }
  },
  methods: {
    priceRangeChangeHandler() {
      this.prevMinPrice = this.filters[this.index].minPrice;
      this.prevMaxPrice = this.filters[this.index].maxPrice;
      this.priceOption = null;
      this.priceRangeUpdate(true);
    },
    getRangePriceValueData(type) {
      if (this.$refs.rangeSlider) {
        return this.$refs.rangeSlider.getValues()[type === 'min' ? 0 : 1];
      }
    },
    priceRangeUpdate(updatePage) {
      this.$store.dispatch('productsListPage/filterPriceRangeUpdate', {
        index: this.index,
        minPrice: this.getRangePriceValueData('min'),
        maxPrice: this.getRangePriceValueData('max'),
        updatePageOnChange: updatePage
      });
    },
    cancelFilter() {
      this.$refs.rangeSlider?.setValues([this.prevMinPrice, this.prevMaxPrice]);
      this.$refs.rangeSlider?.emitChange();
    }
  }
};
</script>
