const translationsMap = {
  'header-meta-content': {
    phoneNumber: 'meta.content.header.phone',
    phoneLabelNumber: 'meta.content.header.phone.string',
    phoneInfo: 'meta.content.header.open.lines',
    helpLabel: 'meta.content.header.help.link',
    serviceLabel: 'meta.content.header.service.link',
    aboutUsLabel: 'meta.content.aboutUs.link'
  },
  'navigation-flyout': {
    allProductsLink: 'global.allArticles.link'
  },
  'active-filters': {
    resetButtonLabel: 'productlist.filter.list.reset.button'
  },
  'product-description': {
    descriptionName: 'global.article.detail_information'
  },
  'product-features': {
    featuresName: 'ads.main.head.features'
  },
  'product-additional-information': {
    additionalInformationName: 'ads.main.shipping.cost.headline',
    info: 'ads.main.shipping.cost.info',
    info2: 'ads.main.shipping.cost.info2'
  },
  'filters': {
    buttonLabel: 'filters.button',
    name: 'filters.headline',
    resetButtonLabel: 'filters.reset.button',
    applyButtonLabel: 'filters.apply'
  },
  'wishlist-page': {
    headline: 'wishlist.headline',
    whishlistEmpty: 'global.whishlistEmpty',
    addAllToBasket: 'wishlist.basket.all.button',
    goBacktoShop: 'global.back_to_shop.button'
  }
};

export default translationsMap;
