<template>
  <div
    class="services-list-teaser"
    :class="[
      `services-list-teaser--size_${services.length}`
    ]"
  >
    <service-teaser
      v-for="(service, index) in services"
      v-bind="service"
      class="services-list-teaser__service"
      :key="index"
    />
  </div>
</template>

<script>
  import ServiceTeaser from '../serviceTeaser/serviceTeaser.vue';

  export default {
    components: {
      ServiceTeaser
    },
    props: {
      services: {
        type: Array,
        default() {
          return [];
        }
      }
    }
  };
</script>
