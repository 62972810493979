<template>
  <div
    v-if="visible"
    class="general-message"
    :class="[
      thisType ? `general-message--type_${thisType}` : ''
    ]"
  >
    <template
      v-if="list"
    >
      <ul class="general-message__messages-list">
        <li
          v-for="(msg, index) in thisMessage"
          class="general-message__message"
          v-html="msg"
          :key="index"
        />
      </ul>
    </template>
    <template
      v-else
    >
      <span
        class="general-message__message"
        v-html="singleMessage"
      />
    </template>
    <span
      class="general-message__close"
      @click="close"
    />
  </div>
</template>

<script>
  import pigeon from '../../utilities/js/pigeon/pigeon.js';

  export default {
    props: {
      type: {
        type: String,
        default: 'info'
      },
      hidden: {
        type: Boolean,
        default: false
      },
      message: Array,
      singleMessage: String,
      id: String,
      list: {
        type: Boolean,
        default: false
      },
      autoclose: {
        type: Boolean,
        default: true
      },
      autocloseTimeout: {
        type: Number,
        default: 10000
      }
    },
    data() {
      return {
        visible: !this.hidden,
        thisMessage: this.message,
        thisType: this.type
      };
    },
    created() {
      pigeon.subscribe('shopmessage:show', this.showMessageHandler.bind(this));
      pigeon.subscribe('shopmessage:hide', this.hideMessageHandler.bind(this));
    },
    mounted() {
      if(this.autoclose) {
        setTimeout(() => {
          this.close();
        }, this.autocloseTimeout)
      }
    },
    methods: {
      close() {
        this.visible = false;
      },
      open() {
        this.visible = true;
      },
      setMessage(message) {
        this.thisMessage = message;
      },
      setType(type) {
        this.thisType = type;
      },
      showMessageHandler(data) {
        if (data.id === this.id) {
          this.thisMessage = data.message;
          this.thisType = data.type ? data.type : this.thisType;
          this.open();
        }
      },
      hideMessageHandler(data) {
        if (data.id === this.id) {
          this.close();
        }
      }
    }
  };
</script>
