<template>
  <div
    v-show="productsList.length"
    class="miniwishlist"
  >
    <span
      v-if="miniFlyout.itemAdded !== 'wishlist'"
      class="miniwishlist__close"
      @click="closeHandler"
    />
    <div
      class="miniwishlist__header"
      :class="{'minicart__header--add' : miniFlyout.itemAdded === 'wishlist'}"
    >
      <div v-if="miniFlyout.itemAdded !== 'wishlist'">
        {{ $t('global.minicart.wishlist') }}, {{ wishlistCount }} {{ $t('global.article.label') }}
      </div>
      <div
        v-if="miniFlyout.itemAdded === 'wishlist'"
        class="minicart__header--added"
      >
        {{ $t('global.add.message.wishlist') }}
      </div>
    </div>
    <div
      :class="[productsList.length >= 4 ? 'miniwishlist__products-scroll' : 'miniwishlist__products']"
      :id="`miniwishlist`"
    >
      <product-order-position
        class="miniwishlist__product-order-position"
        v-for="(product, index) in productsList"
        v-bind="product"
        :key="`miniwishlist-${index}`"
        :index="index"
        :variation-map="product.variationMap"
        :flyout="true"
        :id="`miniwishlist-${product.itemId}`"
        type="miniwishlist"
      />
    </div>
    <div
      class="miniwishlist__footer"
      :class="{
        'miniwishlist__footer--many' : productsList.length >= 3
      }"
    >
      <a
        class="miniwishlist__button | button button--size_40"
        :href="'/wishlist/'"
      >
        {{ $t('ads.ads_top_info.to_wishlist.button') }}
      </a>
    </div>
  </div>
</template>

<script>
  import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';
  import ProductOrderPosition from "../productOrderPosition/productOrderPosition";
  import VueScrollTo from 'vue-scrollto'

  export default {
    components: {
      ProductOrderPosition
    },
    mixins: [
      globalStateComputedMixin
    ],
    emits: ['closeFlyout'],
    data() {
      return{
        scroll: '',
        mutationType: ''
      }
    },
    mounted() {
      this.$store.subscribe(mutation => {
        if (mutation.type === 'showMinicart') {
          this.mutationType =  mutation.payload.type;
          this.scroll = 'mini' + this.mutationType + '-' + mutation.payload.itemId;
        }
      });
    },
    updated() {
      if(this.pageType !== 'wishlist' && this.mutationType === 'wishlist') {
        if(this.scroll) {
          let elementToBeScrolledTo = document.getElementById(this.scroll);
          VueScrollTo.scrollTo(elementToBeScrolledTo, 1000, {
            container: '#miniwishlist',
            cancelable: true,
            onDone: () => this.scroll = ''
          })
        }
      }
    },
    computed: {
      productsList() {
        return this.wishlist;
      },
    },
    methods: {
      closeHandler(){
        this.$emit('closeFlyout')
      }
    }
  };
</script>
