<template>
  <div class="range-slider">
    <div class="range-slider__range">
      <span
        v-if="!editingMinValue"
        class="range-slider__current-value range-slider__current-value--min"
        @click="minValueClickHandler"
      >
        {{ readableMinValue }}
      </span>
      <input
        v-if="editingMinValue"
        ref="editMinValueInput"
        type="number"
        @blur="finishMinValueEdit"
        @keyup.enter.prevent="blurMinValueInput"
        v-model="minInputValue"
        class="range-slider__current-value-edit range-slider__current-value-edit--min"
        :name="minInputName"
      >
      <span
        v-if="!editingMaxValue"
        class="range-slider__current-value range-slider__current-value--max"
        @click="maxValueClickHandler"
      >
        {{ readableMaxValue }}
      </span>
      <input
        v-if="editingMaxValue"
        ref="editMaxValueInput"
        type="number"
        @blur="finishMaxValueEdit"
        @keyup.enter.prevent="blurMaxValueInput"
        v-model="maxInputValue"
        class="range-slider__current-value-edit range-slider__current-value-edit--max"
        :name="maxInputName"
      >
    </div>
    <div
      ref="slider"
      class="range-slider__slider"
    />
  </div>
</template>

<script>
  import noUiSlider from 'nouislider';

  export default {
    props: {
      initialRangeMin: {
        type: Number,
        default: 0
      },
      initialRangeMax: {
        type: Number,
        default: 100
      },
      initialMinValue: {
        type: Number,
        default: 0
      },
      initialMaxValue: {
        type: Number,
        default: 100
      },
      minValueEditable: {
        type: Boolean,
        default: true
      },
      maxValueEditable: {
        type: Boolean,
        default: true
      },
      minValueSymbol: String,
      maxValueSymbol: String,
      minInputName: String,
      maxInputName: String
    },
    emits: ['change'],
    data() {
      return {
        rangeMin: null,
        rangeMax: null,
        minValue: null,
        maxValue: null,
        minInputValue: 0,
        maxInputValue: 0,
        editingMinValue: false,
        editingMaxValue: false
      };
    },
    created() {
      this.setInitialValues();
    },
    mounted() {
      this.createSlider();
    },
    methods: {
      setInitialValues() {
        this.rangeMin = this.initialRangeMin;
        this.rangeMax = this.initialRangeMax <= this.rangeMin ? this.rangeMin + 1 : this.initialRangeMax;
        this.minValue = this.initialMinValue < this.rangeMin ? this.rangeMin : (this.initialMinValue < this.rangeMax ? this.initialMinValue : this.rangeMax - 1);
        this.maxValue = this.initialMaxValue > this.rangeMax ? this.rangeMax : (this.initialMaxValue > this.minValue ? this.initialMaxValue : this.minValue + 1);
      },
      createSlider() {
        this.rangeSlider = noUiSlider.create(
          this.$refs.slider,
          {
            start: [this.minValue, this.maxValue],
            connect: true,
            range: {
              min: this.rangeMin,
              max: this.rangeMax
            }
          }
        );

        this.bindSliderEvents()
      },
      bindSliderEvents() {
        this.rangeSlider.on('slide', this.sliderSlideHandler.bind(this));
        this.rangeSlider.on('change', this.sliderChangeHandler.bind(this));
      },
      sliderSlideHandler() {
        let values = this.rangeSlider.get();
        let min = Math.floor(Number(values[0]));
        let max = Math.floor(Number(values[1]));

        this.setInputValues([min, max])
      },
      sliderChangeHandler() {
        this.emitChange();
      },
      emitChange() {
        this.$emit('change');
      },
      setInputValues(values) {
        if (values[0] !== null) {
          this.minValue = values[0] < this.rangeMin ? this.rangeMin : (values[0] >= this.maxValue ? this.maxValue - 1 : values[0]);
        }

        if (values[1] !== null) {
          this.maxValue = values[1] > this.rangeMax ? this.rangeMax : (values[1] <= this.minValue ? this.minValue + 1 : values[1]);
        }
      },
      setSliderValues(values) {
        this.rangeSlider.set(values);
      },
      minValueClickHandler() {
        if (this.minValueEditable) {
          this.editMinValue();
        }
      },
      maxValueClickHandler() {
        if (this.maxValueEditable) {
          this.editMaxValue();
        }
      },
      editMinValue() {
        this.editingMinValue = true;
        this.minInputValue = this.minValue;

        this.$nextTick(() => {
          this.$refs.editMinValueInput.focus();
          this.$refs.editMinValueInput.select();
        });
      },
      editMaxValue() {
        this.editingMaxValue = true;
        this.maxInputValue = this.maxValue;

        this.$nextTick(() => {
          this.$refs.editMaxValueInput.focus();
          this.$refs.editMaxValueInput.select();
        });
      },
      finishMinValueEdit() {
        let newMinValue = parseInt(this.minInputValue);
        this.editingMinValue = false;

        if (!isNaN(newMinValue)) {
          this.setInputValues([newMinValue, null]);
          this.setSliderValues([this.minValue, null]);
          this.emitChange();
        }
      },
      finishMaxValueEdit() {
        let newMaxValue = parseInt(this.maxInputValue);
        this.editingMaxValue = false;

        if (!isNaN(newMaxValue)) {
          this.setInputValues([null, newMaxValue]);
          this.setSliderValues([null, this.maxValue]);
          this.emitChange();
        }
      },
      getValues() {
        return [this.minValue, this.maxValue];
      },
      setValues(values) {
        this.minValue = values[0] ? values[0] : this.minValue;
        this.maxValue = values[1] ? values[1] : this.maxValue;

        this.setSliderValues([this.minValue, this.maxValue]);
      },
      resetValues() {
        this.setValues([this.rangeMin, this.rangeMax]);
      },
      getRange() {
        return [this.rangeMin, this.rangeMax];
      },
      setRange(range) {
        this.rangeMin = range[0] ? range[0] : this.rangeMin;
        this.rangeMax = range[1] ? range[1] : this.rangeMax;

        this.rangeSlider.updateOptions({
          range: {
            min: this.rangeMin,
            max: this.rangeMax,
          },
          start: [
            this.minValue >= this.rangeMin ? this.minValue : this.rangeMin,
            this.maxValue <= this.rangeMax ? this.maxValue : this.rangeMax
          ]
        });
      },
      blurMinValueInput() {
        this.$refs.editMinValueInput.blur();
      },
      blurMaxValueInput() {
        this.$refs.editMaxValueInput.blur()
      }
    },
    computed: {
      readableMinValue() {
        return this.minValueSymbol ? `${this.minValue} ${this.minValueSymbol}` : `${this.minValue}`;
      },
      readableMaxValue() {
        return this.maxValueSymbol ? `${this.maxValue} ${this.maxValueSymbol}` : `${this.maxValue}`;
      }
    }
  };
</script>
