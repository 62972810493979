<template>
  <div
    class="product-box"
  >
    <div
      class="product-box-tiles"
      :class="{'product-box-tiles--hoverable': showOnHover}"
      v-if="!listView"
    >
      <a
        :href="url"
        class="product-box-tiles__main-info"
      >
        <div class="product-box-tiles__slider">
          <div
            class="product-box__images-container"
            ref="imagesContainer"
          >
            <div class="product-box__images-wrapper">
              <lazy-load-image
                class="product-box__image"
                :src="images[0].src"
                :alt="images[0].alt"
                :title="images[0].title"
                :enabled="lazyLoadImages"
                :scaling-width="width"
              />

              <div
                v-if="isCarModelSelectionEnabled && !inSelectedCarModel"
                class="product-box__images-wrapper__in-selected-model-icon"
              />
            </div>
          </div>
          <div
            v-if="thumbnails"
            class="product-box-tiles__image-thumbnails"
          >
            <span
              class="product-box-tiles__image-thumbnail"
              v-for="(thumbnail, index) in thumbnails"
              :key="index"
            />
          </div>
        </div>
        <span class="product-box-tiles__name default-semi-bold">
          {{ name }}
        </span>
        <div class="product-box-tiles__utils">
          <div class="product-box-tiles__prices">
            <span
              v-if="hasAvailableParts && price.isFromPrice"
              class="product-box-tiles__price product-box-tiles__price--promo big-semi-bold"
            >
          {{ $t('global.price.from') }}
        </span>
            <span class="product-box-tiles__price product-box-tiles__price--promo big-semi-bold">
              {{ price.current.displayValue }}
            </span>
          </div>
        </div>
        <div
          v-if="selectedCarModel"
          class="product-box-tiles__utils"
        >
          <div class="product-box-tiles__in-selected-model-text default-semi-bold">
            <span v-if="isCarModelSelectionEnabled && !inSelectedCarModel">{{ $t('global.product.incompatible.model') }}</span>
          </div>
        </div>
      </a>
    </div>
    <div v-else-if="listView">
      <div
        class="product-box-list"
        :class="{'product-box-tiles--hoverable': showOnHover}"
      >
        <csrf-input
          ref="csrf"
        />
        <div class="product-box-list__slider">
          <div
            v-if="labels.length"
            class="product-box-list__labels"
          >
            <span
              v-for="(label, index) in labels"
              class="product-box__label | product-label"
              :class="`product-label--${label.style}`"
              :key="index"
            >
              {{ label.name }}
            </span>
          </div>
          <div
            class="product-box__images-container"
            ref="imagesContainer"
          >
            <a
              class="product-box__images-wrapper"
              :href="url"
            >
              <lazy-load-image
                class="product-box__image"
                :src="images[0].src"
                :alt="images[0].alt"
                :title="images[0].title"
                :enabled="lazyLoadImages"
                :scaling-width="width"
              />
            </a>
          </div>
          <div
            class="product-box-list__variation"
          >
            <div
              class="product-box-list__variation__wrapper"
              v-for="(variation, idx) in variations"
              :key="idx"
            >
              <product-variations
                v-bind="variation"
                ref="productVariation"
                :variations="variation.options"
                :initial-selected-value="variation.selectedOption"
                :index="index"
                :product-detail="false"
                :key="`var-${index}`"
                @changeVariation="productUpdateHandler"
              />
            </div>
          </div>
          <div
            v-if="thumbnails"
            class="product-box-list__image-thumbnails"
          >
            <span
              class="product-box-list__image-thumbnail"
              v-for="(thumbnail, index) in thumbnails"
              :key="index"
            />
          </div>
        </div>
        <div class="product-box-list__main">
          <div
            class="product-box-list__main--info"
          >
            <a
              class="product-box-list__main--name"
              :href="url"
            >
              {{ name }}
            </a>
            <div class="product-box-list__product-details">
              <ul>
                <li
                  v-for="(sellingPoint, index) in sellingPoints"
                  :key="index"
                >
                  {{ sellingPoint }}
                </li>
              </ul>
            </div>
            <div
              class="product-box-list__salebox__button-cart product-box-list__salebox__button-cart__in-cart"
              v-if="isInCart"
            />
          </div>
          <div class="product-box-list__salebox">
            <div class="product-box-list__salebox__prices">
              <span
                class="product-box-list__price product-box-list__salebox__price-current product-box-tiles__price--promo big-semi-bold"
              >
                {{ price.current.displayValue }}
              </span>
            </div>
            <div class="product-box-list__salebox__utils">
              <span class="product-box-list__salebox__availability">
                {{ availabilityText }}
              </span>
              <div class="product-box-list__salebox__wishlist-mobile">
                <a
                  class="product-box-list__salebox__button-wishlist"
                  :aria-label="$t('add.to.favorites.button.label')"
                  :class="[isOnWishlist ? 'product-box-list__salebox__button-wishlist__on-wishlist' : 'product-box-list__salebox__button-wishlist__not-on-wishlist']"
                  @click.prevent="toggleWishlist"
                />
                <a
                  class="product-box-list__salebox__button-to-article"
                  :href="url"
                >
                  {{ $t('global.article.button') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Commented part of a component is swiper implementation for product images.
// In order to make it work please uncomment code and replace selector `product-box-tiles__image-slide` with commented below.

import productVariations from '../productVariations/productVariations.vue'
import LazyLoadImage from '../lazyLoadImage/lazyLoadImage.vue';
import globalStateComputedMixin
  from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';
import CsrfInput from '../csrfInput/csrfInput.vue';
import ProductIDsMixin from "../../utilities/vueMixins/productMixin/productIDsMixin";
import { breakpoints } from "../../variables/variables";
import { imageSizeM, imageSizeMobile } from "../../utilities/js/image/image.js";


export default {
  components: {
    LazyLoadImage,
    CsrfInput,
    productVariations
  },
  mixins: [
    globalStateComputedMixin,
    ProductIDsMixin
  ],
  props: {
    name: String,
    articleNumber: String,
    availablePartsSize: Number,
    inSelectedCarModel: {
      type: Boolean,
      default() {
        return true;
      }
    },
    images: {
      type: Array,
      default() {
        return [];
      }
    },
    price: Object,
    brand: Object,
    url: String,
    labels: {
      type: Array,
      default() {
        return [];
      }
    },
    thumbnails: {
      type: Array,
      default() {
        return [];
      }
    },
    variations: Array,
    lazyLoadImages: {
      type: Boolean,
      default: false
    },
    refreshOn: {
      type: Array,
      default() {
        return [];
      }
    },
    showOnHover: {
      type: Boolean,
      default: false
    },
    availabilityText: String,
    available: Boolean,
    id: String,
    itemId: String,
    productId: String,
    index: Number,
    productUpdatePath: String,
    listView: Boolean,
    subCategory: false,
  },
  computed: {
    isOnWishlist() {
      return this.$store.getters["isOnWishlist"](this.itemId);
    },
    isInCart() {
      return this.$store.getters["isInCart"](this.itemId);
    },
    sellingPoints() {
      let allSellingPoints = this.$store.getters["productsListPage/sellingPoints"](this.index);
      return allSellingPoints.slice(0, 3);
    },
    hasAvailableParts() {
      return this.availablePartsSize > 1;
    },
    width() {
      if(window.innerWidth <= breakpoints.laptop) {
        return imageSizeMobile;
      } else {
        return imageSizeM;
      }
    }
  },
  mounted() {
    // if (this.images.length > 1) {
    //   this.createImagesSlider();
    // }
    //
    // this.refreshOn.forEach(event => {
    //   pigeon.subscribe(event, this.refresh.bind(this));
    // });
  },
  methods: {
    // createImagesSlider() {
    //
    //   this.imagesSlider = new Swiper(this.$refs.imagesContainer, {
    //     wrapperClass: 'product-box-tiles__images-wrapper',
    //     slideClass: 'product-box-tiles__image-slide',
    //     slideActiveClass: 'product-box-tiles__image-slide--active',
    //     navigation: {
    //       prevEl: this.$refs.prevSlideButton,
    //       nextEl: this.$refs.nextSlideButton,
    //       hiddenClass: 'product-box-tiles__slider-button--hidden',
    //       disabledClass: 'product-box-tiles__slider-button--disabled'
    //     }
    //   });
    // },
    // refresh() {
    //   if(this.imagesSlider) {
    //     this.imagesSlider.update(true);
    //   }
    // },
    /*remove from wishlist still needs implementation*/
    removeFromWishlist(evt) {
      evt.preventDefault();
      this.$store.dispatch('wishlistPage/removeSingleProduct', {
        entryId: this.$refs.removeProduct.dataset.entryId
      });
    },
    toggleWishlist() {
      let data = {};
      let url = '/wishlist/' + (this.isOnWishlist ? 'removeItem' : 'addItem');
      url += `/?_csrf=${this.csrfToken.value}&itemId=${this.itemId}&productId=${this.productId}&quantity=1`;

      data["flyoutType"] = 'wishlist';
      data["url"] = url;
      data["boxIndex"] = this.index;
      data["itemId"] = this.itemId;
      let wishlistAction = this.isOnWishlist ? 'removeFromWishlist' : 'addToWishlist';
      this.$store.dispatch('productPage/' + wishlistAction, data);
    },
    productUpdateHandler() {
      this.loaderVisible = true;
      this.$store.dispatch('productsListPage/productChanged', {
        formData: this.getFormData(),
        url: this.productUpdatePath,
        index: this.index
      });
    },
    getFormData() {
      let data = {};

      data[this.$refs.csrf.getName()] = this.$refs.csrf.getValue();
      data["productId"] = this.id;
      data["itemId"] = this.itemId;
      data["quantity"] = 1;
      data["boxIndex"] = this.index;
      return data;
    }
  }
};
</script>
