const mutations = {
  updateOrdersList(state, newList) {
    state.orders = newList
  },
  updateCurrentPage(state, data) {
    state.pagingData.currentPage = data.value;
  },
  updatePagination(state, pagingData) {
    state.pagingData.currentPage = pagingData.currentPage;
    state.pagingData.pagesCount = pagingData.pagesCount;
  },
  updateItemsPerPage(state, data) {
    state.pagingData.currentPage = Math.ceil((((state.pagingData.currentPage - 1) * state.pagingData.entriesPerPage) + 1) / data.value);
    state.pagingData.entriesPerPage = data.value;
  }
};
export default mutations;
