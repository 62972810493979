<template>
  <div
    class="loader"
    v-if="visible"
  />
</template>

<script>
  import pigeon from '../../utilities/js/pigeon/pigeon.js';

  export default {
    props: {
      id: String,
      initialVisible: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        visible: this.initialVisible
      };
    },
    mounted() {
      if (this.id) {
        pigeon.subscribe('loader:show', this.loaderShowEventHandler.bind(this));
        pigeon.subscribe('loader:hide', this.loaderHideEventHandler.bind(this));
      }
    },
    methods: {
      loaderShowEventHandler(data) {
        if (data.id === this.id) {
          this.show();
        }
      },
      loaderHideEventHandler(data) {
        if (data.id === this.id) {
          this.hide();
        }
      },
      show() {
        this.visible = true;
      },
      hide() {
        this.visible = false;
      }
    }
  };
</script>
