const getters = {
  urlParameters(state) {
    const pagingData = [];
    if (state.pagingData) {
      pagingData.push(
          {
            name: state.pagingData.name,
            value: state.pagingData.currentPage
          }
      );
    }

    const itemsPerPage = [];
    if (state.pagingData) {
      pagingData.push(
          {
            name: 'itemsPerPage',
            value: state.pagingData.entriesPerPage
          }
      );
    }

    const parameters = [
      ...pagingData,
      ...itemsPerPage
    ];

    return parameters;
  },
};

export default getters;
