<template>
  <div class="login-page__grid_container">
    <div class="login-page__logo-container">
      <img
        class="login-page__logo"
        v-if="isMobile"
        :src="`${baseAssetsUrl}/staticImages/audi_rings-white.svg`"
        :alt="$t('global.login.headline')"
      >
      <img
        class="login-page__logo"
        v-else
        :src="`${baseAssetsUrl}/staticImages/audi_logo_white_${$i18n.locale}.svg`"
        :alt="$t('global.login.headline')"
      >
    </div>
    <div class="login-page__header-container">
      <h1 class="headline headline--type_h1">
        {{ $t('global.login.headline') }}
      </h1>
      <div
        class="login-form__error"
        v-if="loginError"
        v-html="$t('error.login.general.message')"
      />
      <button
        v-t="'global.login.grp'"
        @click="redirectToGrp"
        class="button button--filled-white login-page__btn_login"
      />
    </div>
    <div class="login-page__image-container">
      <img
        class="login-page__image-content"
        :src="backgroundImageSrc()"
        :alt="$t('global.login.info.headline')"
      >
    </div>
    <div class="login-page__terms">
      <div class="login-page__terms--top">
        <h1 class="headline headline--type_h1">
          {{ $t('global.login.info.headline') }}
        </h1>
      </div>
      <div class="login-page__terms_link">
        <div class="login-page__terms_link_content default-regular">
          <a :href="baseUrl + $t('global.terms.link')">
            {{ $t('global.terms') }}
          </a>
          <a :href="baseUrl + $t('global.imprint.link')">
            {{ $t('global.imprint') }}
          </a>
          <a :href="baseUrl + $t('global.privacy.link')">
            {{ $t('global.privacy') }}
          </a>
          <language-switcher
            :small="!isMobile"
            :without-border="true"
            :inverse="isMobile"
          />
        </div>
        <p class="login-page__copyright default-regular">
          {{ $t('footer_navi_legal.copyright') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import LanguageSwitcher from "../languageSwitcher/languageSwitcher.vue";
import {breakpoints} from "../../variables/variables";

export default {
  mixins: [
    globalStateComputedMixin
  ],
  components: {
    LanguageSwitcher
  },
  props: {
    backgroundImage: {
      type: String,
    },
    grpLoginUrl: {
      type: String,
    },
    loginError: {
      type: Boolean,
    }
  },
  data() {
    return {
      isMobile: window.innerWidth < breakpoints.tablet,
    };
  },
  computed: {},
  created() {
    this.breakpointChangeHandler();
  },
  mounted() {
    window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
  },
  methods: {
    redirectToGrp() {
      window.location.href = this.grpLoginUrl;
    },
    backgroundImageSrc() {
      return this.backgroundImage === '' ?
        `${this.baseAssetsUrl}/staticImages/login_background_fallback.png`
        : this.backgroundImage
    },
    breakpointChangeHandler() {
      this.isMobile = window.innerWidth < breakpoints.tablet;
    }
  }
};
</script>
