<template>
  <div class="counter">
    <input
      class="counter__input"
      :name="name"
      :aria-label="$t('product.input.field.label')"
      v-model="value"
      @keyup="keyUpHandler"
      @blur="blurHandler"
      :disabled="disableInputEdit"
    >
    <button
      href="#"
      class="counter__button counter__button--increase"
      :aria-label="$t('product.increase.button.label')"
      :class="{
        'counter__button--disabled': this.value === this.maxQuantity
      }"
      @click.prevent="increase"
    />
    <button
      href="#"
      class="counter__button counter__button--decrease"
      :aria-label="$t('product.decrease.button.label')"
      :class="{
        'counter__button--disabled': this.value === this.minQuantity
      }"
      @click.prevent="decrease"
    />
  </div>
</template>

<script>
  export default {
    props: {
      initialValue: Number,
      step: {
        type: Number,
        default: 1
      },
      min: Number,
      max: Number,
      name: String,
      predefinedQuantities: Array,
      disableInputEdit: {
        type: Boolean,
        default: true
      }
    },
    emits: ['change'],
    data() {
      return {
        value: 0,
        currentPredefinedQuantityIndex: null,
        minQuantity: this.min,
        maxQuantity: this.max
      };
    },
    mounted() {
      if (this.initialValue) {
        this.value = this.initialValue;
      }

      if (this.predefinedQuantities) {
        this.setPredefinedQuantityIndex();
      }
    },
    methods: {
      setPredefinedQuantityIndex() {
        let quantityIndex;
        this.minQuantity = this.predefinedQuantities[0];
        this.maxQuantity = this.predefinedQuantities[this.predefinedQuantities.length - 1];

        this.predefinedQuantities.map((quantity, index) => {
          if (quantity === this.initialValue) {
            quantityIndex = index;
          }
        });

        if (!quantityIndex) {
          quantityIndex = 0;
          this.value = this.predefinedQuantities[quantityIndex];
        }

        this.currentPredefinedQuantityIndex = quantityIndex;
      },
      increase() {
        if (this.predefinedQuantities) {
          if (this.currentPredefinedQuantityIndex + 1 <= this.predefinedQuantities.length - 1) {
            this.value = this.predefinedQuantities[this.currentPredefinedQuantityIndex + 1];
            this.$emit('change', this.value);
          }
        } else {
          if (this.max) {
            this.value = this.value + this.step > this.max ? this.max : this.value + this.step;
          } else {
            this.value = this.value + this.step;
          }

          this.$emit('change', this.value);
        }
      },
      decrease() {
        if (this.predefinedQuantities) {
          if (this.currentPredefinedQuantityIndex - 1 >= 0) {
            this.value = this.predefinedQuantities[this.currentPredefinedQuantityIndex - 1];
            this.$emit('change', this.value);
          }
        } else {
          if (this.min !== undefined) {
            this.value = this.value - this.step < this.min ? this.min : this.value - this.step;
          } else {
            this.value = this.value - this.step;
          }

          this.$emit('change', this.value);
        }
      },
      keyUpHandler() {
        this.value = this.value.replace(/[^0-9]/g, '');
        this.value = this.value !== '' ? this.value : (this.min ? this.min : 0);

        if (typeof this.value === 'string') {
          this.value = Number(this.value);
        }
      },
      blurHandler() {
        this.$emit('change', this.value);
      },
      getValue() {
        return this.value;
      },
      setValue(newValue) {
        this.value = newValue;
      }
    }
  };
</script>
