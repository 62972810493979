<template>
  <div>
    <a
      :href="link"
      class="search-autosuggest__suggestion"
    >
      <lazy-load-image
        :src="image.path"
        class="search-autosuggest-box__image"
        :scaling-width="width"
      />
      <span class="search-autosuggest-box__name default-semi-bold">
        {{ value }}
      </span>
      <span class="product-box-tiles__price product-box-tiles__price--promo big-semi-bold">
        {{ displayValue }}
      </span>
    </a>
  </div>
</template>

<script>
import LazyLoadImage from '../lazyLoadImage/lazyLoadImage.vue';
import ProductIDsMixin from "../../utilities/vueMixins/productMixin/productIDsMixin";
import { imageSizeXL } from "../../utilities/js/image/image";

export default {
  components: {
    LazyLoadImage
  },
  props: {
    value: String,
    image: Object,
    price: Object,
    link: String,
    displayValue: String
  },
  mixins: [
    ProductIDsMixin
  ],
  computed: {
    width() {
      return imageSizeXL;
    }
  }
};
</script>
