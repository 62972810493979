<template>
  <div class="account-single-order-cart">
    <template
      v-if="asDropdown"
    >
      <button
        class="account-single-order-cart__opener big-semi-bold"
        :class="{
          'account-single-order-cart__opener--open': isOpen
        }"
        @click.prevent="toggleDropdown"
      >
        {{ order.basketName ? order.basketName : $t('account.order.cart') }}
      </button>
      <div class="account-single-order-cart__content-wrapper">
        <transition name="slide">
          <div
            v-show="isOpen"
            class="account-single-order__cart-content"
          >
            <product-order-position
              v-for="product in order.positions"
              v-bind="product"
              :key="product.id"
              :show-gallery="true"
              type="history"
              class="cart-page__content__product account-single-order__product"
            />
          </div>
        </transition>
      </div>
    </template>
    <template v-else>
      <div class="account-single-order-cart__headline big-semi-bold">
        {{ order.basketName ? order.basketName : $t('account.order.cart') }}
      </div>
      <div class="account-single-order-cart__content">
        <product-order-position
          v-for="product in order.positions"
          v-bind="product"
          :key="product.id"
          :show-gallery="true"
          type="history"
          class="cart-page__content__product account-single-order-cart__product"
        />
      </div>
    </template>

  </div>
</template>
<script>

import ProductOrderPosition from "../productOrderPosition/productOrderPosition.vue";

export default {
  components: {
    ProductOrderPosition
  },
  props: {
    order: Object,
    asDropdown: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>