const open = 'data-open';
const animationInProgress = 'data-animation-in-progress';

export function collapseAll(elements) {
  elements
    .filter(dropdown => dropdown.hasAttribute(open))
    .forEach(element => collapseAnimation(element));
}

export function collapseAnimation(element, duration = 300, callback) {
  if (element.dataset.animationInProgress) {
    return;
  }

  const elementContent = element.firstChild;
  const contentHeight = elementContent.offsetHeight;
  const isOpen = element.offsetHeight > 0;
  const currentHeight = element.offsetHeight;
  const targetHeight = isOpen ? 0 : contentHeight;
  const startTime = (new Date()).getTime();

  let progress = currentHeight;
  let finished = false;
  let lastProgress;
  let stopAnimation = false;

  // Taken from: https://codepen.io/branneman/pen/tCdHa with some modifications
  function easeInOutQuart(currentTime, startValue, endValue, animationDuration) {
    const changeInValue = endValue - startValue;
    let step;

    if ((currentTime /= animationDuration / 2) < 1) {
      step = changeInValue / 2 * currentTime * currentTime * currentTime * currentTime + startValue;
    } else {
      step = -changeInValue / 2 * ((currentTime -= 2) * currentTime * currentTime * currentTime - 2) + startValue;
    }

    return step;
  }

  function animate() {
    /* reset animation for removeAllClickHandler from outside the component */
    if (element.style.height === '0px' && element.hasAttribute(open)) {
      element.removeAttribute(open);
      element.removeAttribute(animationInProgress);
      return;
    }

    progress = easeInOutQuart((new Date).getTime() - startTime, currentHeight, targetHeight, duration);

    if (targetHeight === 0 && progress > lastProgress) {
      element.style.height = '0px';
      finished = true;
    } else if (targetHeight > 0 && progress < lastProgress) {
      element.style.height = '';
      finished = true;
    } else {
      element.style.height = `${progress}px`;
    }

    if (finished || stopAnimation) {
      element.removeAttribute(animationInProgress);

      if (isOpen) {
        element.removeAttribute(open);
      } else {
        element.setAttribute(open, '');
      }

      if (callback) {
        callback();
      }
    } else {
      lastProgress = progress;

      setTimeout(() => {
        window.requestAnimationFrame(animate);
      }, 1000/60);
    }
  }

  function stop() {
    stopAnimation = true;
    element.removeAttribute(animationInProgress);
  }

  window.requestAnimationFrame(() => {
    element.setAttribute(animationInProgress, '');
    animate();
  });

  return {
    stop: stop
  }
}

export default {collapseAnimation, collapseAll};
