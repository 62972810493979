import productPage from '../../../services/productPage/productPage.js';
import { toRaw } from "vue";

const actions = {
  productUpdateFromHash(store, data) {
    store.dispatch('productChanged', data);
  },
  productChanged(store, data) {
    productPage()
      .handleGeneralRequest(`${data.url}`, data.formData, 'get')
      .then((updatedData) => store.dispatch('updateProduct', updatedData));
  },
  updateProduct(store, updatedData) {
    store.commit('updateProduct', updatedData);
  },
  addToCart(store, data) {
    return productPage()
        .handleGeneralRequest(data.url, data.formData, 'post')
        .then((res) => {
          if (res === undefined) {
            throw new Error("Could not be added.");
          } else if (typeof res === 'string') {
            throw new Error(res);
          } else {
            return store.dispatch('updateCart', Object.assign(res, {
              'boxIndex': data.boxIndex,
              'itemId': data.itemId,
              'isBundle': data.isBundle
            }))
          }
        })
  },
  removeFromToCart(store, data) {
    productPage()
        .handleGeneralRequest(data.url, data.formData, 'delete')
        .then((res) => {
          return store.dispatch('updateCart', Object.assign(res, {
            'itemId': data.itemId,
            'isBundle': data.isBundle
          }));
        });
  },
  updateCart(store, response) {
    store.commit('updateCartCount', response.cartInput, {root: true});
    store.commit('updateCart', response.cartInput.cart, {root: true});
    store.commit('updateCarts', response.cartInput.carts, {root: true});
    if (response.isBundle) {
      store.commit('updateAvailablePartDummy', response.availablePart);
    } else {
      store.commit('updateAvailablePart', response.availablePart);
    }
  },
  addToWishlist(store, data) {
    productPage()
      .handleGeneralRequest(data.url, data.formData, 'post')
      .then((res) => store.dispatch('updateWishlist', Object.assign(res, {'boxIndex': data.boxIndex, 'itemId': data.itemId, 'isAddedToWishlist': true})));
  },
  removeFromWishlist(store, data) {
    productPage()
      .handleGeneralRequest(data.url, data.formData, 'post')
      .then((res) => store.dispatch('updateWishlist', Object.assign(res, {'boxIndex': data.boxIndex, 'itemId': data.itemId, 'isAddedToWishlist': false})));
  },
  updateWishlist(store, updatedWishlist) {
    store.commit('updateWishlistCount', updatedWishlist, { root: true });
    store.commit('updateOnWishlistState', updatedWishlist, { root: true });
  },
  prevPage(store) {
    if (store.state.productData.pagingData.currentPage > 1) {
      store.commit('updateCurrentPage', {value: store.state.productData.pagingData.currentPage - 1});
      store.dispatch('pageUpdate');
    }
  },
  nextPage(store) {
    if (store.state.productData.pagingData.currentPage < store.state.productData.pagingData.pagesCount) {
      store.commit('updateCurrentPage', {value: store.state.productData.pagingData.currentPage + 1});
      store.dispatch('pageUpdate');
    }
  },
  gotoPage(store, data) {
    if (data.value >= 1 && data.value <= store.state.productData.pagingData.pagesCount) {
      store.commit('updateCurrentPage', {value: data.value});
      store.dispatch('pageUpdate');
    }
  },
  itemsPerPage(store, data) {
    store.commit('updateItemsPerPage', {value: data.value});
    store.dispatch('pageUpdate');
  },
  sortProducts(store, data) {
    store.commit('updateSortValue', {value: data.value});
    store.dispatch('pageUpdate', {resetPage: true});
  },
  initialPageUpdate(store, urlParameters) {
    productPage()
      .getItemsList(urlParameters)
      .then(data => {
        if (data) {
          store.dispatch('updateState', data);
        }
    });
  },
  pageUpdate(store, options = {}) {
    if (options.resetPage) {
      store.commit('updateCurrentPage', {value: 1});
    }

    let urlParameters = store.getters.urlParameters;

    productPage()
      .getItemsList(urlParameters)
      .then(data => {
        if (data) {
          store.dispatch('updateState', data, true);
        }
    });
  },
  updateState(store, data) {
    store.commit('updateProduct', data.state.productData);
    store.commit('updateAvailablePartsList', data.state.availableParts);
    store.commit('updateAvailablePartDummy', data.state.availablePartDummy);
    store.commit('updatePagination', data.state.pagingData);
    store.commit('updateItemsPerPage', {value: data.state.pagingData.entriesPerPage});
    store.commit('updateSortValue', {value: data.state.sortingData.selectedOptionValue});
    store.dispatch('historyPushState', {url: data.baseUrl});
  },
  historyPushState(store, data) {
    window.history.pushState(
        {
          availableParts: toRaw(store.state.productData.availableParts),
          pagingData: toRaw(store.state.productData.pagingData),
          sortingData: toRaw(store.state.productData.sortingData)
        },
        document.title,
        data.url
    );
  },
  historyPopState(store, eventData) {
    store.commit('updateAvailablePartsList', eventData.state.availableParts);
    store.commit('updateAvailablePartDummy', eventData.state.availablePartDummy);
    store.commit('updateCurrentPage', {value: eventData.state.pagingData.currentPage});
    store.commit('updateItemsPerPage', {value: eventData.state.pagingData.entriesPerPage});
    store.commit('updateSortValue', {value: eventData.state.sortingData.selectedOptionValue});
  },
};

export default actions;
