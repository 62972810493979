import checkoutState from './checkoutState.js';
import checkoutMutations from './checkoutMutations.js';
import checkoutGetters from './checkoutGetters.js';
import checkoutActions from './checkoutActions.js';

const store = {
  namespaced: true,
  state: checkoutState,
  mutations: checkoutMutations,
  getters: checkoutGetters,
  actions: checkoutActions
};

export default store;
