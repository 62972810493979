<template>
  <div
    v-if="type === 'orderTable' || type === 'history'"
    class="product-order-position__actions"
  >
    <div
      v-if="type === 'orderTable'"
      class="product-order-position__action product-order-position__action__delete default-semi-bold"
      @click.prevent="removeGlobally()"
    >
      {{ $t('global.article.remove.button') }}
    </div>

    <div
      v-if="isBundle || showAdditionalInfo"
      class="product-order-position__showMore"
      :class="[showMore ? 'show-less' : 'show-more']"
      @click="toggleShowMore"
    >
      <template v-if="!isBundle">
        {{ $t('global.product.damageDescription') }}
      </template>
      <template v-else>
        {{ $t('global.product.bundleParts') }}
        <span class="product-order-position__showMore--counter">
          ({{ bundlePartsCount }})
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    isBundle: Boolean,
    showMore: Boolean,
    showAdditionalInfo: Boolean,
    bundlePartsCount: Number,
  },
  emits: ['toggleShowMore', 'removeGlobally'],
  data() {
    return {}
  },
  methods: {
    toggleShowMore() {
      this.$emit("toggleShowMore")
    },
    removeGlobally() {
      this.$emit("removeGlobally")
    }
  }
};
</script>
