<template>
  <img
    class="audi-rings"
    :src="`${baseAssetsUrl}/staticImages/audi_rings-${color}.svg`"
    :alt="$t('footer.logo.alt')"
    height="50"
    width="145"
    loading="lazy"
  >
</template>
<script>
  import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';

  export default {
    components: {
    },
    mixins: [
      globalStateComputedMixin
    ],
    props: {
      isWhite: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        color: this.isWhite ? 'white' : 'black',
      };
    },
  }
</script>
