import _debounce from 'lodash/debounce';
import JsComponent from '../jsComponent/jsComponent.js';
import { containerWidth, breakpoints } from '../../variables/variables.js';

class ScallableElement extends JsComponent {
  constructor(mainElement, name = 'scalable-element') {
    super(mainElement, name);
    this.createClassNames(
      '--ready'
    );

    this.scaleSet = false;
    this.scaleOnBreakpoints = this.mainElement.dataset.scaleOnBreakpoints ? this.mainElement.dataset.scaleOnBreakpoints.trim().split(',').map(breakpoint => breakpoint.trim()) : ['mobile', 'tablet'];
    this.setScaleOrigin(this.mainElement.dataset.scaleOrigin ? this.mainElement.dataset.scaleOrigin : 'center');
    this.scaleElement();
    this.mainElement.classList.add(this.classNames.ready);
    this.windowResizeHandlerDobunced = _debounce(this.windowResizeHandler.bind(this), 100);
    window.addEventListener('resize', this.windowResizeHandlerDobunced);
  }

  setScaleOrigin(scaleOrigin) {
    if (scaleOrigin === 'center' || scaleOrigin === 'top left' || scaleOrigin === 'bottom left' || scaleOrigin === 'top right' || scaleOrigin === 'bottom right') {
      this.mainElement.style.transformOrigin = scaleOrigin;
    } else {
      this.mainElement.style.transformOrigin = 'center';
    }
  }

  scaleElement() {
    let scaleIt = (window.innerWidth < breakpoints.tablet && this.scaleOnBreakpoints.includes('mobile')) || (window.innerWidth >= breakpoints.tablet && window.innerWidth <= breakpoints.laptop && this.scaleOnBreakpoints.includes('tablet'));

    if (scaleIt) {
      let scale = window.innerWidth / containerWidth;
      scale = scale < 1 ? scale : 1;
      this.mainElement.style.transform = `scale(${scale})`;
      this.scaleSet = true;
    } else if (!scaleIt && this.scaleSet) {
      this.mainElement.style.transform = '';
      this.scaleSet = false;
    }

  }

  windowResizeHandler() {
    this.scaleElement();
  }
}

export default ScallableElement;
