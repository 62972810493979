<template>
  <div class="textarea">
    <label
      class="input__label"
      :class="{
        'input__label--as-placeholder': labelAsPlaceholder,
        'input__label--only-mobile': labelType === 'only-mobile',
        'input__label--required': required
      }"
      v-if="label"
    >
      {{ label }}
    </label>
    <div
      class="textarea__wrapper"
      :class="{
        'textarea__wrapper--focus': focus,
        'textarea__wrapper--error': !!error,
        'textarea__wrapper--valid': (!error && value)
      }"
    >
      <textarea
        class="textarea__field"
        type="textarea"
        :aria-label="innerName"
        :name="innerName"
        :value="modelValue"
        :placeholder="inputPlaceholder"
        @blur="blurHandler"
        @focus="focusHandler"
        :disabled="disabled"
        :autocomplete="autocomplete"
        v-model="modelValue"
      />
    </div>
    <span
      class="textarea__error"
      v-if="!!error"
      v-html="error"
    />
  </div>
</template>

<script>
export default {
    data() {
      return {
        focus: false,
        innerName: '',
        modelValue: this.intiialValue
      };
    },
    props: {
      intiialValue: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      labelType: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ''
      },
      autocomplete: String
    },
    emits: ['update:modelValue', 'blur:modelValue'],
    computed: {
      inputPlaceholder() {
        return this.focus ? '' : (this.placeholder || this.label) + (this.required ? ' *' : '');
      },
      labelAsPlaceholder() {
        return !this.placeholder && !this.focus && !this.modelValue;
      }
    },
    mounted() {
      this.innerName = this.name;
    },
    methods: {
      blurHandler() {
        this.focus = false;
        this.$emit('blur:modelValue');
      },
      focusHandler() {
        this.focus = true;
      },
      getValue() {
        return this.modelValue;
      }
    },
    watch: {
      modelValue(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  };
</script>

