<template>
  <div
    class="account-page account-mobile-menu | user-area"
  >
    <h1 class="user-area__name | headline h1">
      {{ $t('account.overview.headline') }}
    </h1>
    <div
      class="user-area__column"
    >
      <account-sidebar
        @changeLocation="toggleMobileView"
      />
    </div>
  </div>
</template>

<script>
import AccountSidebar from "../accountSidebar/accountSidebar.vue";
import {breakpoints} from "../../variables/variables";
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";

export default {
  components: {
    AccountSidebar
  },
  mixins: [
    globalStateComputedMixin
  ],
  props: {},
  data() {
    return {
      isMobile: window.innerWidth <= breakpoints.tablet,
    };
  },
  created() {
    this.breakpointChangeHandler();
  },
  mounted() {
    window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
  },
  methods: {
    toggleMobileView(href) {
      window.location = href
    },
    breakpointChangeHandler() {
      if (!this.isMobile) {
        window.location = this.loginPageUrl;
      }
    }
  }
};
</script>
