<template>
  <div class="products-sort">
    <select
      class="default-regular products-sort__select"
      :name="sorting.name"
      :aria-label="sorting.name"
      v-model="selectedSortValue"
    >
      <option
        v-for="(option, index) in sorting.options"
        :key="index"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
  import plpStateComputedMixin from '../../utilities/vueMixins/plpStateComputedMixin/plpStateComputedMixin.js';

  export default {
    mixins: [
      plpStateComputedMixin
    ],
    computed: {
      selectedSortValue: {
        get() {
          return this.$store.state.productsListPage.sorting.selectedOptionValue;
        },
        set(value) {
          this.$store.dispatch('productsListPage/sortProducts', {value: value});
        }
      }
    }
  };
</script>
