<template>
  <input
    class="csrf-input"
    type="hidden"
    :name="csrfToken.name"
    :value="csrfToken.value"
  >
</template>

<script>
  import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';

  export default {
    mixins: [
      globalStateComputedMixin
    ],
    methods: {
      getName() {
        return this.csrfToken.name;
      },
      getValue() {
        return this.csrfToken.value;
      }
    }
  };
</script>
