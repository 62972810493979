<template>
  <div
    class="bundle-parts"
  >
    <div
      v-for="(bundlePart, i) in bundleParts"
      :key="`bundle-part-${i}`"
      class="bundle-parts__part"
    >
      <div
        class="bundle-parts__part__image"
      >
        <img
          v-if="!showGallery"
          :src="bundlePart.images[0].src"
          :title="bundlePart.bundlePartName"
          :alt="bundlePart.bundlePartName"
        >
        <part-gallery
          v-if="showGallery"
          :images="bundlePart.images"
          :image-versions="bundlePart.imageVersions"
          :id="bundlePart.sku"
          :init-photo-swipe="showGallery"
        />
      </div>
      <div class="bundle-parts__part__content">
        <div class="bundle-parts__part__content__name default-semi-bold">
          {{ bundlePart.bundlePartName }}
        </div>
        <div class="bundle-parts__part__content__quality">
          <span class="bundle-parts__part__content__quality--key">{{ $t('global.product.quality') }}:</span>
          <span class="bundle-parts__part__content__quality--value">{{ bundlePart.qualityClass }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PartGallery from "../partGallery/partGallery.vue";

export default {
  components: {
    PartGallery
  },
  props: {
    bundleParts: Array,
    showGallery: Boolean
  },
  data() {
    return {}
  },
  methods: {}
};
</script>
