<template>
  <label
    class="checkbox"
    :for="checkboxID"
    :class="{'inverse': inverse}"
  >
    <span class="checkbox__wrapper">
      <Field
        :id="checkboxID"
        :name="name"
        type="checkbox"
        class="checkbox__input"
        :disabled="disabled"
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.checked)"
      />
      <span class="checkbox__box" />
      <span
        class="checkbox__label"
        :class="{
          'checkbox__label--disabled': disabled
        }"
        v-html="label"
      />
      <span
        class="input__error"
        v-if="!!error"
        v-html="error"
      />
    </span>
  </label>

  <ErrorMessage
    v-if="!suppressErrorMessages"
    as="span"
    class="input__error | input-error"
    :name="name"
  />
</template>

<script>
import { ErrorMessage, Field } from "vee-validate";
import randomId from '../../utilities/js/randomId/randomId';

export default {
  components: {
    Field,
    ErrorMessage
  },
  props: {
    checked: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default() {
        return '';
      }
    },
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: null
    },
    inverse: {
      type: Boolean,
      require: false,
      default: false
    },
    suppressErrorMessages: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      checkboxID: this.id ? this.id : randomId(this.checked),
      modelValue: this.checked
    }
  }
};
</script>
