<template>
  <div
    class="available-part-add-to-basket available-parts__buttons"
    :id="`availablePart-${id}`"
  >
    <div
      v-if="availablePart.reservation && !reservedForOther"
      class="available-parts__reservation"
    >
      <div class="available-parts__label">
        {{ $t('reservation.duration.reserved.for', [availablePart.reservation.reservedFor]) }}
      </div>
      <a
        :href="getBasketUrl"
        class="available-parts__button-to-cart available-parts__button-open-cart | button button--filled-default"
        v-html="$t('reservation.duration.open.basket')"
      />
      <button
        class="available-parts__button-cancel-reservation | button button--outlined-default"
        v-t="'reservation.duration.cancel'"
        @click.stop="cancelReservation"
      />
    </div>
    <div
      v-else-if="reservedForOther"
      class="available-parts__reservation"
    >
      <div
        class="available-parts__label"
      >
        {{ $t('reservation.duration.reserved.for.other', [availablePart.reservation.reservedFor]) }}
      </div>
      <button
        class="available-parts__button-to-cart | button button--filled-default"
        v-t="'global.addToBasket'"
        :disabled="true"
      />
    </div>
    <template v-else>
      <button
        class="available-parts__button-to-cart | button button--filled-default"
        v-t="'global.addToBasket'"
        @click.stop="showModal"
      />
    </template>
  </div>
</template>

<script>
import productPageStateComputedMixin from "../../utilities/vueMixins/productPageStateComputedMixin/productPageStateComputedMixin";
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import pigeon from "../../utilities/js/pigeon/pigeon";

export default {
  mixins: [
    productPageStateComputedMixin,
    globalStateComputedMixin
  ],
  props: {
    id: String,
    availablePart: Object,
  },
  data() {
    return {};
  },
  computed: {
    getBasketUrl() {
      if (this.availablePart.reservation.externalBasketId && this.cart.externalId !== this.availablePart.reservation.externalBasketId) {
        return `${this.basketChangeUrl}?externalId=${this.availablePart.reservation.externalBasketId}`;
      }
      return this.basketPageUrl;
    },
    reservedForOther() {
      return this.availablePart.reservation ? this.availablePart.reservation.reservedForOther : false
    }
  },
  methods: {
    showModal() {
      this.$store.dispatch('showModal', {id: this.id, action: 'open'})
    },
    cancelReservation() {
      pigeon.publish(`cancel:reservation:${this.id}`, this.id)
    }
  }
};
</script>
