<template>
  <div
    class="product-detailed-page"
    id="PRODUCT_DETAILS"
  >
    <div class="product-detailed-page__top">
      <div class="product-detailed-page__gallery">
        <product-gallery
          v-if="productDetails.imageVersions.length"
          :product-name="productDetails.product.name"
          index-key="pdpImages__fullscreen"
          id="pdpImages"
        />
        <photo-swipe-gallery
          id="pdpImages__fullscreen"
          :product-name="productDetails.product.name"
          :image-versions="imageVersions"
        />
        <product-flags
          v-if="productFlags.length"
          :product-flags="productFlags"
        />
      </div>
      <div class="product-detailed-page__sale-box">
        <product-info
          :scroll-to-id="'available-parts'"
          @scrollToAvailablePart="productNavigationClickHandler"
        />
      </div>
    </div>
    <slot />
    <div
      class="product-detailed-page__available-parts"
      id="available-parts"
    >
      <available-parts
        :is-bundle="availablePartDummy !== undefined"
      />
    </div>
    <div
      v-if="possibleAlternatives.length"
      id="alternative"
      class="product-detailed-page__carousel"
    >
      <recommendations-teaser
        :headline="$t('global.possible_alternatives')"
        :initial-products="possibleAlternatives"
      />
    </div>
  </div>
</template>
<script>
import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';
import productPageStateComputedMixin
  from '../../utilities/vueMixins/productPageStateComputedMixin/productPageStateComputedMixin.js';
import ScrollToElement from '../../utilities/js/scrollToElement/scrollToElement.js';
import ProductGallery from '../productGallery/productGallery.vue';
import PhotoSwipeGallery from '../photoSwipeGallery/photoSwipeGallery.vue';
import ProductFlags from '../productFlags/productFlags.vue';
import RecommendationsTeaser from '../recommendationsTeaser/recommendationsTeaser.vue';
import ProductInfo from "../productInfo/productInfo.vue";
import AvailableParts from "../availableParts/availableParts.vue";

export default {
  components: {
    ProductGallery,
    PhotoSwipeGallery,
    ProductFlags,
    RecommendationsTeaser,
    ProductInfo,
    AvailableParts
  },
  mixins: [
    translationsMixin,
    productPageStateComputedMixin
  ],
  data() {
    return {
      isHash: ''
    }
  },
  computed: {
    imageVersions() {
      if (!this.availablePartDummy && this.productDetails.imageVersions && this.productDetails.imageVersions.length > 0) {
        return [this.productDetails.imageVersions[0]];
      } else {
        return this.productDetails.imageVersions;
      }
    }
  },
  methods: {
    productNavigationClickHandler(id) {
      const section = this.$el.querySelector(`#${id}`);
      const sectionAccordion = this.$refs[`${id}-accordion`];

      if (sectionAccordion) {
        sectionAccordion.openAccordion();
      }

      ScrollToElement(section);
    }
  }
};
</script>
