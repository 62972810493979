import dateMappings from './dateMappings.js';

const localeDateStringOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};

const ISO = {
  separator: "-",
  format: "yyyy-MM-dd"
};

const DateFormatMixin = {
  computed: {
    datePattern() {
      const dateFormat = dateMappings[this.$i18n.locale];
      return dateFormat ? dateFormat.format : ISO.format;
    },
    dateMask() {
      return this.datePattern.replace(/[dDMmyY]/g, "#");
    }
  },
  methods: {
    toLocaleDateString(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale, localeDateStringOptions);
    },
    toISODateString(inputDate) {
      let tokenizerOutputDate = ISO.separator;
      let tokenizerInputDate = dateMappings[this.$i18n.locale].separator;
      let inputDateArray = inputDate.toString().split(tokenizerInputDate);

      let fromFormat = dateMappings[this.$i18n.locale].format.split(tokenizerInputDate);
      let toFormat = ISO.format.split(tokenizerOutputDate);

      return inputDateArray[fromFormat.indexOf(toFormat[0])] + tokenizerOutputDate +
        inputDateArray[fromFormat.indexOf(toFormat[1])] + tokenizerOutputDate +
        inputDateArray[fromFormat.indexOf(toFormat[2])]
    }
  }
};

export default DateFormatMixin;

