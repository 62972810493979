const cookieMonster = {
  getAll() {
    let cookies = {};

    document.cookie
      .split(';')
      .filter(cookieString => {
        return cookieString !== '';
      })
      .map(cookieString => {
        cookies[cookieString.trim().replace(/^([^=]*)=(.*)/, '$1')] = cookieString.trim().replace(/^([^=]*)=(.*)/, '$2');
    });

    return cookies;
  },
  get(cookieName) {
    return this.getAll()[cookieName];
  },
  set(cookieName, cookieValue, expireDate) {
    document.cookie = `${cookieName}=${cookieValue}${expireDate ? '; expires=' + expireDate + '; path=/' : ''}`;
  },
  eat(cookieName) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
  }
};

export default cookieMonster;
