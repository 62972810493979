const mutations = {
  updateWishlistState(state, newWishlistItems) {
    state.wishlistItems = newWishlistItems;
  },
  removeFromWishlist(state, entryId) {
    const index = state.wishlistItems.findIndex(item => item.entryId === entryId);
    state.wishlistItems.splice(index, 1);
  }
};

export default mutations;
