<template>
  <div
    v-if="show"
  >
    <slot />
  </div>
  <div
    v-if="!show && noAuthorityHasSlotData"
  >
    <slot name="noAuthority" />
  </div>
</template>

<script>
export default {
    components: {},
    props: {
      isAuthenticated: {
        type: Boolean,
        default: () => false
      },
      hasAuthority: {
        type: String,
        default: null
      },
      hasAnyAuthority: {
        type: String,
        default: ""
      },
      hasAllAuthorities: {
        type: String,
        default: ""
      }
    },
    computed: {
      show() {
        return this.authorized() || this.authority() || this.allAuthorities() || this.anyAuthority();
      },
      noAuthorityHasSlotData() {
        return this.$slots.noAuthority;
      }
    },
    methods: {
      authorized() {
        return this.isAuthenticated && this.user.loggedIn;
      },
      anyAuthority() {
        const authorities = this.hasAnyAuthority.split(',').map(value => value.trim());
        return authorities.length && authorities.some(value => this.userAuthorities().indexOf(value) >= 0);
      },
      allAuthorities() {
        const authorities = this.hasAllAuthorities.split(',').map(value => value.trim());
        return authorities.length && authorities.every(value => this.userAuthorities().indexOf(value) > -1);
      },
      authority() {
        return this.hasAuthority && this.userAuthorities().indexOf(this.hasAuthority) > -1;
      },
      userAuthorities() {
        return this.user.authorities.map(value => value.authority);
      }
    }
  };
</script>
