const getters = {
  urlParameters(state) {
    const productId = [];
    if (state.productData.productDetails) {
      productId.push(
          {
            name: 'productId',
            value: state.productData.productDetails.product.id
          }
      );
    }

    const pagingData = [];
    if (state.productData.pagingData) {
      pagingData.push(
          {
            name: state.productData.pagingData.name,
            value: state.productData.pagingData.currentPage
          }
      );
    }

    const itemsPerPage = [];
    if (state.productData.pagingData) {
      pagingData.push(
          {
            name: 'itemsPerPage',
            value: state.productData.pagingData.entriesPerPage
          }
      );
    }

    const sortingData = [];
    if (state.productData.sortingData) {
      sortingData.push(
          {
            name: state.productData.sortingData.name,
            value: state.productData.sortingData.selectedOptionValue
          }
      );
    }

    const parameters = [
      ...productId,
      ...pagingData,
      ...itemsPerPage,
      ...sortingData
    ];

    return parameters;
  },
};

export default getters;
