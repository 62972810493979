function isElementVisibleOnScreen(element) {
  const windowTopPosition = window.pageYOffset;
  const windowBottomPosition = windowTopPosition + window.innerHeight;
  const elementRect = element.getBoundingClientRect();
  const elementTopPosition = elementRect.top + windowTopPosition;
  const elementBottomPosition = elementTopPosition + element.offsetHeight;

  if (elementTopPosition < windowTopPosition) {
    return [false, elementTopPosition];
  } else if (elementBottomPosition > windowBottomPosition) {
    return [false, windowTopPosition + (elementBottomPosition - windowBottomPosition)];
  } else {
    return [true, windowTopPosition];
  }
}

export default isElementVisibleOnScreen;
