import createParametersString from "../../utilities/js/createParametersString/createParametersString";

let service;

class AccountOrders {
  constructor() {
    this.path = window.__globalConfig.apiUrls.account.order;
    this.invoicePdfPath = window.__globalConfig.apiUrls.account.invoicePdf;
    this.accountOrdersPath = window.__globalConfig.apiUrls.account.orders;
    this.baseOrdersUrl = `${window.__initialAppState.global.baseUrl.replace(/\/+$/, '')}/${this.accountOrdersPath.replace(/^\/+/, '')}`;
  }

  getOrdersList(parameters) {
    const createdParameters = createParametersString(parameters);
    const baseUrlWithParameters = `${this.baseOrdersUrl}?${createdParameters}`;
    const requestUrl = `${this.baseOrdersUrl.replace(/\/+$/, '')}/ajax/?${createdParameters}`;
    return fetch(`${requestUrl}`)
      .then(response => {
        return response.json();
      })
      .then(response => {
        if (response) {
          return {
            state: response,
            url: baseUrlWithParameters
          };
        }
      });
  }

  getOrderDetails(orderNumber) {
    let url = `${this.path}${orderNumber}/`;

    return fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
      })
  }

  getInvoicePdf(orderNumber) {
    let url = this.invoicePdfPath.replace("{orderNumber}", orderNumber);

    return fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/pdf'
      }
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        }
        //TODO handle 302 Status
        return null;
      })
  }
}

function accountOrders() {
  if (service) {
    return service;
  } else {
    service = new AccountOrders();
    return service;
  }
}

export default accountOrders;
