<template>
  <a
    class="half-size-teaser"
    :href="link.url"
    :target="link.openInNewWindow ? '_blank' : ''"
  >
    <div
      class="half-size-teaser__image"
      :style="`background-image: url('${image.src}');`"
      :title="image.title"
      :alt="image.alt"
    />
    <div
      v-if="(headline && headline !== '') || (subHeadline && subHeadline !== '')"
      class="half-size-teaser__text | scalable-element"
    >
      <div
        v-if="headline && headline !== ''"
        class="half-size-teaser__headline"
        :class="[
          link.overlay ? `half-size-teaser__headline--color_${link.overlay}` : ''
        ]"
        v-html="headline"
      />
      <div
        v-if="subHeadline && subHeadline !== ''"
        class="half-size-teaser__subheadline"
        :class="[
          link.overlay ? `half-size-teaser__subheadline--color_${link.overlay}` : ''
        ]"
        v-html="subHeadline"
      />
    </div>
  </a>
</template>

<script>
export default {
  props: {
    link: Object,
    image: Object,
    headline: String,
    subHeadline: String,
  }
}
</script>
