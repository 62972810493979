<template>
  <div
    class="page-overlay"
    :class="{
      'page-overlay--visible': isOverlayVisible
    }"
  />
</template>

<script>
  import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';

  export default {
    mixins: [
      globalStateComputedMixin
    ]
  };
</script>
