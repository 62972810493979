<template>
  <div class="account-address-form">
    <template
      v-if="isModal"
    >
      <modal
        :id="modalId"
      >
        <div class="account-address-form__wrapper account-address-form__modal">
          <span class="account-address-form__header">
            {{ header }}
          </span>
          <loader
            class="account-address-form__loader"
            v-if="showLoader"
          />
          <address-form
            class="account-address-form__form"
            :action="action"
            :success-url="successUrl"
            :initial-model="initialModel"
            :allow-packstation="allowPackstation"
          />
        </div>
      </modal>
    </template>
    <template
      v-else
    >
      <div class="account-address-form__wrapper">
        <span class="account-address-form__header">
          {{ header }}
        </span>
        <loader
          class="account-address-form__loader"
          v-if="showLoader"
        />
        <address-form
          class="account-address-form__form"
          :action="action"
          :success-url="successUrl"
          :initial-model="initialModel"
          :allow-packstation="allowPackstation"
        />
      </div>
    </template>
  </div>
</template>

<script>
  import Modal from '../modal/modal.vue';
  import Loader from '../loader/loader.vue';
  import AddressForm from '../addressForm/addressForm.vue';
  import pigeon from '../../utilities/js/pigeon/pigeon.js';

  export default {
    components: {
      Modal,
      Loader,
      AddressForm
    },
    props: {
      header: String,
      modalId: String,
      action: String,
      successUrl: String,
      isModal: {
        type: Boolean,
        default: true
      },
      initialModel: {
        type: Object
      },
      allowPackstation: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        showLoader: true
      }
    },
    mounted() {
      pigeon.subscribe('addressChangeRequest', this.prepareForm.bind(this));
      this.showLoader = false;
    },
    methods: {
      showModal(id) {
        this.$store.dispatch('showModal', {id: id, action: 'open'})
        document.body.classList.add('modal-open')
      },
      prepareForm(data) {
        if(this.isModal && this.modalId === data) {
          this.showModal(data);
        }
      }
    }
  };
</script>
