<template>
  <div
    class="filter-category | sidebar-menu"
    v-if="navigation.tree && navigation.tree.length"
  >
    <ul class="sidebar-menu__list">
      <li
        v-for="(category, index) in navigation.tree"
        class="sidebar-menu__item"
        :key="index"
      >
        <a
          class="sidebar-menu__link"
          :class="{
            'sidebar-menu__link--active': category.selected
          }"
          :href="category.value"
        >
          <span v-text="category.label" />
          <span class="sidebar-menu__link-addition">{{ category.count }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import plpStateComputedMixin from '../../utilities/vueMixins/plpStateComputedMixin/plpStateComputedMixin.js';

  export default {
    mixins: [
      plpStateComputedMixin
    ]
  };
</script>
