<template>
  <div class="social-media">
    <a
      v-for="(item, index) in socialMediaList"
      :key="index"
      class="social-media__link"
      :aria-label="item.name"
      :class="[ item.name ? `social-media__link--${item.name}` : '' ]"
      :href="item.link"
      target="_blank"
      rel="nofollow noopener"
    />
  </div>
</template>

<script>
export default {
  props: {
    socialMediaList: Object,
  }
}
</script>
