const getters = {
  urlParameters(state) {
    const searchParameters = [];
    if (state.search && state.search.query) {
      searchParameters.push(
        {
          name: encodeURIComponent(state.search.name),
          value: encodeURIComponent(state.search.query)
        }
      );
    }
    if (state.ms) {
      searchParameters.push(state.ms);
    }

    const filtersOptions = state.filters ? state.filters
      .filter(filter => filter.type !== 'price' && filter.activeOptions.length)
      .map(filter => {
        return {
          name: filter.name,
          activeOptions: filter.activeOptions
        };
      })
      .reduce((optionsArray, filter) => {
        for (let option of filter.activeOptions) {
          optionsArray.push({
            name: filter.name,
            value: option
          });
        }

        return optionsArray;
      }, []
    ) : [];

    const priceFilter = state.filters ? state.filters.find(filter => filter.type === 'price') : undefined;
    const priceFilterOptions = [];

    if (priceFilter) {
      if (priceFilter.minPriceRange !== priceFilter.minPrice || priceFilter.maxPriceRange !== priceFilter.maxPrice) {
        priceFilterOptions.push(
          {
            name: priceFilter.priceMinInputName,
            value: priceFilter.minPrice
          },
          {
            name: priceFilter.priceMaxInputName,
            value: priceFilter.maxPrice
          }
        );
      }
      if (priceFilter.activeOptions.length) {
        priceFilterOptions.push(
          {
            name: priceFilter.priceOptionsName,
            value: priceFilter.activeOptions[0]
          }
        )
      }
    }

    const pagination = [];
    if (state.pagination) {
      pagination.push(
        {
          name: state.pagination.name,
          value: state.pagination.currentPage
        }
      );
    }

    const sorting = [];
    if (state.sorting) {
      sorting.push(
        {
          name: state.sorting.name,
          value: state.sorting.selectedOptionValue
        }
      );
    }

    const parameters = [
      ...searchParameters,
      ...filtersOptions,
      ...priceFilterOptions,
      ...pagination,
      ...sorting
    ];

    return parameters;
  },
  activeFilters(state) {
    let activeFilters = [];
    if (state.filters) {
      for (let filter of state.filters) {
        if (filter.type === 'price' && filter.selectedRange) {
          activeFilters.push({
            label: filter.selectedRange,
            value: filter.selectedRange,
            name: filter.name
          });
        }
        if (filter.activeOptions.length) {
          const activeFilterOptions = filter.options
            .filter(option => filter.activeOptions.includes(option.value))
            .map(option => {
              return {
                label: option.label,
                value: option.value,
                name: filter.name
            }});
          activeFilters.push(...activeFilterOptions);
        }
      }
    }
    return activeFilters;
  },
  insertion: (state) => (id) => {
    return state.insertions.find(insertion => insertion.id === id);
  },
  teaserTop(state) {
    return state.teaserTop;
  },
  teaserBottom(state) {
    return state.teaserBottom;
  },
  headline(state) {
    return state.headline;
  },
  resultCount(state) {
    return state.resultCount;
  },
  articlesPerRow(state) {
    return state.articlesPerRow;
  },
  sellingPoints: (state) => (index) =>{
    return (state && state.elementsList && state.elementsList[index] && state.elementsList[index].data.sellingPoints) || [];
  },
};

export default getters;
