import globalState from './global/state.js';
import globalMutations from './global/mutations.js';
import globalGetters from './global/getters.js';
import globalActions from './global/actions.js';
import checkoutStore from './modules/checkout/checkoutStore.js';
import productsListPageStore from './modules/productsListPage/productsListPageStore.js';
import productPageStore from './modules/productPage/productPageStore.js';
import cartPageStore from './modules/cartPage/cartPageStore.js';
import wishlistPageStore from './modules/wishlistPage/wishlistPageStore.js';
import accountPageStore from './modules/accountPage/accountPageStore.js';

const store = {
  state: globalState,
  mutations: globalMutations,
  actions: globalActions,
  getters: globalGetters,
  modules: {
    checkout: checkoutStore,
    productsListPage: productsListPageStore,
    accountPage: accountPageStore,
    productPage: productPageStore,
    cartPage: cartPageStore,
    wishlistPage: wishlistPageStore
  }
};

export default store;
