<template>
  <div
    id="PRODUCTS_FILTER_OPTIONS_LIST"
    class="filters"
  >
    <div class="filters__mobile-toggles">
      <button
        class="default-regular filters__open-filters "
        @click.prevent="toggleMobileFilters"
      >
        {{ $t('filters.button') }}
      </button>
      <products-sort class="filters__products-sort" />
    </div>
    <div
      :class="[this.isActiveOnMobile ? 'filters__wrapper--mobile-active' : 'filters__wrapper']"
    >
      <div class="filters__mobile-column">
        <div class="filters__header">
          <h2 class="headline headline--type_h3">
            {{ $t('filters.headline') }}
          </h2>
          <span
            class="filters__mobile-close-button"
            @click="toggleMobileCloseFilters"
          />
        </div>
        <div class="filters__list">
          <div
            v-for="(filter, index) in filters"
            :key="filter.name"
            class="filters__filter"
            v-show="show(filter)"
          >
            <button
              class="filters__filter-toggle default-regular"
              type="button"
              :class="{
                'default-bold': filter.selected,
                'filters__filter-toggle--active': filter.open,
              }"
              @click.prevent="toggleButtonClickHandler(index)"
            >
              {{ filter.label }} {{ showNumberOfSelectedFilters(filter) }}
            </button>
            <div
              class="filters__filter-dropdown"
              ref="filterDropdown"
              :data-index="index"
              :class="{
                'filters__filter-dropdown--price': filter.type === 'price'
              }"
              style="height: 0;"
            >
              <div>
                <template v-if="filter.type === 'price'">
                  <filter-price
                    ref="filterRefs"
                    :name="filter.name"
                  />
                </template>
                <template v-else>
                  <filter-vue
                    ref="filterRefs"
                    :name="filter.name"
                    :show-option-on-count-zero="true"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="this.isActiveOnMobile"
        class="filters__mobile-column"
      >
        <button
          class="button button--outlined-default filters__clear-all-button default-regular"
          @click="removeAllClickHandler"
        >
          {{ $t('productlist.filter.list.reset.button') }}
        </button>

        <div class="filters__mobile-buttons">
          <button
            v-t="'global.cancel.button'"
            class="button button--outlined-default filters__button default-regular"
            @click="toggleMobileCloseFilters"
          />
          <button
            v-t="'filters.apply'"
            class="button button--filled-white-default filters__button default-regular"
            @click="toggleMobileFilters"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plpStateComputedMixin from '../../utilities/vueMixins/plpStateComputedMixin/plpStateComputedMixin.js';
import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';
import { collapseAll, collapseAnimation } from '../../utilities/js/collapseAnimation/collapseAnimation.js';
import FilterVue from '../filter/filter.vue';
import FilterPrice from '../filterPrice/filterPrice.vue';
import ProductsSort from '../productsSort/productsSort.vue';
import { breakpoints } from '../../variables/variables.js';

export default {
  mixins: [
    plpStateComputedMixin,
    translationsMixin
  ],
  components: {
    FilterVue,
    FilterPrice,
    ProductsSort,
  },
  data() {
    return {
      isActiveOnMobile: false,
      updatePageOnChange: {
        type: Boolean,
        default: true
      }
    }
  },
  created() {
    this.$store.subscribe(mutation => {
      if (mutation.type === 'productsListPage/removeActiveFilterOption') {
        this.collapseFilter(mutation.payload.name, mutation.payload.value);
      }
      if (mutation.type === 'productsListPage/removeAllActiveFilterOptions') {
        this.collapseFilters();
      }
    });
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    getFilter(index) {
      return this.$refs.filterDropdown.find((element) => {
        return parseInt(element.getAttribute("data-index")) === index;
      });
    },
    toggleButtonClickHandler(index) {
      const filter = this.filters[index];
      filter['open'] = filter.open !== undefined ? !filter.open : true;
      collapseAnimation(this.getFilter(index));
    },
    toggleMobileFilters() {
      this.isActiveOnMobile = !this.isActiveOnMobile;
    },
    toggleMobileCloseFilters() {
      for (let i = 0; i < this.$refs.filterRefs.length; i++) {
        const filterRef = this.$refs.filterRefs[i];
        if (filterRef) {
          filterRef.cancelFilter();
        }
      }
      this.toggleMobileFilters();
    },
    removeAllClickHandler() {
      this.$store.dispatch('productsListPage/removeAllActiveFilterOptions', {
        updatePageOnChange: this.updatePageOnChange
      });
      this.toggleMobileFilters();
    },
    collapseFilter(name, value) {
      const filtered = this.activeFilters.filter(active => active.name === name);
      const options = filtered.flatMap(filter => filter.activeOptions);
      if (!filtered.length && !options.includes(value)) {
        collapseAnimation(this.$refs.filterDropdown[this.filters.findIndex(filter => filter.name === name)]);
      }
    },
    collapseFilters() {
      collapseAll(this.$refs.filterDropdown);
    },
    show(filter) {
      return filter.type === 'price' || (filter.type !== 'price' &&
        filter.options.filter(option => option.count > 0 || option.selected).length);
    },
    onResize() {
      if ($("input").is(":focus")) {

      } else {
        if (window.innerWidth <= breakpoints.laptop && this.isActiveOnMobile) {
          this.toggleMobileFilters();
        }
      }
    },
    showNumberOfSelectedFilters(filter) {
      return filter.activeOptions.length === 0 ? '' : `(${filter.activeOptions.length})`;
    },
  }
};
</script>
