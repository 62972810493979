import accountOrders from "../../../services/accountOrders/accountOrders";
import { toRaw } from "vue";

const actions = {
  prevPage(store) {
    if (store.state.pagingData.currentPage > 1) {
      store.commit('updateCurrentPage', {value: store.state.pagingData.currentPage - 1});
      store.dispatch('pageUpdate');
    }
  },
  nextPage(store) {
    if (store.state.pagingData.currentPage < store.state.pagingData.pagesCount) {
      store.commit('updateCurrentPage', {value: store.state.pagingData.currentPage + 1});
      store.dispatch('pageUpdate');
    }
  },
  itemsPerPage(store, data) {
    store.commit('updateItemsPerPage', {value: data.value});
    store.dispatch('pageUpdate', {all: data.allOrders});
  },
  pageUpdate(store, options = {}) {
    if (options.resetPage || options.all) {
      store.commit('updateCurrentPage', {value: 1});
    }

    let urlParameters = store.getters.urlParameters;

    accountOrders()
        .getOrdersList(urlParameters)
        .then(data => {
          if (data) {
            store.dispatch('updateState', data, true);
          }
        });
  },
  updateState(store, data) {
    store.commit('updateOrdersList', data.state.orders);
    store.commit('updatePagination', data.state.pagingData);
    store.commit('updateItemsPerPage', {value: data.state.pagingData.entriesPerPage});
    store.commit('updateCurrentPage', {value: data.state.pagingData.currentPage});
    store.dispatch('historyPushState', {url: data.url});
  },
  historyPopState(store, eventData) {
    store.commit('updateOrdersList', eventData.state.orders);
    store.commit('updatePagination', eventData.state.pagingData);
    store.commit('updateCurrentPage', {value: eventData.state.pagingData.currentPage});
    store.commit('updateItemsPerPage', {value: eventData.state.pagingData.entriesPerPage});
  },
  historyPushState(store, data) {
    window.history.pushState(
        {
          orders: toRaw(store.state.orders),
          pagingData: toRaw(store.state.pagingData)
        },
        document.title,
        data.url
    );
  },
};
export default actions;
