<template>
  <div
    class="navigation-slider__wrapper"
  >
    <div
      class="navigation-slider__button big-semi-bold"
      :class="{
        'navigation-slider__button--active' : showLeft && !disableSlider
      }"
      @click.prevent="scrollTo('left')"
    />

    <div
      class="navigation-slider__content-wrapper"
      ref="wrapper"
    >
      <div
        class="navigation-slider__content"
        ref="content"
      >
        <slot
          name="content"
        />
      </div>
    </div>

    <div
      class="navigation-slider__button big-semi-bold right"
      :class="{
        'navigation-slider__button--active' : showRight && !disableSlider
      }"
      @click.prevent="scrollTo('right')"
    />
  </div>
</template>

<script>
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import plpStateComputedMixin from "../../utilities/vueMixins/plpStateComputedMixin/plpStateComputedMixin";

export default {
  mixins: [
    globalStateComputedMixin,
    plpStateComputedMixin,
  ],
  props: {
    elementId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      scrollPosition: 0,
      disableSlider: false,
      showLeft: false,
      showRight: true,
    };
  },
  mounted() {
    this.updateSliders();
    window.addEventListener('resize', this.updateSliders);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateSliders);
  },
  methods: {
    scrollTo(direction) {
      const scrollAmount = Number(200);
      const maxScrollAmount = Math.round(this.$refs.content.getBoundingClientRect().width - this.$refs.wrapper.getBoundingClientRect().width);

      if (direction === 'left') {
        this.scrollPosition -= scrollAmount;
        this.showRight = true;
      } else {
        this.scrollPosition += scrollAmount;
        this.showLeft = true;
      }

      // Crop the scrollPosition to the max allowed amount.
      if (this.scrollPosition > maxScrollAmount) {
        this.scrollPosition = maxScrollAmount;
        this.showRight = false;
      }

      // Crop the scrollPosition to 0 because negative values shouldn't be used.
      if (this.scrollPosition < 0) {
        this.scrollPosition = 0;
        this.showLeft = false;
      }

      this.updateScrollPosition();
    },
    updateScrollPosition() {
      this.$refs.wrapper.scrollTo({
        left: this.scrollPosition,
        behavior: 'smooth',
      });
    },
    updateSliders() {
      let calculatedContentWidth = this.$refs.content.getBoundingClientRect().width;
      let calculatedWrapperWidth = this.$refs.wrapper.getBoundingClientRect().width;
      this.disableSlider = calculatedContentWidth < calculatedWrapperWidth;

      // reset everything, because the scrollposition might not be valid anymore after a resize (e.g. rotate)
      this.scrollPosition = 0;
      this.showLeft = false;
      this.showRight = true;
      this.updateScrollPosition();
    }
  },
};
</script>

