<template>
  <div
    class="active-filters"
    v-if="activeFilters.length"
  >
    <div class="navigation-slider">
      <navigation-slider
        :element-id="`SELECTED_FILTER_MENU`"
      >
        <template #content>
          <ul
            id="SELECTED_FILTER_MENU"
            class="navigation-slider__list"
          >
            <li
              v-for="activeFilter in activeFilters"
              class="active-filters__filter | tag tag--button"
              :key="`${activeFilter.name}-${activeFilter.value}`"
              @click="removeFilter(activeFilter.name, activeFilter.value)"
            >
              {{ $t(activeFilter.name) }}: {{ activeFilter.label }}
              <span
                class="tag__button tag__button--icon_close"
              />
            </li>
          </ul>
        </template>
      </navigation-slider>
    </div>

    <div class="active-filters__selecetd">
      <span
        v-for="activeFilter in activeFilters"
        :key="`${activeFilter.name}-${activeFilter.value}`"
        class="active-filters__filter | tag tag--button"
        @click="removeFilterClickHandler(activeFilter.name, activeFilter.value)"
      >
        {{ $t(activeFilter.name) }}: {{ activeFilter.label }}
        <span
          class="tag__button tag__button--icon_close"
        />
      </span>
      <span
        class="active-filters__remove-all | tag tag--button-left"
        @click="removeAllClickHandler"
      >
        {{ $t('productlist.filter.list.reset.button') }}
      </span>
    </div>
  </div>
</template>

<script>
import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';
import plpStateComputedMixin from '../../utilities/vueMixins/plpStateComputedMixin/plpStateComputedMixin.js';
import NavigationSlider from '../navigationSlider/navigationSlider'

export default {
  mixins: [
    plpStateComputedMixin,
    translationsMixin
  ],
  components: {
    NavigationSlider,
  },
  props: {
    updatePageOnChange: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    removeFilterClickHandler(name, value) {
      this.$store.dispatch('productsListPage/removeActiveFilterOption', {
        name: name,
        value: value,
        updatePageOnChange: this.updatePageOnChange
        });
      },
      removeAllClickHandler() {
        this.$store.dispatch('productsListPage/removeAllActiveFilterOptions', {
          updatePageOnChange: this.updatePageOnChange
        });
      },
      removeFilter(name, value) {
        this.$store.dispatch('productsListPage/removeActiveFilterOption', {
          name: name,
          value: value,
          updatePageOnChange: this.updatePageOnChange
        });
      },
    }
  };
</script>
