import {breakpoints} from '../../../variables/variables.js';

function breakpointChangeEvent() {
  let breakpointsArray = [];
  let currentBreakpoint = null;

  for (let breakpoint in breakpoints) {
    breakpointsArray.push({
      name: breakpoint,
      value: breakpoints[breakpoint]
    });
  }

  breakpointsArray = breakpointsArray.sort((breakpoint1, breakpoint2) => {
    return breakpoint1.value > breakpoint2.value ? -1 : 1;
  });

  function getCurrentBreakpoint() {
    for (let i = 0; i < breakpointsArray.length; i++) {
      if (window.innerWidth <= breakpointsArray[i].value && ((breakpointsArray.length - 1 == i) || (breakpointsArray[i + 1] && window.innerWidth > breakpointsArray[i + 1].value))) {
        return breakpointsArray[i];
      }
    }

    return {
      name: 'widest',
      value: window.innerWidth
    };
  }

  currentBreakpoint = getCurrentBreakpoint();

  window.addEventListener('resize', () => {
    let newBreakpoint = getCurrentBreakpoint();

    if (currentBreakpoint.name !== newBreakpoint.name) {
      let event = new Event('breakpointChange');
      let decreased = newBreakpoint.value < currentBreakpoint.value;

      currentBreakpoint = newBreakpoint;

      event.breakpointName = currentBreakpoint.name;
      event.breakpointValue = currentBreakpoint.value;
      event.breakpointDecreased = decreased;
      event.breakpointIncreased = !decreased;

      window.dispatchEvent(event);
    }
  });
}

export default breakpointChangeEvent;
