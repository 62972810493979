const checkoutStateComputedMixin = {
  computed: {
    breadcrumbs() {
      return this.$store.state.checkout.breadcrumbs
    },
    activeBreadcrumbIndex() {
      return this.$store.state.checkout.activeBreadcrumbIndex;
    },
  }
};

export default checkoutStateComputedMixin;
