<template>
  <div
    class="image-magnify"
    @mouseenter="mouseEnterHandler"
    @mousemove="mouseMoveHandler"
    @mouseleave="mouseLeaveHandler"
  >
    <img
      ref="image"
      class="image-magnify__image"
      :alt="alt"
      :src="imageSrc"
    >
    <div
      ref="magnify"
      class="image-magnify__magnify"
      :style="magnify.style"
    />
    <div
      ref="previewBlock"
      class="image-magnify__preview-wrapper"
      :class="{ 'image-magnify__preview-wrapper--visible': mouse.hover }"
    >
      <loader
        class="image-magnify__loader"
        :class="{
          'image-magnify__loader--visible': loaderVisible
        }"
      />
      <img
        ref="previewImage"
        class="image-magnify__preview"
        :alt="alt"
        :src="mouse.hover ? previewSrc : ''"
        :style="{
          transform: 'translate(' + previewImage.posX + 'px,' + previewImage.posY + 'px)',
          padding: previewImagePadding[0] + 'px ' + previewImagePadding[1] + 'px'
        }"
      >
    </div>
  </div>
</template>

<script>
  import Loader from '../loader/loader.vue';

  export default {
    components: {
      Loader
    },
    props: {
      imageSrc: String,
      previewSrc: String,
      alt: String
    },
    data() {
      return {
        image: {
          top: 0,
          left: 0,
          width: 0,
          height: 0
        },
        mouse: {
          hover: false,
          posX: 0,
          posY: 0
        },
        thumbnail: {
          rect: null,
          topRelative: 0,
          leftRelative: 0
        },
        magnify: {
          rect: null,
          width: 0,
          height: 0,
          posX: 0,
          posY: 0,
          style: {}
        },
        magnifyImage: {
          width: 0,
          posX: 0,
          posY: 0,
          style: {}
        },
        previewImage: {
          rect: null,
          posX: 0,
          posY: 0,
          style: {}
        },
        previewWrapper: {
          rect: null
        },
        previewImageLoaded: false,
        previewImagePadding: [0, 0],
        loaderVisible: false
      };
    },
    mounted() {
      this.windowScrollHandlerBinded = this.windowScrollHandler.bind(this);
      this.$refs.previewImage.addEventListener('load', this.imageLoadHandler.bind(this));
    },
    methods: {
      imageLoadHandler() {
        this.loaderVisible = false;
        this.calculateImageRect();
        this.previewImageLoaded = true;
      },
      calculateImageRect() {
        this.previewImage.rect = this.$refs.previewImage.getBoundingClientRect();
      },
      mouseEnterHandler() {
        this.loaderVisible = true;
        this.mouse.hover = true;
        this.mainElementRect = this.$el.getBoundingClientRect();
        this.thumbnail.rect = this.$refs.image.getBoundingClientRect();
        this.thumbnail.leftRelative = this.thumbnail.rect.left - this.mainElementRect.left;
        this.thumbnail.topRelative = this.thumbnail.rect.top - this.mainElementRect.top;
        this.previewWrapper.rect = this.$refs.previewBlock.getBoundingClientRect();
        this.magnifyImage.width = this.thumbnail.rect.width;
        this.magnifyImage.style.width = `${this.magnifyImage.width}px`;
        this.magnifyImage.style.padding = `${this.thumbnail.topRelative}px ${this.thumbnail.leftRelative}px`;
        window.addEventListener('scroll', this.windowScrollHandlerBinded);
      },
      calculatePreviewImagePadding() {
        this.previewImagePadding = [
          (this.thumbnail.topRelative/this.thumbnail.rect.height) * this.previewImage.rect.height,
          (this.thumbnail.leftRelative/this.thumbnail.rect.width) * this.previewImage.rect.width,
        ];
      },
      mouseMoveHandler(event) {
        if (this.previewImageLoaded) {
          this.calculateImageRect();
          this.calculatePreviewImagePadding();
          this.calculateMousePosition(event);
          this.calculateMagnifySize();
          this.calculateMagnifyPosition();
          this.calculatePreviewImagePosition();
        }
      },
      mouseLeaveHandler() {
        this.previewImageLoaded = false;
        this.mouse.hover = false;
        window.removeEventListener('scroll', this.windowScrollHandlerBinded);
      },
      calculateMousePosition(event) {
        this.mouse.posX = event.clientX - this.mainElementRect.left;
        this.mouse.posY = event.clientY - this.mainElementRect.top;
      },
      calculateMagnifySize() {
        this.magnify.width = Math.floor(( this.previewWrapper.rect.width / this.previewImage.rect.width ) * this.mainElementRect.width);
        this.magnify.height = Math.floor(( this.previewWrapper.rect.height / this.previewImage.rect.height ) * this.mainElementRect.height);

        this.magnify.style.width = `${this.magnify.width}px`;
        this.magnify.style.height = `${this.magnify.height}px`;

        this.magnify.rect = this.$refs.magnify.getBoundingClientRect();
      },
      calculateMagnifyPosition() {
        const maxX = this.$el.offsetWidth - this.magnify.rect.width;
        const maxY = this.$el.offsetHeight - this.magnify.rect.height;
        const minX = 0;
        const minY = 0;

        let posX = this.mouse.posX - this.magnify.rect.width / 2;
        let posY = this.mouse.posY - this.magnify.rect.height / 2;

        posX = posX < minX ? 0 + minX : posX;
        posX = posX + this.magnify.rect.width > this.$el.offsetWidth ? maxX : posX;

        posY = posY < minY ? minY : posY;
        posY = posY + this.magnify.rect.height > this.$el.offsetHeight ? maxY : posY;

        this.magnify.posX = posX;
        this.magnify.posY = posY;
        this.magnifyImage.posX = -posX - 1;
        this.magnifyImage.posY = -posY - 1;
        this.magnify.style.transform = `translate(${this.magnify.posX}px, ${this.magnify.posY}px)`;
        this.magnifyImage.style.transform = `translate(${this.magnifyImage.posX}px, ${this.magnifyImage.posY}px)`;
      },
      calculatePreviewImagePosition() {
        const maxX = this.previewWrapper.rect.width - this.previewImage.rect.width;
        const maxY = this.previewWrapper.rect.height - this.previewImage.rect.height;

        let posX = -( this.magnify.posX / this.mainElementRect.width ) * this.previewImage.rect.width;
        let posY = -( this.magnify.posY / this.mainElementRect.height ) * this.previewImage.rect.height;

        posX = posX > 0 ? 0 : posX;
        posX = posX < maxX ? maxX : posX;

        posY = posY > 0 ? 0 : posY;
        posY = posY < maxY ? maxY : posY;

        this.previewImage.posX = posX;
        this.previewImage.posY = posY;
      },
      windowScrollHandler() {
        if (this.previewImageLoaded) {
          this.previewImage.rect = this.$refs.previewImage.getBoundingClientRect();
          this.thumbnail.rect = this.$refs.image.getBoundingClientRect();
          this.previewWrapper.rect = this.$refs.previewBlock.getBoundingClientRect();
        }
      }
    }
  };
</script>


<!-- <template>
  <div
    class="image-magnify"
    @mouseenter="mouseEnterHandler"
    @mousemove="mouseMoveHandler"
    @mouseleave="mouseLeaveHandler"
  >
    <img
      ref="image"
      class="image-magnify__image"
      :class="{
        'image-magnify__image--auto-center': autoCenter
      }"
      :alt="alt"
      :src="imageSrc"
    >
    <div
      ref="magnify"
      class="image-magnify__magnify"
      :class="{ 'image-magnify__magnify--visible': mouse.hover }"
      :style="magnify.style"
    >
      <img
        class="image-magnify__magnify-background"
        :alt="alt"
        :src="imageSrc"
        :style="magnifyImage.style"
      >
    </div>
    <div
      ref="previewBlock"
      class="image-magnify__preview-wrapper"
      :class="{ 'image-magnify__preview-wrapper--visible': mouse.hover }"
      @mouseenter="hidePreview"
    >
      <loader-vue
        class="image-magnify__loader"
        :class="{
          'image-magnify__loader--visible': loaderVisible
        }"
      />
      <img
        ref="previewImage"
        class="image-magnify__preview"
        :alt="alt"
        :src="mouse.hover ? previewSrc : ''"
        :style="{
          transform: 'translate(' + previewImage.posX + 'px,' + previewImage.posY + 'px)',
          padding: previewImagePadding[0] + 'px ' + previewImagePadding[1] + 'px'
        }"
      >
    </div>
  </div>
</template>

<script>
  import LoaderVue from '../loader/loader.vue';

  const ImageMagnify = {
    name: 'image-magnify',
    components: {
      LoaderVue
    },
    props: {
      imageSrc: String,
      previewSrc: String,
      alt: String,
      autoCenter: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        image: {
          top: 0,
          left: 0,
          width: 0,
          height: 0
        },
        mouse: {
          hover: false,
          posX: 0,
          posY: 0
        },
        thumbnail: {
          rect: null,
          topRelative: 0,
          leftRelative: 0
        },
        magnify: {
          rect: null,
          width: 0,
          height: 0,
          posX: 0,
          posY: 0,
          style: {}
        },
        magnifyImage: {
          width: 0,
          posX: 0,
          posY: 0,
          style: {}
        },
        previewImage: {
          rect: null,
          posX: 0,
          posY: 0,
          style: {}
        },
        previewWrapper: {
          rect: null
        },
        loaderVisible: false,
        previewImageLoaded: false,
        previewImagePadding: [0, 0]
      };
    },
    mounted() {
      this.windowScrollHandlerBinded = this.windowScrollHandler.bind(this);
      this.$refs.previewImage.addEventListener('load', this.imageLoadHandler.bind(this));
    },
    methods: {
      imageLoadHandler() {
        this.loaderVisible = false;
        this.calculateImageRect();
        this.previewImageLoaded = true;
      },
      calculateImageRect() {
        this.previewImage.rect = this.$refs.previewImage.getBoundingClientRect();
      },
      mouseEnterHandler() {
        this.loaderVisible = true;
        this.mouse.hover = true;
        this.mainElementRect = this.$el.getBoundingClientRect();
        this.thumbnail.rect = this.$refs.image.getBoundingClientRect();
        this.thumbnail.leftRelative = this.thumbnail.rect.left - this.mainElementRect.left;
        this.thumbnail.topRelative = this.thumbnail.rect.top - this.mainElementRect.top;
        this.previewWrapper.rect = this.$refs.previewBlock.getBoundingClientRect();
        this.magnifyImage.width = this.thumbnail.rect.width;
        this.magnifyImage.style.width = `${this.magnifyImage.width}px`;
        this.magnifyImage.style.padding = `${this.thumbnail.topRelative}px ${this.thumbnail.leftRelative}px`;
        window.addEventListener('scroll', this.windowScrollHandlerBinded);
      },
      calculatePreviewImagePadding() {
        this.previewImagePadding = [
          (this.thumbnail.topRelative/this.thumbnail.rect.height) * this.previewImage.rect.height,
          (this.thumbnail.leftRelative/this.thumbnail.rect.width) * this.previewImage.rect.width,
        ];
      },
      mouseMoveHandler(event) {
        if (this.previewImageLoaded) {
          this.calculateImageRect();
          this.calculatePreviewImagePadding();
          this.calculateMousePosition(event);
          this.calculateMagnifySize();
          this.calculateMagnifyPosition();
          this.calculatePreviewImagePosition();
        }
      },
      mouseLeaveHandler() {
        this.previewImageLoaded = false;
        this.mouse.hover = false;
        window.removeEventListener('scroll', this.windowScrollHandlerBinded);
      },
      calculateMousePosition(event) {
        this.mouse.posX = event.clientX - this.mainElementRect.left;
        this.mouse.posY = event.clientY - this.mainElementRect.top;
      },
      hidePreview() {
        this.previewImageLoaded = false;
        this.mouse.hover = false;
        window.removeEventListener('scroll', this.windowScrollHandlerBinded);
      },
      calculateMagnifySize() {
        this.magnify.width = Math.floor(( this.previewWrapper.rect.width / this.previewImage.rect.width ) * this.mainElementRect.width);
        this.magnify.height = Math.floor(( this.previewWrapper.rect.height / this.previewImage.rect.height ) * this.mainElementRect.height);

        this.magnify.style.width = `${this.magnify.width}px`;
        this.magnify.style.height = `${this.magnify.height}px`;

        this.magnify.rect = this.$refs.magnify.getBoundingClientRect();
      },
      calculateMagnifyPosition() {
        const maxX = this.$el.offsetWidth - this.magnify.rect.width;
        const maxY = this.$el.offsetHeight - this.magnify.rect.height;
        const minX = 0;
        const minY = 0;

        let posX = this.mouse.posX - this.magnify.rect.width / 2;
        let posY = this.mouse.posY - this.magnify.rect.height / 2;

        posX = posX < minX ? 0 + minX : posX;
        posX = posX + this.magnify.rect.width > this.$el.offsetWidth ? maxX : posX;

        posY = posY < minY ? minY : posY;
        posY = posY + this.magnify.rect.height > this.$el.offsetHeight ? maxY : posY;

        this.magnify.posX = posX;
        this.magnify.posY = posY;
        this.magnifyImage.posX = -posX - 1;
        this.magnifyImage.posY = -posY - 1;
        this.magnify.style.transform = `translate(${this.magnify.posX}px, ${this.magnify.posY}px)`;
        this.magnifyImage.style.transform = `translate(${this.magnifyImage.posX}px, ${this.magnifyImage.posY}px)`;
      },
      calculatePreviewImagePosition() {
        const maxX = this.previewWrapper.rect.width - this.previewImage.rect.width;
        const maxY = this.previewWrapper.rect.height - this.previewImage.rect.height;

        let posX = -( this.magnify.posX / this.mainElementRect.width ) * this.previewImage.rect.width;
        let posY = -( this.magnify.posY / this.mainElementRect.height ) * this.previewImage.rect.height;

        posX = posX > 0 ? 0 : posX;
        posX = posX < maxX ? maxX : posX;

        posY = posY > 0 ? 0 : posY;
        posY = posY < maxY ? maxY : posY;

        this.previewImage.posX = posX;
        this.previewImage.posY = posY;
      },
      windowScrollHandler() {
        if (this.previewImageLoaded) {
          this.previewImage.rect = this.$refs.previewImage.getBoundingClientRect();
          this.thumbnail.rect = this.$refs.image.getBoundingClientRect();
          this.previewWrapper.rect = this.$refs.previewBlock.getBoundingClientRect();
        }
      }
    }
  };

  export default ImageMagnify;
</script> -->
