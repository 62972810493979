<template>
  <div class="header__meta-menu header-meta-content">
    <div class="header-meta-content__contact">
      <a
        :href="$t('phoneNumber')"
        class="header-meta-content__phone"
      >
        {{ $t('phoneLabelNumber') }}
      </a>
      <span class="header-meta-content__phone-info">
        {{ $t('phoneInfo') }}
      </span>
    </div>
    <div class="header-meta-content__links">
      <a
        href="#"
        class="header-meta-content__link"
      >
        {{ $t('helpLabel') }}
      </a>
      <a
        id="SERVICE_AND_CONSULTING_BTN"
        href="#"
        class="header-meta-content__link"
      >
        {{ $t('serviceLabel') }}
      </a>
      <a
        href="#"
        class="header-meta-content__link"
      >
        {{ $t('aboutUsLabel') }}
      </a>
      <a
        href="/contact"
        class="header-meta-content__link"
      >
        {{ $t('phoneLabel') }}
      </a>
      <language-switcher />
    </div>
  </div>
</template>

<script>
  import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';
  import LanguageSwitcher from "../languageSwitcher/languageSwitcher";

  export default {
    components: {
      LanguageSwitcher
    },
    mixins: [
      translationsMixin
    ]
  };
</script>
