<template>
  <div class="footer-teaser">
    <div
      v-for="(footerTab, tabIndex) in footerTabs"
      :key="tabIndex"
      class="footer-teaser__column"
    >
      <accordion
        class="footer-teaser__accordion"
        :mobile-only="true"
        :name="footerTab.name"
      >
        <template #content>
          <ul
            class="footer-teaser__list"
          >
            <li
              class="footer-teaser__list-item"
              v-for="(footerTabLink, linkIndex) in footerTab.links"
              :key="linkIndex"
            >
              <a
                class="footer-teaser__link default-regular"
                :href="footerTabLink.link"
                :title="footerTabLink.title"
              >
                {{ footerTabLink.name }}
              </a>
            </li>
          </ul>
        </template>
      </accordion>
    </div>
  </div>
</template>
<script>
import Accordion from '../accordion/accordion.vue';

export default {
    components: {
      Accordion
    },
    props: {
      id: String,
      tabs: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        footerTabs: this.tabs
      };
    }
  };
</script>
