<template>
  <nav class="navigation">
    <select-car-model-flyout
      :id="`select-car-model-flyout`"
      @show-car-model-flyout="checkShowModal"
    />
    <navigation-slider
      :element-id="`MAIN_NAVIGATION_MENU`"
    >
      <template #content>
        <ul
          id="MAIN_NAVIGATION_MENU"
          class="navigation-slider__list"
        >
          <li
            v-for="(category, index) in shopCategoriesTree"
            class="navigation__element"
            :class="{ navigation__element__selected: category.active, navigation__element__disabled: (category.entryEmpty) }"
            :key="`category_${index}`"
          >
            <a
              v-if="!category.entryEmpty"
              :href="category.url"
              class="navigation__link"
            >
              {{ category.name }}
            </a>
            <span
              v-if="category.entryEmpty"
              class="navigation__link"
            >
              {{ category.name }}
            </span>
          </li>
        </ul>
      </template>
    </navigation-slider>
  </nav>
</template>

<script>
import globalStateComputedMixin
  from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';
import NavigationSlider from '../navigationSlider/navigationSlider.vue';
import SelectCarModelFlyout from '../selectCarModelFlyout/selectCarModelFlyout.vue';

export default {
  mixins: [
    globalStateComputedMixin
  ],
  components: {
    NavigationSlider,
    SelectCarModelFlyout
  },
  emits: ['show-child-modal'],
  methods: {
    checkShowModal(showModal) {
      this.$emit('show-child-modal', showModal);
    }
  }
};
</script>
