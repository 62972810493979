<template>
  <div class="error-page__content">
    <h1 class="error-page__headline h2">
      {{ $t('global.error.pageNotFound.headline') }}
    </h1>
    <p
      v-if="!!requestUrl"
      class="error-page__text default-regular"
    >
      {{ $t('global.error_404.description.part_1') }}
      <strong v-text="requestUrl" />
      {{ $t('global.error_404.description.part_2') }}
    </p>
    <p
      v-else
      class="error-page__text default-regular"
    >
      {{ $t('global.error_404.description.default') }}
    </p>
    <p class="error-page__text default-regular">
      {{ $t('global.error_404.description_2') }}
    </p>

    <div class="error-page__button-group">
      <a
        class="button button--filled-default error-page__button"
        href="/"
        v-t="'global.error.backToStartpage'"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    requestUrl: String,
  }
};
</script>
