<template>
  <div class="account-personal-data-form">
    <div class="account-personal-data-form__wrapper">
      <span class="account-personal-data-form__sub-headline">{{ index + 1 }}. {{ $t('account.address') }}</span>
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.country.label') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.country"
          :disabled="!editable"
        >
      </div>
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.city.label') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.city"
          :disabled="!editable"
        >
      </div>
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.street.label') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.street"
          :disabled="!editable"
        >
      </div>
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.street_number.label') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.streetNumber"
          :disabled="!editable"
        >
      </div>
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.addressAddition.label') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.addressAddition"
          :disabled="!editable"
        >
      </div>
      <div class="account-personal-data-form__wrapper_input">
        <span
          :class="editable ? 'account-personal-data-form__wrapper_input--headline':'account-personal-data-form__wrapper_input--headline--disabled'"
        >
          {{ $t('global.zipcode.label') }}
        </span>
        <input
          class="account-personal-data-form__wrapper_input--field"
          type="text"
          :value="address.zipCode"
          :disabled="!editable"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editable: false,
    }
  },
  props: {
    address: Object,
    index: Number
  }
}
</script>
