const accountPageStateComputedMixin = {
  computed: {
    orders() {
      return this.$store.state.accountPage.orders
    },
    pagingData() {
      return this.$store.state.accountPage.pagingData;
    },
    totalOrdersSize() {
      return this.$store.state.accountPage.totalOrdersSize
    }
  }
}

export default accountPageStateComputedMixin;
