<template>
  <div
    class="product-price product-info__wrapper--price"
  >
    <div
      class="product-info__price product-info__price--base bigger-regular"
      :class="{'sale-box__price--old': productDetails.product.price.isRetailPrice || productDetails.product.price.isOldPrice}"
    >
      <template v-if="user.showVat">
        <div v-if="productDetails.product.price.isRetailPrice">
          {{ productDetails.product.price.rrp.displayValue }}
        </div>
        <div v-else-if="productDetails.product.price.isOldPrice">
          {{ productDetails.product.price.old.displayValue }}
        </div>
        <div v-else>
          {{ productDetails.product.price.current.displayValue }}
          <span
            v-if="!productDetails.product.bundleCode && availablePartsSize > 1 && productDetails.product.price.isFromPrice">
            - {{ productDetails.product.price.max.displayValue }}</span>
        </div>
      </template>
      <template v-else>
        <div v-if="productDetails.product.price.isRetailPrice">
          {{ withoutVatAndFormatted(productDetails.product.price.rrp) }}
        </div>
        <div v-else-if="productDetails.product.price.isOldPrice">
          {{ withoutVatAndFormatted(productDetails.product.price.old) }}
        </div>
        <div v-else>
          {{ withoutVatAndFormatted(productDetails.product.price.current) }}
          <span
            v-if="!productDetails.product.bundleCode && availablePartsSize > 1 && productDetails.product.price.isFromPrice">
            - {{ withoutVatAndFormatted(productDetails.product.price.max) }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import productPageStateComputedMixin
  from "../../utilities/vueMixins/productPageStateComputedMixin/productPageStateComputedMixin";
import globalStateComputedMixin
  from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";

export default {
  mixins: [
    productPageStateComputedMixin,
    globalStateComputedMixin,
  ],
  props: {},
  data() {
    return {};
  },
  methods: {
    withoutVatAndFormatted(price) {
      let result = this.withoutVat(price);
      return this.formattedPrice(result, this.productDetails.product.price.current.currency);
    },
    withoutVat(price) {
      let total = price.value;
      let divisor = (this.productDetails.product.price.vatPercentage / 100) + 1;
      let subtrahend = total / divisor;
      let vat = total - subtrahend;
      return total - vat;
    }
  }
};
</script>
