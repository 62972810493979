<template>
  <div
    class="product-additional-information"
    id="shipping"
  >
    <accordion
      ref="accordion"
      class="product-additional-information__accordion"
      :is-content-html="true"
      :id="id"
      :name="$t('additionalInformationName')"
    >
      <div
        slot="content"
        class="product-additional-information__content | html-teaser"
      >
        <span class="product-additional-information__info">{{ $t('info') }}</span>
        <span class="product-additional-information__info">{{ $t('info2') }}</span>
      </div>
    </accordion>
  </div>
</template>
<script>
  import translationsMixin from '../../utilities/vueMixins/translationsMixin/translationsMixin.js';
  import Accordion from '../accordion/accordion.vue';

  export default {
    components: {
      Accordion
    },
    mixins: [
      translationsMixin
    ],
    props: {
      id: String
    },
    methods: {
      openAccordion() {
        this.$refs.accordion.open();
      }
    }
  }
</script>
