<template>
  <div class="captcha">
    <div
      class="captcha__input-wrapper"
      :class="{
        'captcha__input-wrapper--error': error
      }"
    >
      <img
        :src="image"
        :id="imageId"
        alt="captcha"
        class="captcha__image"
      >
      <input
        class="captcha__input"
        :class="[
          {'captcha__input--error': error},
          {'captcha__input--success': valid && error != null}
        ]"
        type="text"
        v-model="value"
        :placeholder="placeholder"
        :name="name"
        @blur="blurHandler"
        @keyup="keyupHandler"
      >
      <span
        v-if="reload"
        class="captcha__captcha-reload"
        :class="{'captcha__captcha-reload--rotate' : rotate}"
        @click="handleCaptchaReload"
      />
    </div>
    <span
      class="captcha__error"
      v-if="error"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>
  import pigeon from '../../utilities/js/pigeon/pigeon.js';

  export default {
    props: {
      captchaImg: String,
      name: String,
      placeholder: String,
      imageId: String,
      requiredError: String,
      initError: String,
      initValue: String,
      initValid: Boolean,
      reload: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        image: this.captchaImg,
        rotate: null,
        value: this.initValue,
        error: this.initError,
        valid: this.initValid
      };
    },
    mounted() {
      pigeon.subscribe('captcha:reset', this.reset);
    },
    methods: {
      blurHandler() {
        this.validate();
      },
      keyupHandler() {
        this.validate();
      },
      validate() {
        let valid = !!this.value.trim('');
        this.error = valid ? null : this.requiredError;
        this.valid = valid;
        return valid;
      },
      reloadImage() {
        this.image = `${this.captchaImg}?${(new Date()).getTime()}`;
      },
      updateCaptchaData(captchaValues) {
        this.error = captchaValues.captchaErrorText;
        this.value = captchaValues.inputCaptchaText;
        this.valid = !captchaValues.captchaErrorText;
      },
      reset() {
        this.error = null;
        this.value = '';
        this.reloadImage();
      },
      handleCaptchaReload() {
        this.rotate = !this.rotate;
        this.reloadImage();
      }
    }
  };
</script>
