let service;

class SearchSuggestions {
  constructor() {
    this.apiUrls = {
      searchForQuery: window.__globalConfig.apiUrls.searchSuggestions.searchForQuery
    }
  }

  searchForQuery(query) {
    return fetch(`${this.apiUrls.searchForQuery}?q=${query}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        return this.mapResponseData(data);
      })
  }

  mapResponseData(data) {
    const newData = [];

    for (let type in data.suggestions) {
      newData.push({
        type: type,
        suggestions: data.suggestions[type]
      });
    }

    return newData.filter(result => !!result.suggestions.length);
  }
}

function searchSuggestions() {
  if (service) {
    return service;
  } else {
    service = new SearchSuggestions();
    return service;
  }
}

export default searchSuggestions;
