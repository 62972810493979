import backendRequestService from "../backendRequest/backendRequest";

let service;

class Wishlist {
  constructor() {}

  removeSingleProduct(requestData) {
    requestData['title'] = document.title;
    return backendRequestService().postBackendRequest('/wishlist/remove/', requestData)
      .then((response) => {
        if(response.status === 200 || response.ok) {
          return requestData.entryId;
        }
      });
  }

  addSingleProductToCart(productData) {
    productData['title'] = document.title;
    return backendRequestService().postBackendRequest('/wishlist/basket/add/', productData)
      .then((response) => {
        if(response.status === 200 || response.ok) {
          return response.json()
        }
    })
  }

  addAllToCart(url, data) {
    data['title'] = document.title;
    return backendRequestService()
      .postBackendRequest(url, data)
      .then((response) => {
        if(response.status === 200 || response.ok) {
          return response.json()
        }
    })
  }

}

function wishlist() {
  if (service) {
    return service;
  } else {
    service = new Wishlist();
    return service;
  }
}

export default wishlist;
