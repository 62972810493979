import translationsMap from './translations.map.js';

function generateTranslations(messages) {
  const componentsTranslations = {};

  for (let component in translationsMap) {
    componentsTranslations[component] = {};

    for (let translation in translationsMap[component]) {
      componentsTranslations[component][translation] = messages[translationsMap[component][translation]];
    }
  }

  return Object.assign(messages, componentsTranslations);
}

export default generateTranslations;
