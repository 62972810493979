<template>
  <div
    class="product-order"
    :id="number"
  >
    <div
      v-if="!deleting"
      class="product-order-position"
    >
      <div
        v-if="!isMobile"
        class="product-order-position__cell product-order-position__cell__image"
      >
        <a
          v-if="!showGallery || isBundle"
          :href="url"
        >
          <img
            v-on="!url ? { click: toggleShowMore } : {}"
            class="product-order-position__image"
            :src="imageURL(images[0].src, imageSizeM())"
            :title="images[0].title"
            :alt="images[0].alt"
          >
        </a>
        <part-gallery
          v-if="showGallery && !isBundle"
          :id="`part-gallery-${number}`"
          :images="images"
          :image-versions="imageVersions"
        />
      </div>
      <div
        class="product-order-position__cell product-order-position__cell__info"
      >
        <div
          class="product-order-position__description product-order-position__wrapper"
        >
          <div
            v-if="isMobile"
            class="product-order-position__wrapper__image"
          >
            <img
              v-if="!showGallery || isBundle"
              v-on="!url ? { click: toggleShowMore } : {}"
              class="product-order-position__image"
              :src="imageURL(images[0].src, imageSizeM())"
              :title="images[0].title"
              :alt="images[0].alt"
            >
            <div class="available-parts__cell available-parts__image-container">
              <part-gallery
                v-if="showGallery && !isBundle"
                :images="images"
                :image-versions="imageVersions"
                :id="`part-gallery-bundle-${number}`"
              />
            </div>
          </div>
          <div class="product-order-position__wrapper__info">
            <div class="product-order-position__info">
              <div class="product-order-position__headline">
                <div class="product-order-position__name default-semi-bold">
                  {{ name }}
                </div>
                <div
                  class="product-order-position__number small-regular"
                >
                  {{ renderProductNumbers(jzbNumber, bundleCode) }}
                </div>
              </div>
              <div class="product-order-position__summary">
                <span
                  class="product-order-position__price default-semi-bold"
                >
                  {{ totalPrice.displayValue }}
                </span>
                <span
                  v-if="type === 'orderTable'"
                  class="product-order-position__reservedFor"
                >
                  {{ reservation.reservedFor }}
                </span>
              </div>
            </div>

            <div
              v-if="!isBundle"
              class="product-order-position__details"
            >
              <span class="product-order-position__details--key">{{ $t('global.product.quality') }}:</span>
              <span class="product-order-position__details--value">{{ qualityClass }}</span>
            </div>
            <div class="product-order-position__delivery-information">{{ $t('ITEM_STOCK_AVAILABLE') }}</div>

            <!-- in case containedPartNumbers should be shown -->
            <div
              v-if="false"
              class="product-order-position__details"
            >
              <span class="product-order-position__details--key">{{ $t('global.product.included.numbers') }}:</span>
              <span class="product-order-position__details--value">{{ containedPartNumbers }}</span>
            </div>

            <div
              v-if="showMore"
              class="product-order-position__details"
            >
              <product-order-bundle-parts
                v-if="isBundle"
                :bundle-parts="bundleParts"
                :show-gallery="showGallery"
              />
              <available-part-damage-model
                v-else
                :type="'basket'"
                :available-part-damage-models="availablePartDamageModels"
                :remark-notice="remarkNotice"
              />
            </div>
          </div>
        </div>
        <product-order-position-actions
          :type="type"
          :is-bundle="isBundle"
          :bundle-parts-count="bundleParts.length"
          :show-additional-info="availablePartDamageModels.length > 0 || !!remarkNotice"
          :show-more="showMore"
          @toggleShowMore="toggleShowMore"
          @removeGlobally="removeGlobally"
        />
      </div>
    </div>
  </div>
</template>

<script>
import globalStateComputedMixin
  from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';
import {breakpoints} from "../../variables/variables";
import ScrollToElement from "../../utilities/js/scrollToElement/scrollToElement";
import AvailablePartDamageModel from "../availableParts/availablePartDamageModel.vue";
import productIDsMixin from "../../utilities/vueMixins/productMixin/productIDsMixin";
import PartGallery from "../partGallery/partGallery.vue";
import ProductOrderBundleParts from "./productOrderBundleParts.vue";
import ProductOrderPositionActions from "./productOrderPositionActions.vue";
import {imageSizeM, imageUrlWithParam} from "../../utilities/js/image/image";

export default {
  mixins: [
    globalStateComputedMixin,
    productIDsMixin
  ],
  components: {
    AvailablePartDamageModel,
    PartGallery,
    ProductOrderBundleParts,
    ProductOrderPositionActions
  },
  props: {
    id: String,
    url: String,
    showGallery: Boolean,
    images: Array,
    imageVersions: Array,
    name: String,
    number: String,
    quantity: Number,
    entryId: String,
    price: Object,
    totalPrice: Object,
    itemId: String,
    parentId: String,
    type: String,
    actionId: String,
    actionUrl: {
      type: String,
      default: ""
    },
    qualityClass: String,
    availablePartDamageModels: Array,
    remarkNotice: String,
    containedPartNumbers: String,
    reservation: Object,
    bundleCode: String,
    jzbNumber: String,
    bundleParts: Array
  },
  data() {
    return {
      deleting: false,
      showMore: false,
      isMobile: window.innerWidth < breakpoints.laptop,
      timeoutId: undefined
    }
  },
  created() {
    window.addEventListener('breakpointChange', this.breakpointChangeHandler.bind(this));
  },
  computed: {
    isBundle() {
      return this.bundleParts?.length > 0
    },
  },
  methods: {
    imageSizeM() {
      return imageSizeM
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    removeFromCart() {
      this.$store.dispatch('cartPage/removeSingleProduct', {
        entryId: this.entryId,
        _csrf: this.csrfToken.value
      });
    },
    removeGlobally() {
      this.$store.dispatch('reduceCount', {count: 'cart'});
      this.productNavigationClickHandler();
      this.removeFromCart();
    },
    breakpointChangeHandler() {
      this.isMobile = window.innerWidth < breakpoints.laptop;
    },
    productNavigationClickHandler() {
      ScrollToElement(this.$el);
    },
    imageURL(src, width) {
      return imageUrlWithParam(src, width)
    }
  }
};
</script>
