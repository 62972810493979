import productsList from '../../../services/productsList/productsList.js';
import { toRaw } from "vue";

const actions = {
  filterUpdate(store, data) {
    store.commit('updateFilterOptions', data);

    if (data.updatePageOnChange) {
      store.dispatch('pageUpdate', {resetPage: true});
    }
  },
  filterPriceRangeUpdate(store, data) {
    store.commit('updateFilterRange', data);

    if (data.updatePageOnChange) {
      store.dispatch('pageUpdate', {resetPage: true});
    }
  },
  prevPage(store) {
    if (store.state.pagination.currentPage > 1) {
      store.commit('updateCurrentPage', {value: store.state.pagination.currentPage - 1});
      store.dispatch('pageUpdate');
    }
  },
  nextPage(store) {
    if (store.state.pagination.currentPage < store.state.pagination.pagesCount) {
      store.commit('updateCurrentPage', {value: store.state.pagination.currentPage + 1});
      store.dispatch('pageUpdate');
    }
  },
  gotoPage(store, data) {
    if (data.value >= 1 && data.value <= store.state.pagination.pagesCount) {
      store.commit('updateCurrentPage', {value: data.value});
      store.dispatch('pageUpdate');
    }
  },
  sortProducts(store, data) {
    store.commit('updateSortValue', {value: data.value});
    store.dispatch('pageUpdate', {resetPage: true});
  },
  pageUpdate(store, options = {}) {
    if (options.resetPage) {
      store.commit('updateCurrentPage', {value: 1});
    }

    let urlParameters = store.getters.urlParameters;

    productsList()
      .getProductsList(urlParameters)
      .then(data => {
        if (data) {
          store.dispatch('updateState', data, true);
        }
    });
  },
  updateState(store, data) {
    store.commit('updateProductsList', data.state.elementsList);
    store.commit('updateFiltersState', data.state.filters);
    store.commit('updatePagination', data.state.pagination);
    store.commit('updateHeadline', data.state.headline);
    store.commit('updateResultCount', data.state.resultCount);
    store.dispatch('historyPushState', {url: data.url});
  },
  historyPushState(store, data) {
    window.history.pushState(
      {
        productsList: toRaw(store.state.elementsList),
        filters: toRaw(store.state.filters),
        pagination: toRaw(store.state.pagination),
        sorting: toRaw(store.state.sorting)
      },
      document.title,
      data.url
    );
  },
  historyPopState(store, eventData) {
    store.commit('updateProductsList', eventData.state.productsList);
    store.commit('updateFiltersState', eventData.state.filters);
    store.commit('updateCurrentPage', {value: eventData.state.pagination.currentPage});
    store.commit('updateSortValue', {value: eventData.state.sorting.selectedOptionValue});
  },
  removeActiveFilterOption(store, data) {
    store.commit('removeActiveFilterOption', data);

    if (data.updatePageOnChange) {
      store.dispatch('pageUpdate', {resetPage: true});
    }
  },
  removeAllActiveFilterOptions(store, data) {
    store.commit('removeAllActiveFilterOptions');

    if (data.updatePageOnChange) {
      store.dispatch('pageUpdate', {resetPage: true});
    }
  },
  productChanged(store, data) {
    productsList()
      .handleGeneral(data.url, data.formData, 'get')
      .then((res) =>  store.dispatch('updateProduct', Object.assign(res, {'boxIndex': data.formData.boxIndex})))
  },
  updateProduct(store, updatedData){
    store.commit('updateProductOnList', updatedData)
  }
};

export default actions;
