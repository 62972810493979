<template>
  <div class="cart-page">
    <template v-if="productsCount > 0">
      <div class="cart-page__main">
        <div class="cart-page__header">
          <div class="h3">
            {{ $t('checkout.basket.label') }}
            <span class="cart-page__header--count">
              {{ productsCount }}
              <template v-if="productsCount > 1">
                {{ $t('global.articles.label') }}
              </template>
              <template v-else>
                {{ $t('global.article.label') }}
              </template>
            </span>
          </div>
          <cart-select
            class="cart-page__cart-select"
            :use-add-new="false"
          />
        </div>
        <div class="cart-page__content">
          <product-order-position
            v-for="product in productsList"
            v-bind="product"
            class="cart-page__content__product"
            id="ADDED_POSITION_DETAILS"
            :key="product.simpleId"
            type="orderTable"
          />
        </div>
      </div>
      <div class="cart-page__footer">
        <div class="cart-page__order-info" />
        <order-summary
          class="cart-page__order-summary"
          :products-costs="productsCosts"
          :discount="orderDiscount"
          :shipping-costs="orderShippingCosts"
          :shipping-cost-mulitplier="orderShippingCostMultiplier"
          :total="orderTotalCosts"
          :vat="vatDetails"
        />
        <a
          :href="checkoutUrl"
          class="cart-page__submit-button | button button--filled-default"
        >
          {{ $t('checkout.basket.next.label') }}
        </a>
      </div>
    </template>
    <div
      v-else
      class="cart-page__empty"
    >
      <div class="cart-page__header">
        <div class="cart-page__headline h3">
          {{ $t('checkout.basket.empty') }}
        </div>
        <cart-select
          class="cart-page__cart-select"
          :use-add-new="false"
        />
      </div>
      <div class="cart-page__content">
        <a
          :href="mainHref"
          class="cart-page__back-to-home-button | button  button--alt button--icon button--icon_back"
        >
          {{ $t('navigation_main_checkout.back_to_shop.link') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import OrderSummary from '../orderSummary/orderSummary.vue';
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js';
import {mapGetters} from 'vuex';
import CartSelect from "../cartSelect/cartSelect";
import ProductOrderPosition from "../productOrderPosition/productOrderPosition.vue";

export default {
    mixins: [
      globalStateComputedMixin
    ],
    computed: {
      ...mapGetters('cartPage', [
        'productsCount',
        'productsList',
        'productsCosts',
        'orderDiscount',
        'orderShippingCosts',
        'orderShippingCostMultiplier',
        'orderTotalCosts',
        'vatDetails'
      ]),
    },
    components: {
      ProductOrderPosition,
      OrderSummary,
      CartSelect
    },
    data() {
      return {
        maxBasketAllowedReached: false
      }
    },
  };
</script>
