import encodeFormData from '../../utilities/js/encodeFormData/encodeFormData.js';
import backendRequestService from "../backendRequest/backendRequest";

let service;

class Product {
  constructor() {
    this.urls = window.__globalConfig.apiUrls.product;
  }

  getProductEditData(type, productData) {
    if (type === 'cart') {
      return this.getCartEditData(productData);
    } else if (type === 'wishlist') {
      return this.getWishlistEditData(productData);
    }
  }

  getCartEditData(productData) {
    return fetch(
      `${this.urls.cartEdit}?${encodeFormData(productData)}`,
      {credentials: 'include'}
    )
      .then(response => {
        return response.json();
    });
  }

  getWishlistEditData(productData) {
    return fetch(
      `${this.urls.wishlistEdit}?${encodeFormData(productData)}`,
      {credentials: 'include'}
    )
      .then(response => {
        return response.json();
    });
  }

  postNewProductData(url, productData) {
    productData['title'] = document.title;
    return backendRequestService()
      .postBackendRequest(url, productData)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
    });
  }
}

function product() {
  if (service) {
    return service;
  } else {
    service = new Product();
    return service;
  }
}

export default product;
