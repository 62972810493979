<template>
  <div class="date-picker">
    <div class="date-picker__header">
      <div class="date-picker__select-wrapper date-picker__select-wrapper--month-wrapper">
        <span
          class="date-picker__change-select-button date-picker__change-select-button--prev"
          :class="{
            'date-picker__change-select-button--disabled': selectedYear === minYear && selectedMonth === minMonth
          }"
          @click="prevMonthBtnClickHandler"
        />
        <span
          class="date-picker__change-select-button date-picker__change-select-button--next"
          :class="{
            'date-picker__change-select-button--disabled': selectedYear === maxYear && selectedMonth === maxMonth
          }"
          @click="nextMonthBtnClickHandler"
        />
        <select
          class="date-picker__select"
          v-model="selectedMonth"
          @change="monthSelectChangeHandler"
        >
          <option
            v-for="month in 12"
            :key="month"
            :value="month"
            :disabled="(selectedYear === maxYear && selectedMonth > maxMonth) || (selectedYear === minYear && selectedMonth < minMonth)"
          >
            {{ getMonthDisplayName(month) }}
          </option>
        </select>
      </div>
      <div class="date-picker__select-wrapper date-picker__select-wrapper--year-wrapper">
        <span
          class="date-picker__change-select-button date-picker__change-select-button--prev"
          :class="{
            'date-picker__change-select-button--disabled': selectedYear === minYear
          }"
          @click="prevYearBtnClickHandler"
        />
        <span
          class="date-picker__change-select-button date-picker__change-select-button--next"
          :class="{
            'date-picker__change-select-button--disabled': selectedYear === maxYear
          }"
          @click="nextYearBtnClickHandler"
        />
        <select
          class="date-picker__select"
          v-model="selectedYear"
          @change="yearSelectChangeHandler"
        >
          <option
            v-for="(year, index) in maxYear - minYear + 1"
            :key="minYear + index"
            :value="minYear + index"
          >
            {{ minYear + index }}
          </option>
        </select>
      </div>
    </div>
    <div class="date-picker__header date-picker__header__days date-picker__days-wrapper">
      <span class="date-picker__day date-picker__day--name">
        {{ $t('datepicker.mondayShort') }}
      </span>
      <span class="date-picker__day date-picker__day--name">
        {{ $t('datepicker.tuesdayShort') }}
      </span>
      <span class="date-picker__day date-picker__day--name">
        {{ $t('datepicker.wednesdayShort') }}
      </span>
      <span class="date-picker__day date-picker__day--name">
        {{ $t('datepicker.thurstdayShort') }}
      </span>
      <span class="date-picker__day date-picker__day--name">
        {{ $t('datepicker.fridayShort') }}
      </span>
      <span class="date-picker__day date-picker__day--name">
        {{ $t('datepicker.saturdayShort') }}
      </span>
      <span class="date-picker__day date-picker__day--name">
        {{ $t('datepicker.sundayShort') }}
      </span>
    </div>
    <div class="date-picker__days-wrapper">
      <span
        class="date-picker__day date-picker__day--empty"
        v-for="emptyDay in emptyDays"
        :key="emptyDay"
      />
      <label
        class="date-picker__day"
        v-for="day in daysOfMonth"
        :key="`day_${day}`"
        :for="`day_${day}`"
      >
        <input
          class="date-picker__day-input"
          type="radio"
          v-model="selectedDay"
          :value="day"
          :id="`day_${day}`"
          :disabled="(selectedYear === minYear && selectedMonth === minMonth && day < minDay) || (selectedYear === maxYear && selectedMonth === maxMonth && day > maxDay)"
          @change="dayInputChangeHandler"
        >
        <span class="date-picker__day-label">
          {{ day }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      initialSelectedDate: String,
      minDate: String,
      maxDate: String
    },
    data() {
      return {
        selectedYear: null,
        selectedMonth: null,
        selectedDay: null,
        selectedMonthDays: null,
        minYear: null,
        minMonth: null,
        minDay: null,
        maxYear: null,
        maxMonth: null,
        maxDay: null
      };
    },
    created() {
      this.setInitialMinDate();
      this.setInitialMaxDate();
      this.setInitialSelectedDate();
    },
    methods: {
      setInitialMinDate() {
        let initialMinDate = this.minDate;

        if (initialMinDate === 'today') {
          let today = new Date();
          initialMinDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        } else if (!initialMinDate) {
          // 3153600000000 is ~100 years in miliseconds
          initialMinDate = `${(new Date((new Date()).getTime() - 3153600000000)).getFullYear()}-01-01`;
        }

        this.setMinDate(initialMinDate);
      },
      setMinDate(date) {
        let dateArray = date.split('-')
          .map(dateElement => {
            return parseInt(dateElement);
          });

        let daysInSelectedMonth;

        this.minYear = dateArray[0];
        this.minMonth = dateArray[1] < 12 ? dateArray[1] : 12;
        daysInSelectedMonth = this.getNumberOfDaysInMonth(this.minMonth);
        this.minDay = dateArray[2] < daysInSelectedMonth ? dateArray[2] : daysInSelectedMonth;
      },
      setInitialMaxDate() {
        let initialMaxDate = this.maxDate;

        if (initialMaxDate === 'today') {
          let today = new Date();
          initialMaxDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        } else if (!initialMaxDate) {
          // 315360000000 is ~10 years in miliseconds
          // 409968000000 is ~13 years in miliseconds
          // 568025140000 is ~18 years in milliseconds
          initialMaxDate = `${(new Date((new Date()).getTime() - 568025140000)).getFullYear()}-12-31`;
        }

        this.setMaxDate(initialMaxDate);
      },
      setMaxDate(date) {
        let dateArray = date.split('-')
          .map(dateElement => {
            return parseInt(dateElement);
          });

        let daysInSelectedMonth;

        this.maxYear = dateArray[0] > this.minYear ? dateArray[0] : this.minYear;

        if (this.minYear === this.maxYear) {
          this.maxMonth = dateArray[1] > this.minMonth ? (dateArray[1] < 12 ? dateArray[1] : 12) : this.minMonth;
        } else {
          this.maxMonth = dateArray[1] < 12 ? dateArray[1] : 12;
        }

        daysInSelectedMonth = this.getNumberOfDaysInMonth(this.maxMonth);

        if (this.minYear === this.maxYear && this.minMonth === this.maxMonth) {
          this.maxDay = dateArray[2] > this.minDay ? (dateArray[2] < daysInSelectedMonth ? dateArray[2] : daysInSelectedMonth) : this.minDay;
        } else {
          this.maxDay = dateArray[2] < daysInSelectedMonth ? dateArray[2] : daysInSelectedMonth;
        }
      },
      setInitialSelectedDate() {
        let initialDate = this.initialSelectedDate;

        if (!initialDate) {
          let today = new Date();
          initialDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        }

        this.setSelectedDate(initialDate);
      },
      setSelectedDate(date) {
        let dateArray = date.split('-')
          .map(dateElement => {
            return parseInt(dateElement);
          });

        this.setSelectedYear(dateArray[0]);
        this.setSelectedMonth(dateArray[1]);
        this.setSelectedDay(dateArray[2]);
      },
      setSelectedYear(year) {
        this.selectedYear = year < this.maxYear ? (year > this.minYear ? year : this.minYear) : this.maxYear;
      },
      setSelectedMonth(month) {
        if (this.selectedYear === this.maxYear && this.selectedYear === this.minYear) {
          this.selectedMonth = month < this.maxMonth ? (month > this.minMonth ? month : this.minMonth) : this.maxMonth;
        } else if (this.selectedYear === this.maxYear) {
          this.selectedMonth = month < this.maxMonth ? (month > 1 ? month : 1) : this.maxMonth;
        } else if (this.selectedYear === this.minYear) {
          this.selectedMonth = month > this.minMonth ? (month < 12 ? month : 12) : this.minMonth;
        } else {
          this.selectedMonth = month > 1 ? (month < 12 ? month : 12) : 1;
        }
      },
      setSelectedDay(day) {
        let daysInSelectedMonth = this.getNumberOfDaysInMonth(this.selectedMonth);

        if (this.selectedYear === this.maxYear && this.selectedYear === this.minYear) {
          if (this.selectedMonth === this.maxMonth && this.selectedMonth === this.minMonth) {
            this.selectedDay = day < this.maxDay ? (day > this.minDay ? day : this.minDay) : this.maxDay;
          } else if (this.selectedMonth === this.maxMonth) {
            this.selectedDay = day < this.maxDay ? (day > 1 ? day : 1) : this.maxDay;
          } else if (this.selectedMonth === this.minMonth) {
            this.selectedDay = day > this.minDay ? (day < daysInSelectedMonth ? day : daysInSelectedMonth) : this.minDay;
          }
        } else if (this.selectedYear === this.maxYear && this.selectedMonth === this.maxMonth) {
          this.selectedDay = day < this.maxDay ? (day > 1 ? day : 1) : this.maxDay;
        } else if (this.selectedYear === this.minYear && this.selectedMonth === this.minMonth) {
          this.selectedDay = day > this.minDay ? (day < daysInSelectedMonth ? day : daysInSelectedMonth) : this.minDay;
        } else {
          this.selectedDay = day > 1 ? (day < daysInSelectedMonth ? day : daysInSelectedMonth) : 1;
        }
      },
      getNumberOfDaysInMonth(month) {
        if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
          return 31;
        } else {
          return this.selectedMonth === 2 ? (this.selectedYear % 4 === 0 ? 29 : 28) : 30;
        }
      },
      yearSelectChangeHandler() {
        if (this.selectedYear === this.minYear && this.selectedMonth < this.minMonth) {
          this.selectedMonth = this.minMonth;
        }

        if (this.selectedYear === this.maxYear && this.selectedMonth > this.maxMonth) {
          this.selectedMonth = this.maxMonth;
        }

        this.selectedDay = null;
      },
      monthSelectChangeHandler() {
        this.selectedDay = null;
      },
      prevYearBtnClickHandler() {
        if (this.selectedYear !== this.minYear) {
          this.selectedYear = this.selectedYear - 1;
          this.yearSelectChangeHandler();
        }
      },
      nextYearBtnClickHandler() {
        if (this.selectedYear !== this.maxYear) {
          this.selectedYear = this.selectedYear + 1;
          this.yearSelectChangeHandler();
        }
      },
      prevMonthBtnClickHandler() {
        if (!(this.selectedYear === this.minYear && this.selectedMonth === this.minMonth)) {
          if (this.selectedMonth === 1) {
            this.selectedMonth = 12;
            this.selectedYear = this.selectedYear - 1;
            this.monthSelectChangeHandler();
            this.yearSelectChangeHandler();
          } else {
            this.selectedMonth = this.selectedMonth - 1;
            this.monthSelectChangeHandler();
          }
        }
      },
      nextMonthBtnClickHandler() {
        if (!(this.selectedYear === this.maxYear && this.selectedMonth === this.maxMonth)) {
          if (this.selectedMonth === 12) {
            this.selectedMonth = 1;
            this.selectedYear = this.selectedYear + 1;
            this.monthSelectChangeHandler();
            this.yearSelectChangeHandler();
          } else {
            this.selectedMonth = this.selectedMonth + 1;
            this.monthSelectChangeHandler();
          }
        }
      },
      dayInputChangeHandler() {
        this.$emit('change', {
          value: `${this.selectedYear}-${this.selectedMonth < 10 ? '0' + this.selectedMonth : this.selectedMonth}-${this.selectedDay < 10 ? '0' + this.selectedDay : this.selectedDay}`,
          day: this.selectedDay,
          month: this.selectedMonth,
          year: this.selectedYear
        });
      },
      getMonthDisplayName(monthNr) {
        switch (monthNr) {
        case 1:
          return this.$t('datepicker.january');
        case 2:
          return this.$t('datepicker.february');
        case 3:
          return this.$t('datepicker.march');
        case 4:
          return this.$t('datepicker.april');
        case 5:
          return this.$t('datepicker.may');
        case 6:
          return this.$t('datepicker.june');
        case 7:
          return this.$t('datepicker.july');
        case 8:
          return this.$t('datepicker.august');
        case 9:
          return this.$t('datepicker.september');
        case 10:
          return this.$t('datepicker.october');
        case 11:
          return this.$t('datepicker.november');
        case 12:
          return this.$t('datepicker.december');
        }
      }
    },
    computed: {
      daysOfMonth() {
        return this.getNumberOfDaysInMonth(this.selectedMonth);
      },
      emptyDays() {
        let dayOfWeek = (new Date(this.selectedYear, this.selectedMonth - 1, 1, 0, 0, 0, 0)).getDay();
        return dayOfWeek === 0 ? 6 : -1 + dayOfWeek;
      }
    }
  };
</script>
